import React from "react";

import { AiOutlineClose } from 'react-icons/ai';

export const ModalHeader = ({
  title,
  handleModalClose,
  borderBottom = true,
}) => {
  return (
    <div
      className={`flex items-start justify-between p-5 ${
        borderBottom ? "border-b" : ""
      } rounded-t h-16`}
    >
      <h3 className="text-xl font-semibold text-darkText dark:text-white">
        {title}
      </h3>
      <button
        className="flex items-center text-gray-500 hover:text-gray-700 rounded px-2 py-1 hover:bg-gray-300 focus:outline-none"
        onClick={handleModalClose}
      >
        <AiOutlineClose className="w-5 h-5 mr-1" />
        <span>Close</span>
      </button>
    </div>
  );
};
