import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import ResourceTable from "../components/resource/ResourceTable"; // Update import for resource table
import AddResourceModal from "../components/resource/AddResourceModal"; // Update import for resource modal
import { getResources } from "../../../store/slices/resource-slice"; // Update import for resource slice
import { pageTitle } from "../../../helpers/PageTitle";

export default function AdminResourcePage() {
  pageTitle("Admin Resources"); // Update page title
  const location = useLocation();
  const modalOpen = location?.state?.modalOpen;
  const [isModalOpen, setIsModalOpen] = useState(modalOpen ? modalOpen : false);
  const dispatch = useDispatch();

  const { resources } = useSelector((state) => state.resource); // Update state to fetch resources

  useEffect(() => {
    dispatch(getResources({ page: 1, pageSize: 5 })); // Fetch resources
  }, [dispatch]);

  return (
    <div className="p-4">
      <div className="flex flex-row justify-between items-center">
        <h2 className="text-lg font-semibold">Resources</h2> {/* Update heading */}
        <Link
          onClick={() => setIsModalOpen(true)}
          className="border flex items-center justify-center gap-2 hover:text-accent rounded-2xl px-4 py-2 shadow-md transition duration-300"
        >
          <FaPlus />
          <span>Create Resource</span> {/* Update button text */}
        </Link>
      </div>

      <ResourceTable resources={resources} /> {/* Update component to render resources */}

      <AddResourceModal
        isOpen={isModalOpen}
        handleModalClose={() => setIsModalOpen(false)} // Close modal function
      />
    </div>
  );
}
