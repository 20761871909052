import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getPartners } from "../../../store/slices/partner-slice";
import { pageTitle } from "../../../helpers/PageTitle";
import AddPartnerModal from "../components/partners/AddPartnerModal";
import PartnerTable from "../components/partners/PartnerTable";

export default function AdminPartnerPage() {
  pageTitle("Admin Partner");
  const location = useLocation();
  const modalOpen = location?.state?.modalOpen;
  const [isModalOpen, setIsModalOpen] = useState(modalOpen ? modalOpen : false);
  const dispatch = useDispatch();

  const { partners } = useSelector((state) => state.partner);

  useEffect(() => {
    dispatch(getPartners({ page: 1, pageSize: 5 }));
  }, [dispatch]);

  return (
    <div className="p-4">
      <div className="flex flex-row justify-between items-center ">
        <h2 className="text-lg font-semibold">Partners</h2>
        <Link
          onClick={() => setIsModalOpen(true)}
          className="border flex items-center justify-center gap-2 hover:text-accent rounded-2xl px-4 py-2 shadow-md transition duration-300"
        >
          <FaPlus />
          <span>Create partner</span>
        </Link>
      </div>

      <PartnerTable partners={partners} />

      <AddPartnerModal
        isOpen={isModalOpen}
        handleModalClose={() => setIsModalOpen(false)}
      />
    </div>
  );
}
