import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { ModalHeader } from "../modal/ModalHeader";
import { ModalBody } from "../modal/ModalBody";
import { ModalFooter } from "../modal/ModalFooter";
import {
  createOpportunity,
  selectOpportunityById,
  updateOpportunity,
} from "../../../../store/slices/opportunity-slice";
import ImageUploader from "../blog/ImageUploader";

const defaultData = {
  title: "",
  description: "",
  type: "Agriculture",
  imageUrl: "",
  link: "",
};

const opportunityTypes = [
  "Agriculture",
  "Art & Music",
  "Aviation",
  "Construction",
  "Consulting",
  "Defence",
  "Accounting",
  "Energy",
  "Financial Services",
  "Government Entity",
  "Health Care",
  "Hospitality",
  "Education",
  "Legal Services",
  "Logistic",
  "Manufacturing",
  "Media",
  "Mining",
  "Oil & Gas",
  "IT",
  "Real Estate",
  "Retail",
  "Sports",
  "Trading",
  "Transport",
  "Professional Service",
];

const AddOpportunityModal = ({ handleModalClose, id, isOpen }) => {
  const dispatch = useDispatch();

  const selectedOpportunity = useSelector((state) =>
    selectOpportunityById(state, id)
  );

  const [formData, setFormData] = useState(defaultData);

  useEffect(() => {
    if (id && selectedOpportunity) {
      setFormData({
        title: selectedOpportunity.title,
        description: selectedOpportunity.description,
        type: selectedOpportunity.type,
        imageUrl: selectedOpportunity.imageUrl,
        link: selectedOpportunity?.link,
      });
    }
  }, [id, selectedOpportunity]);

  const handleSave = async (event) => {
    event.preventDefault();

    if (id) {
      const updatedData = { ...formData };
      dispatch(updateOpportunity({ id, updatedData }));
    } else {
      dispatch(createOpportunity(formData));
    }
    handleModalClose();
    setFormData(defaultData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageUploadSuccess = (url) => {
    setFormData((prevState) => ({
      ...prevState,
      imageUrl: url,
    }));
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleModalClose}
      contentLabel="Add/Edit Opportunity"
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
        },
        overlay: {
          background: "rgba(0,0,0,0.10)",
        },
      }}
    >
      <ModalHeader
        title={id ? "Edit Opportunity" : "Add Opportunity"}
        handleModalClose={handleModalClose}
        borderBottom={true}
      />
      <ModalBody>
        <form className="flex flex-col max-h-[400px]" onSubmit={handleSave}>
          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              Title
            </div>
            <div className="w-9/12">
              <input
                id="title"
                name="title"
                className="form-control cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full placeholder-gray-400"
                value={formData.title}
                onChange={handleChange}
                placeholder="Enter opportunity title"
              />
            </div>
          </div>

          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              Description
            </div>
            <div className="w-9/12">
              <textarea
                id="description"
                name="description"
                className="form-control cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full placeholder-gray-400"
                value={formData.description}
                onChange={handleChange}
                placeholder="Enter opportunity description"
              />
            </div>
          </div>

          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              Form Link
            </div>
            <div className="w-9/12">
              <input
                id="link"
                name="link"
                className="form-control cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full placeholder-gray-400"
                value={formData.link}
                onChange={handleChange}
                placeholder="Enter form link (optional)"
              />
            </div>
          </div>

          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              Type
            </div>
            <div className="w-9/12">
              <select
                id="type"
                name="type"
                className="form-control cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full"
                value={formData.type}
                onChange={handleChange}
              >
                <option value="" disabled>
                  Select opportunity type
                </option>
                {opportunityTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex  w-full pb-4 gap-2 items-center">
            <div className="mr-16 h-9 flex items-center text-sm font-semibold">
              Image
            </div>
            <div>
              <ImageUploader onUploadSuccess={handleImageUploadSuccess} />
            </div>
          </div>

          <ModalFooter>
            <div className="flex justify-end gap-2">
              <button
                className="flex items-center shadow-md px-3 py-1 rounded-md border hover:text-accent hover:bg-gray-300 focus:outline-none"
                onClick={handleModalClose}
              >
                <span>Close</span>
              </button>

              <button
                type="submit"
                className="flex items-center disabled:cursor-not-allowed shadow-md px-3 py-1 rounded-md border hover:bg-gray-300 hover:text-accent bg-accent text-white"
              >
                Save
              </button>
            </div>
          </ModalFooter>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default AddOpportunityModal;
