import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../helpers/api";

export const getJobs = createAsyncThunk(
  "job/fetch",
  async ({ page, pageSize, category }, { rejectWithValue }) => {
    try {
      const response = await API.get(`/jobs`, {
        params: {
          page: page,
          pageSize: pageSize,
          ...(category && { category }),
        },
      });

      return response.data;
    } catch (error) {
      console.log(error,'errro in get jobs');
      
      return rejectWithValue(error);
    }
  }
);

export const createJob = createAsyncThunk(
  "job/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(`/jobs`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateJob = createAsyncThunk(
  "job/update",
  async ({ id, updatedData }) => {
    const response = await API.put(`/jobs/${id}`, updatedData);
    return response.data;
  }
);

export const deleteJob = createAsyncThunk(
  "job/delete",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await API.delete(`/jobs/${id}`);
      return { ...response.data, id };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  jobs: [],
  status: null,
  error: undefined,
  total: 0,
  page: 1,
  pageSize: 5,
};

const jobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      // Get Jobs
      .addCase(getJobs.pending, (state) => {
        state.error = undefined;
        state.status = "loading";
        state.jobs = [];
      })
      .addCase(getJobs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.jobs = action.payload.jobs;
        state.total = action.payload.total;
      })
      .addCase(getJobs.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      })

      // Create Job
      .addCase(createJob.pending, (state) => {
        state.error = undefined;
        state.status = "loading";
      })
      .addCase(createJob.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.jobs.unshift(action.payload.job);
      })
      .addCase(createJob.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      })

      // Handle update Job
      .addCase(updateJob.fulfilled, (state, action) => {
        const index = state.jobs.findIndex(
          (job) => job._id === action.payload.job._id
        );
        if (index !== -1) {
          state.jobs[index] = action.payload.job;
        }
        state.status = "succeeded";
      })
      .addCase(updateJob.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateJob.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Handle delete Job
      .addCase(deleteJob.fulfilled, (state, action) => {
        state.jobs = state.jobs.filter((job) => job._id !== action.payload.id);
        state.status = "succeeded";
      })
      .addCase(deleteJob.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteJob.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const selectJobById = (state, id) => {
  return state.job?.jobs?.find((job) => job._id === id);
};

export default jobSlice.reducer;
