import React from "react";
import Service from "../../Service";
import SectionHeadingStyle2 from "../../SectionHeading/SectionHeadingStyle2";
import { Link } from "react-router-dom";

export default function ServiceSectionStyle4({
  data,
  sectionTitleUp,
  sectionTitle,
}) {
  return (
    <div className="container">
      <SectionHeadingStyle2
        sectionTitleUp={sectionTitleUp}
        sectionTitle={sectionTitle}
      />
      <div className="row">
        {data?.map((item, index) => (
          <div className="col-xl-4 col-md-6" key={index}>
            <Service {...item} />
          </div>
        ))}
        <div
          className="col-xl-8 col-md-12 cs_quick_contact_card background-filled cs_mb_40 text-center relative "
          style={{
            backgroundImage: 'url("/images/partner_with_us.png")',
          }}
        >
          <div className="absolute inset-0 bg-black opacity-60 "></div>
          <div className="cs_quick_contact_card_in">
            <h6 className="cs_fs_30 cs_fs_lg_36 text-white shadow-md relative z-10 cs_mb_40">
              Want to Partner with Us? Fill this Form
            </h6>
            <Link
              to={"https://forms.gle/f5xCyq6uTaMXxGqA7"}
              target="_blanck"
              className="cs_btn cs_style_1 cs_fs_16 cs_rounded_5 cs_pl_30 cs_pr_30 cs_pt_10 cs_pb_10 overflow-hidden"
            >
              <span>Become a Partner</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
