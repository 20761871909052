import React, { useEffect } from "react";
import Breadcrumb from "../Breadcrumb";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { pageTitle } from "../../helpers/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { getOpportunities } from "../../store/slices/opportunity-slice";
import SectionHeadingStyle2 from "../SectionHeading/SectionHeadingStyle2";

export default function OpportunitiesPage() {
  pageTitle("Opportunities");

  const { opportunities, status } = useSelector((state) => state.opportunity);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getOpportunities({ page: 1, pageSize: 500 }));
  }, [dispatch]);

  return (
    <>
      <Breadcrumb
        title="Available Opportunities"
        bgUrl="/images/page_header_1.jpeg"
      />

      <SectionHeadingStyle2
        sectionTitleUp={"Investment Opportunities"}
        sectionTitle={
          'Exploring <span className="text-accent"> investment opportunities </span> <br />'
        }
      />
      <section className="p-4 mb-16 min-h-screen">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {opportunities &&
            status !== "loading" &&
            opportunities.map((opportunity, index) => (
              <div
                className="bg-white shadow-md rounded-lg overflow-hidden transition-transform transform hover:scale-105"
                key={index}
              >
                <div className="relative">
                  {opportunity?.imageUrl && (
                    <img
                      src={opportunity.imageUrl}
                      alt={opportunity.title}
                      className="h-300"
                    />
                  )}

                  <div className="cs_post_info cs_pl_23 cs_pr_23 cs_pt_40 cs_pb_30 px-3">
                    <h2 className="cs_post_title cs_lh_base cs_fs_20 cs_fs_lg_18 cs_mb_10">
                      <Link>{parse(opportunity.title)}</Link>
                    </h2>
                    <div className="cs_post_subtitle">
                      {parse(opportunity.description.slice(0, 100))}
                      {opportunity.description.length > 100 && "..."}
                    </div>
                  </div>

                  <Link
                    to={`/opportunities/${opportunity._id}`}
                    className="cs_post_btn d-flex justify-content-between align-items-center cs_pl_20 cs_pr_40 cs_pb_10 cs_pt_10 bg-gray-100"
                  >
                    <span className="cs_post_btn-text">Read More</span>
                    <div className="cs_post_btn-icon d-flex cs_transition_4">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.9999 0.224976C6.52011 0.224976 1.63131 4.16368 0.485006 9.52707C-0.0876941 12.207 0.298106 15.0567 1.57581 17.4816C2.80551 19.8153 4.82151 21.7014 7.23351 22.7703C9.74241 23.8824 12.6227 24.0762 15.2597 23.3178C17.8037 22.5864 20.0594 20.9811 21.5951 18.8262C24.806 14.3211 24.3767 7.99288 20.5991 3.95608C18.3851 1.59028 15.2405 0.224976 11.9999 0.224976ZM17.6486 12.6291L14.4386 15.9165C13.6259 16.749 12.3413 15.4878 13.1507 14.6592L14.7704 13.0005H7.09461C6.54951 13.0005 6.09471 12.5454 6.09471 12.0006C6.09471 11.4558 6.54981 11.0007 7.09461 11.0007H14.732L13.0802 9.34918C12.2594 8.52838 13.532 7.25548 14.3528 8.07628L17.6411 11.3643C17.9897 11.7126 17.993 12.2766 17.6486 12.6291Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          {opportunities?.length === 0 && status !== "loading" && (
            <div className="flex items-center justify-center text-xl">
              No Opportunities found
            </div>
          )}
          {status === "loading" && (
            <div className="flex items-center justify-center text-xl">
              Loading...
            </div>
          )}
        </div>
      </section>
    </>
  );
}
