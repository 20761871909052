import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { getResources } from "../../../../store/slices/resource-slice"; // Update import for resource slice
import AddResourceModal from "./AddResourceModal"; // Update import for resource modal
import DeleteResourceModal from "./DeleteResourceModal"; // Update import for resource delete modal

const ResourceTable = ({ resources }) => {
  const { status, total, pageSize } = useSelector((state) => state.resource); // Update state slice
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedResourceId, setSelectedResource] = useState();

  const handleEdit = (id) => {
    setSelectedResource(id);
    setEditModalOpen(true);
  };

  const handleDelete = (id) => {
    setSelectedResource(id);
    setDeleteModalOpen(true);
  };

  const dispatch = useDispatch();

  const handlePaginationModelChange = (pagination) => {
    dispatch(
      getResources({
        page: pagination.page + 1,
        pageSize: pagination.pageSize,
      })
    );
  };

  const columns = [
      { field: "_id", headerName: "ID", flex: 0.5 },
      { field: "dateAdded", headerName: "Created At", flex: 1 },
      { field: "title", headerName: "Title", flex: 1 },
    { field: "imgUrl", headerName: "Image Url", flex: 1 }, // Adjust according to your resource properties
    { field: "description", headerName: "Description", flex: 2 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <div className="flex flex-row gap-3 mt-2 items-center justify-center">
          <button
            className="text-blue-500 text-xl"
            onClick={() => handleEdit(params.row._id)}
          >
            <FaEdit />
          </button>
          <button
            className="text-red-500 text-xl"
            onClick={() => handleDelete(params.row._id)}
          >
            <FaTrashAlt />
          </button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <DataGrid
        sx={{ m: 2 , minHeight: '400px',}}
        style={{ padding: "12px" }}
        getRowId={(row) => row._id}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 5, page: 0 },
          },
        }}
        columns={columns}
        rows={resources} // Update to use resources
        rowCount={total}
        loading={status === "loading"}
        pageSize={pageSize}
        pagination
        paginationMode="server"
        pageSizeOptions={[5, 10, 15]}
        onPaginationModelChange={handlePaginationModelChange}
      />

      <AddResourceModal
        isOpen={isEditModalOpen}
        handleModalClose={() => setEditModalOpen(false)}
        id={selectedResourceId}
      />

      <DeleteResourceModal
        handleModalClose={() => setDeleteModalOpen(false)}
        isOpen={deleteModalOpen}
        resourceId={selectedResourceId} // Update to resourceId
      />
    </div>
  );
};

export default ResourceTable;
