import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import TeamMemberTable from "../components/teamMember/TeamMemberTable"; // Updated import for team member table
import AddTeamMemberModal from "../components/teamMember/AddTeamMemberModal"; // Updated import for add team member modal
import { getTeamMembers } from "../../../store/slices/teamMember-slice"; // Updated import for team member slice
import { pageTitle } from "../../../helpers/PageTitle";

export default function AdminTeamMemberPage() {
  pageTitle("Admin Team Members"); 
  const location = useLocation();
  const modalOpen = location?.state?.modalOpen;
  const [isModalOpen, setIsModalOpen] = useState(modalOpen ? modalOpen : false);
  const dispatch = useDispatch();

  const { teamMembers } = useSelector((state) => state.teamMember); // Updated state to fetch team members

  useEffect(() => {
    dispatch(getTeamMembers({ page: 1, pageSize: 5 })); // Fetch team members
  }, [dispatch]);

  return (
    <div className="p-4">
      <div className="flex flex-row justify-between items-center">
        <h2 className="text-lg font-semibold">Team Members</h2> {/* Updated heading */}
        <Link
          onClick={() => setIsModalOpen(true)}
          className="border flex items-center justify-center gap-2 hover:text-accent rounded-2xl px-4 py-2 shadow-md transition duration-300"
        >
          <FaPlus />
          <span>Create Team Member</span> {/* Updated button text */}
        </Link>
      </div>

      <TeamMemberTable teamMembers={teamMembers} /> {/* Updated component to render team members */}

      <AddTeamMemberModal
        isOpen={isModalOpen}
        handleModalClose={() => setIsModalOpen(false)} // Close modal function
      />
    </div>
  );
}
