import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../../store/slices/auth-slice";

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { status } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(userLogin(formData)).then((result) => {
      if (result?.payload?.success && result?.payload?.token) {
        navigate("/admin");
      }
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="bg-white  px-6 py-6 shadow sm:rounded-lg sm:px-12 items-center flex flex-col justify-center">
        <Link className=" align-self-center" to="/">
          <img className="h-16" src="/image.png" alt="Logo" />
        </Link>

        <form className="flex flex-col gap-3 my-4" onSubmit={handleSubmit}>
          <div className="flex flex-row items-center justify-center gap-3">
            <label
              htmlFor="email"
              className="block text-sm font-medium mt-3 leading-6 text-gray-900"
            >
              Email Address
            </label>

            <input
              className="form-control cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray"
              type="email"
              id="email"
              placeholder="Your email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="flex flex-row items-center justify-center gap-3">
            <label
              htmlFor="email"
              className="block text-sm font-medium mt-3 leading-6 text-gray-900"
            >
              Password
            </label>

            <input
              className="form-control py-2 cs_fs_14 cs_rounded_5 border-0 bg-gray"
              type="password"
              id="password"
              placeholder="Your password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="mt-10">
            <button
              type="submit"
              className="flex w-full justify-center rounded-md  px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm bg-accent "
              disabled={status === "loading"}
            >
              {status === "loading" ? "Logging in..." : "Login"}
            </button>
            <Link to={'/admin/forgot-password'} className="flex items-center justify-center mt-2">Forgot Password ?</Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
