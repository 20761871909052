import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { getBlogs } from "../../../../store/slices/blog-slice";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import AddBlogModalContent from "./AddBlogModal";
import DeleteBlogModal from "./DeleteBlogModal";

const BlogTable = ({ blogs }) => {
  const { status, total, pageSize } = useSelector((state) => state.blog);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedBlogId, setSelectedBlog] = useState();

  const handleEdit = (id) => {
    setSelectedBlog(id);
    setEditModalOpen(true);
  };

  const handleDelete = (id) => {
    setSelectedBlog(id);
    setDeleteModalOpen(true);
  };

  const dispatch = useDispatch();

  const handlePaginationModelChange = (pagination) => {
    dispatch(
      getBlogs({
        page: pagination.page + 1,
        pageSize: pagination.pageSize,
      })
    );
  };

  const columns = [
    { field: "_id", headerName: "ID", flex: 0.5 },
    { field: "title", headerName: "Title", flex: 0.7 },
    { field: "description", headerName: "Description", flex: 1 },
    {
      field: "categories",
      headerName: "Categories",
      flex: 1,
      renderCell: (params) => (
        <div className="flex overflow-x-scroll mt-2 scrollbar-hide items-center flex-row">
          {params.value.split(",").map((category, index) => (
            <span
              index={index}
              className="inline-flex items-center px-3 py-1 mr-2  text-sm font-medium  bg-gray-300 rounded-full"
            >
              {category}
            </span>
          ))}
        </div>
      ),
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <div className="flex flex-row gap-3 mt-2 items-center justify-center ">
          <button
            className="text-blue-500 text-xl"
            onClick={() => handleEdit(params.row._id)}
          >
            <FaEdit />
          </button>
          <button
            className="text-red-500 text-xl"
            onClick={() => handleDelete(params.row._id)}
          >
            <FaTrashAlt />
          </button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <DataGrid
        sx={{ m: 2, minHeight: '400px', }}
        style={{ padding: "12px" }}
        getRowId={(row) => row._id}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 5, page: 0 },
          },
        }}
        columns={columns}
        rows={blogs}
        rowCount={total}
        loading={status === "loading"}
        pageSize={pageSize}
        pagination
        paginationMode="server"
        pageSizeOptions={[5, 10, 15]}
        onPaginationModelChange={handlePaginationModelChange}
      />

      <AddBlogModalContent
        isOpen={isEditModalOpen}
        handleModalClose={() => setEditModalOpen(false)}
        id={selectedBlogId}
      />

      <DeleteBlogModal
        handleModalClose={() => setDeleteModalOpen(false)}
        isOpen={deleteModalOpen}
        blogId={selectedBlogId}
      />
    </div>
  );
};

export default BlogTable;
