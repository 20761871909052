import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import AddTeamMemberModal from "./AddTeamMemberModal"; // Update modal for team member
import DeleteTeamMemberModal from "./DeleteTeamMemberModal"; // Update modal for team member
import { getTeamMembers } from "../../../../store/slices/teamMember-slice";

const TeamMemberTable = ({ teamMembers }) => {
  const { status, total, pageSize } = useSelector((state) => state.teamMember); // Updated state selector for team member
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedTeamMemberId, setSelectedTeamMember] = useState();

  const handleEdit = (id) => {
    setSelectedTeamMember(id);
    setEditModalOpen(true);
  };

  const handleDelete = (id) => {
    setSelectedTeamMember(id);
    setDeleteModalOpen(true);
  };

  const dispatch = useDispatch();

  const handlePaginationModelChange = (pagination) => {
    dispatch(
      getTeamMembers({
        page: pagination.page + 1,
        pageSize: pagination.pageSize,
      })
    );
  };

  const columns = [
    { field: "_id", headerName: "ID", flex: 0.5 },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "role", headerName: "Role", flex: 1 }, 
    { field: "image", headerName: "Image URL", flex: 0.5 },
    { field: "facebook", headerName: "Facebook", flex: 0.5 },
    { field: "linkedin", headerName: "Linkedin", flex: 0.5 },
    { field: "telegram", headerName: "Telegram", flex: 0.5 },
    { field: "whatsapp", headerName: "Whatsapp", flex: 0.5 },
   
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <div className="flex flex-row gap-3 mt-2 items-center justify-center">
          <button
            className="text-blue-500 text-xl"
            onClick={() => handleEdit(params.row._id)}
          >
            <FaEdit />
          </button>
          <button
            className="text-red-500 text-xl"
            onClick={() => handleDelete(params.row._id)}
          >
            <FaTrashAlt />
          </button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <DataGrid
        sx={{ m: 2, minHeight: '400px', }}
        style={{ padding: "12px" }}
        getRowId={(row) => row._id}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 5, page: 0 },
          },
        }}
        columns={columns}
        rows={teamMembers} // Updated rows prop to team members
        rowCount={total}
        loading={status === "loading"}
        pageSize={pageSize}
        pagination
        paginationMode="server"
        pageSizeOptions={[5, 10, 15]}
        onPaginationModelChange={handlePaginationModelChange}
      />

      <AddTeamMemberModal
        isOpen={isEditModalOpen}
        handleModalClose={() => setEditModalOpen(false)}
        id={selectedTeamMemberId} 
      />

      <DeleteTeamMemberModal
        handleModalClose={() => setDeleteModalOpen(false)}
        isOpen={deleteModalOpen}
        teamMemberId={selectedTeamMemberId} // Updated prop for team member ID
      />
    </div>
  );
};

export default TeamMemberTable;
