import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";

export default function Team({ item }) {

  return (
    <div className="cs_team cs_style_1 text-center cs_mb_25 overflow-hidden cs_rounded_50">
      <div className="cs_team_member position-relative cs_rounded_50">
        <img
          className=" cs_rounded_50"
          src={item?.image}
          alt="Member"
        />
        <div className="cs_social_btns d-flex flex-wrap cs_column_gap_15 cs_row_gap_15 cs_transition_5 cs_fs_20 cs_fs_lg_18 position-absolute">
          <Link
            to={item?.facebook}
            className="d-flex align-items-center justify-content-center cs_height_45 cs_width_45 border-0 text-white rounded-circle"
          >
            <Icon icon="fa:facebook-f" />
          </Link>
          <Link
            to={item.telegram}
            className="d-flex align-items-center justify-content-center cs_height_45 cs_width_45 border-0 text-white rounded-circle"
          >
            <Icon icon="fa:telegram" />
          </Link>
          <Link
            to={item.linkedin}
            className="d-flex align-items-center justify-content-center cs_height_45 cs_width_45 border-0 text-white rounded-circle"
          >
            <Icon icon="fa:linkedin" />
          </Link>
          <Link
            to={item.whatsapp}
            className="d-flex align-items-center justify-content-center cs_height_45 cs_width_45 border-0 text-white rounded-circle"
          >
            <Icon icon="fa:whatsapp" />
          </Link>
        </div>
      </div>
      <div className="cs_team_info cs_pt_127 cs_pl_15 cs_pr_15 cs_pb_25 cs_transition_4">
        <h2 className="text-white m-0 cs_fs_26 cs_mb_3">
          <Link>{item?.name}</Link>
        </h2>
        <p className="text-white m-0">{item?.role}</p>
      </div>
    </div>
  );
}
