import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Pages/Home";
import AboutPage from "./components/Pages/AboutPage";
import PortfolioPage from "./components/Pages/PortfolioPage";
import PortfolioDetailsPage from "./components/Pages/PortfolioDetailsPage";
import ServicesPage from "./components/Pages/ServicesPage";
import ServiceDetailsPage from "./components/Pages/ServiceDetailsPage";
import TeamPage from "./components/Pages/TeamPage";
import TeamMemberPage from "./components/Pages/TeamMemberPage";
import BlogGridPage from "./components/Pages/BlogGridPage";
import BlogDetailsPage from "./components/Pages/BlogDetailsPage";
import ContactPage from "./components/Pages/ContactPage";
import AdminLayout from "./components/Admin/Layout/Layout";
import LoginPage from "./components/Admin/Pages/LoginPage";
import ProtectedRoute from "./components/Admin/ProtectedRoutes";
import AdminHome from "./components/Admin/Pages/AdminHomePage";
import AdminBlogPage from "./components/Admin/Pages/AdminBlogPage";
import AdminPortifolioPage from "./components/Admin/Pages/AdminPortifolioPage";
import AdminJobPage from "./components/Admin/Pages/AdminJobsPage";
import JobsPage from "./components/Pages/JobsPage";
import JobDetailsPage from "./components/Pages/JobsDetailsPage";
import AdminTestimonyPage from "./components/Admin/Pages/AdminTestimonyPage";
import AdminMagazinePage from "./components/Admin/Pages/AdminEMagazinePage";
import EMagazinePage from "./components/Pages/EMagazinePage";
import EMagazineDetailsPage from "./components/Pages/EMagazineDetailsPage";
import AdminPartnerPage from "./components/Admin/Pages/AdminPartnerPage";
import AdminResourcePage from "./components/Admin/Pages/AdminResourcePage";
import ResourcePage from "./components/Pages/ResourcesPage";
import AdminEventPage from "./components/Admin/Pages/AdminEventPage";
import EventsPage from "./components/Pages/EventsPage";
import AdminOpportunityPage from "./components/Admin/Pages/AdminOpportunityPage";
import OpportunitiesPage from "./components/Pages/OpportunitiesPage";
import AdminTeamMemberPage from "./components/Admin/Pages/AdminTeamMemberPage";
import OpportunityDetailsPage from "./components/Pages/OpportunityDetailsPage";
import EventDetailsPage from "./components/Pages/EventDetailsPage";
import ForgotPassword from "./components/Admin/Pages/ForgotPasswordPage";
import ResetPassword from "./components/Admin/Pages/ResetPasswordPage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/portfolio" element={<PortfolioPage />} />
        <Route
          path="/portfolio/:portfolioId"
          element={<PortfolioDetailsPage />}
        />
        <Route path="/service" element={<ServicesPage />} />
        <Route path="/service/:serviceId" element={<ServiceDetailsPage />} />
        <Route path="/team" element={<TeamPage />} />
        <Route path="/team/:teamId" element={<TeamMemberPage />} />
        <Route path="/blog" element={<BlogGridPage />} />
        <Route path="/blog/:blogId" element={<BlogDetailsPage />} />
        <Route path="/job" element={<JobsPage />} />
        <Route path="/job/:id" element={<JobDetailsPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/e-magazine" element={<EMagazinePage />} />
        <Route path="/e-magazine/:id" element={<EMagazineDetailsPage />} />
        <Route path="/resource" element={<ResourcePage />} />
        <Route path="/event" element={<EventsPage />} />
        <Route path="/event/:eventId" element={<EventDetailsPage />} />
        <Route path="/opportunities" element={<OpportunitiesPage />} />
        <Route path="/opportunities/:id" element={<OpportunityDetailsPage />} />
      </Route>

      <Route path="/admin/login" element={<LoginPage />} />
      <Route path="/admin/forgot-password" element={<ForgotPassword />} />
      <Route path="/admin/reset-password" element={<ResetPassword />} />

      <Route
        path="/admin"
        element={
          <ProtectedRoute>
            <AdminLayout />
          </ProtectedRoute>
        }
      >
        <Route
          index
          element={
            <ProtectedRoute>
              <AdminHome />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/blog"
          element={
            <ProtectedRoute>
              <AdminBlogPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/portifolio"
          element={
            <ProtectedRoute>
              <AdminPortifolioPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/job"
          element={
            <ProtectedRoute>
              <AdminJobPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/testimony"
          element={
            <ProtectedRoute>
              <AdminTestimonyPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/admin/e-magazine"
          element={
            <ProtectedRoute>
              <AdminMagazinePage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/admin/partner"
          element={
            <ProtectedRoute>
              <AdminPartnerPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/admin/resource"
          element={
            <ProtectedRoute>
              <AdminResourcePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/event"
          element={
            <ProtectedRoute>
              <AdminEventPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/admin/opportunities"
          element={
            <ProtectedRoute>
              <AdminOpportunityPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/admin/team-members"
          element={
            <ProtectedRoute>
              <AdminTeamMemberPage />
            </ProtectedRoute>
          }
        />
      </Route>
    </Routes>
  );
}

export default App;
