import React, { useEffect } from "react";
import Breadcrumb from "../Breadcrumb";
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import { pageTitle } from "../../helpers/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { getEvents } from "../../store/slices/event-slice";
import { formatDate } from "../../helpers/dateUtil";
import SectionHeadingStyle2 from "../SectionHeading/SectionHeadingStyle2";
import { FaCalendarAlt, FaRegClock, FaMapMarkerAlt } from "react-icons/fa";

export default function EventsPage() {
  pageTitle("Events");

  const { events, status } = useSelector((state) => state.event);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getEvents({ page: 1, pageSize: 500 }));
  }, [dispatch]);

  return (
    <>
      <Breadcrumb title="Upcoming Events" bgUrl="/images/page_header_1.jpeg" />

      <SectionHeadingStyle2
        sectionTitleUp={"Upcoming Events"}
        sectionTitle={
          'Discover <span className="text-accent"> exciting events and opportunities </span> <br />'
        }
      />
      <section className="px-4 pb-16 min-h-screen">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {events &&
            status !== "loading" &&
            events.map((event, index) => (
              <div
                className="bg-white hover:cursor-pointer shadow-md rounded-lg overflow-hidden transition-transform transform hover:scale-105"
                key={index}
                onClick={() => navigate(`/event/${event?._id}`)}
              >
                <div className="relative">
                  {event?.imgUrl && (
                    <img src={event.imgUrl} alt={event.title} className="" />
                  )}

                  <div className="p-4">
                    <h2 className="text-xl font-semibold">
                      <Link className="hover:underline">
                        {parse(event.title)}
                      </Link>
                    </h2>
                    <div className="cs_post_subtitle">
                      {parse(event.description.slice(0, 100))}
                      {event.description.length > 100 && "..."}
                    </div>
                    <p className="text-sm mt-2">
                      <FaCalendarAlt className="inline-block mr-1" />{" "}
                      <span className="font-bold">Starts In: </span>
                      {formatDate(event.startDate)}
                    </p>
                    <p className="text-sm mt-2">
                      <FaRegClock className="inline-block mr-1" />{" "}
                      <span className="font-bold">Ends In: </span>
                      {formatDate(event.endDate)}
                    </p>

                    <p className="text-sm mt-2">
                      <FaMapMarkerAlt className="inline-block mr-1 text-gray-600" />
                      <span className="font-bold">Location: </span>
                      {event?.location}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          {events?.length === 0 && status !== "loading" && (
            <div className="flex items-center justify-center text-xl">
              No Events found
            </div>
          )}

          {status === "loading" && (
            <div className="flex items-center justify-center text-xl">
              Loading...
            </div>
          )}
        </div>
      </section>
    </>
  );
}
