import { useEffect } from "react";
import { pageTitle } from "../../helpers/PageTitle";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaCalendarAlt, FaRegClock, FaMapMarkerAlt } from "react-icons/fa"; // Importing the icons
import { selectEventById } from "../../store/slices/event-slice";
import Breadcrumb from "../Breadcrumb";

export default function EventDetailsPage() {
  pageTitle("Event Details");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { eventId } = useParams();
  const selectedEvent = useSelector((state) => selectEventById(state, eventId));

  return (
    <>
      <Breadcrumb />
      <section className="cs_pt_25 cs_pt_lg_10 cs_pb_110 cs_pb_lg_50 mt-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 ">
              <div className="cs_post cs_style_1 bg-white shadow-sm cs_mb_30">
                <img className="max-h-[300px] w-[70%]" src={selectedEvent?.imgUrl} alt="Thumb" />
                <div className="cs_pl_40 cs_pr_40 cs_pt_40 cs_pb_40 cs_pl_lg_25 cs_pr_lg_25 cs_pt_lg_25 cs_pb_lg_25">
                  <h2 className="cs_post_title cs_lh_base cs_fs_26 cs_fs_lg_18 cs_mb_20">
                    {selectedEvent?.title}
                  </h2>
                  <div className="cs_post_subtitle">{selectedEvent?.description}</div>
                </div>
              </div>
              
            </div>
            <div className="col-lg-6">
              <section className="py-10 bg-gray-100">
                <div className="container flex items-start mx-10 justify-center flex-col">
                  <h6 className="cs_fs_30 cs_fs_lg_36 text-start mb-10 flex">
                    Event Information
                  </h6>
                  <div className="flex flex-col items-start space-y-4 text-center">
                    <div className="flex justify-center items-center gap-4">
                      <FaCalendarAlt className="text-4xl text-accent" />
                      <h4 className="text-lg font-semibold">
                        Start Date:{" "}
                        {new Date(selectedEvent.startDate).toLocaleDateString()}
                      </h4>
                    </div>
                    <div className="flex justify-center items-center gap-4">
                      <FaRegClock className="text-4xl text-accent" />
                      <h4 className="text-lg font-semibold">
                        End Date:{" "}
                        {new Date(selectedEvent.endDate).toLocaleDateString()}
                      </h4>
                    </div>
                    <div className="flex justify-center items-center gap-4">
                      <FaMapMarkerAlt className="text-4xl text-accent" />
                      <h4 className="text-lg font-semibold">
                        Location: {selectedEvent.location}
                      </h4>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
