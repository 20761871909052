import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import AddJobModal from "../components/job/AddJobModal";
import { getJobs } from "../../../store/slices/job-slice";
import JobTable from "../components/job/JobsTable";
import { pageTitle } from "../../../helpers/PageTitle";

export default function AdminJobPage() {
  pageTitle("Admin Jobs");
  const location = useLocation();
  const modalOpen = location?.state?.modalOpen;
  const [isModalOpen, setIsModalOpen] = useState(modalOpen ? modalOpen : false);
  const dispatch = useDispatch();

  const { jobs } = useSelector((state) => state.job);

  useEffect(() => {
    dispatch(getJobs({ page: 1, pageSize: 5 }));
  }, [dispatch]);

  return (
    <div className="p-4">
      <div className="flex flex-row justify-between items-center ">
        <h2 className="te text-lg font-semibold">Jobs</h2>
        <Link
          onClick={() => setIsModalOpen(true)}
          className="border  flex items-center justify-center gap-2 hover:text-accent  rounded-2xl px-4 py-2 shadow-md  transition duration-300"
        >
          <FaPlus />
          <span className="">Create job</span>
        </Link>
      </div>

      <JobTable jobs={jobs} />

      <AddJobModal
        isOpen={isModalOpen}
        handleModalClose={() => setIsModalOpen(false)}
      />
    </div>
  );
}
