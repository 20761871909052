import parse from "html-react-parser";
import { Link } from "react-router-dom";

export default function Hero({
  bgUrl,
  titleUp,
  title,
  subTitle,
  btnText,
  btnUrl,
  isUrlExternal
}) {  
  return (
    <div className="cs_hero cs_style_1 d-flex align-items-center justify-content-center background-filled position-relative overflow-hidden">
      <figure
        className="cs_swiper_parallax_bg"
        style={{ backgroundImage: `url(${bgUrl})` }}
      >
        <div className="bg-primary opacity-75 position-absolute w-100 h-100 start-0 top-0" />
      </figure>
      <div className="container">
        <div className="cs_hero_text position-relative cs_zindex_5 d-inline-block">
          <h2 className="text-white cs_mb_5 fw-normal cs_fs_18">
            {parse(titleUp)}
          </h2>
          <h4 className="text-white cs_mb_16 cs_fs_50 cs_fs_lg_46">
            {parse(title)}
          </h4>
          <p className="text-white cs_mb_20">{parse(subTitle)}</p>
          <div className="cs_hero_btn">
            <Link
              to={btnUrl}
              target={isUrlExternal ? "_blank" : "_self"}
              className="cs_btn cs_style_1 cs_fs_16 cs_rounded_5 cs_pl_30 cs_pr_30 cs_pt_10 cs_pb_10 overflow-hidden"
            >
              <span>{btnText}</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
