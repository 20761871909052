import React from "react";
import SideBarItem from "./SidebarItem";
import { useLocation } from "react-router-dom";
import { GrHomeRounded } from "react-icons/gr";
import {
  FaBriefcase,
  FaRegNewspaper,
  FaBlogger,
  FaHandshake,
  FaBook,
  FaCalendarAlt,
  FaUsers,
} from "react-icons/fa";
import { GiMoneyStack } from "react-icons/gi";
import { MdLibraryBooks } from "react-icons/md";
import { IoMdChatbubbles } from "react-icons/io";

const Sidebar = () => {
  const location = useLocation();
  const { pathname } = location;

  const routes = [
    {
      path: "/admin",
      title: "Dashboard",
      icon: GrHomeRounded,
      active: pathname === "/admin",
    },

    {
      path: "/admin/job",
      title: "Jobs",
      icon: FaBriefcase,
      active: pathname.startsWith("/admin/job"),
    },

    {
      path: "/admin/blog",
      title: "Blog",
      icon: FaBlogger,
      active: pathname.startsWith("/admin/blog"),
    },
    {
      path: "/admin/portifolio",
      title: "Portifolios",
      icon: MdLibraryBooks,
      active: pathname.startsWith("/admin/portifolio"),
    },
    {
      path: "/admin/testimony",
      title: "Testimonies",
      icon: IoMdChatbubbles,
      active: pathname.startsWith("/admin/testimony"),
    },
    {
      path: "/admin/opportunities",
      title: "Opportunities",
      icon: GiMoneyStack,
      active: pathname.startsWith("/admin/opportunities"),
    },
    {
      path: "/admin/e-magazine",
      title: "E-Magazine",
      icon: FaRegNewspaper,
      active: pathname.startsWith("/admin/e-magazine"),
    },
    {
      path: "/admin/partner",
      title: "Partners",
      icon: FaHandshake,
      active: pathname.startsWith("/admin/partner"),
    },

    {
      path: "/admin/team-members",
      title: "Team Members",
      icon: FaUsers,
      active: pathname.startsWith("/admin/team-members"),
    },

    {
      path: "/admin/resource",
      title: "Resources",
      icon: FaBook,
      active: pathname.startsWith("/admin/resource"),
    },

    {
      path: "/admin/event",
      title: "Events",
      icon: FaCalendarAlt,
      active: pathname.startsWith("/admin/event"),
    },
  ];

  return (
    <div className="flex-col bg-white dark:bg-dark-gray-700 h-full">
      <div className="flex items-center justify-center p-6 py-10">
        <img className="h-16" src="/image.png" alt="Logo" />
      </div>
      <div className="flex flex-col gap-2 overflow-y-auto h-[calc(100vh-150px)]">
        {routes.map((route) => (
          <div key={route.path}>
            <SideBarItem
              path={route.path}
              icon={route.icon}
              title={route.title}
              active={route.active}
              onClick={() => {}}
            />
            <hr className="dark:border-dark-gray-900" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
