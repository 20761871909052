import React, { useState } from "react";
import API from "../../../helpers/api";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setError("");

    try {
      setLoading(true);
      const response = await API.post("/auth/forgot-password", { email });

      if (response?.data?.success) {
        setMessage(
          "Password reset email sent successfully. Please check your email for the reset link."
        );
        setLoading(false);
      }
    } catch (err) {
        console.log(err,'');
        
      setError(err?.message || "Failed to send password reset email.");
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="bg-white px-6 py-6 shadow sm:rounded-lg sm:px-12 items-center flex flex-col justify-center">
        <h2 className="text-2xl font-bold text-center mb-4">Forgot Password</h2>

        <form className="flex flex-col my-4" onSubmit={handleSubmit}>
          <div className="flex flex-row items-center justify-center gap-3">
            <label
              htmlFor="email"
              className="block text-sm font-medium mt-3 leading-6 text-gray-900 whitespace-nowrap"
            >
              Email Address
            </label>

            <input
              className="form-control py-2 border-0 bg-gray"
              type="email"
              id="email"
              placeholder="Your email"
              name="email"
              value={email}
              onChange={handleInputChange}
              required
            />
          </div>

          {message && (
            <p className="text-green-600 max-w-[300px] mt-2 text-center">
              {message}
            </p>
          )}
          {error && <p className="text-red-600  mt-2 text-center ">{error}</p>}

          <div className="mt-10">
            <button
              type="submit"
              className="flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm bg-accent"
            >
              {loading ? (
                <img src="/images/loading.gif" alt="" />
              ) : (
                " Send Reset Link"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
