import React, { useEffect, useState } from "react";
import Breadcrumb from "../Breadcrumb";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { pageTitle } from "../../helpers/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { getResources } from "../../store/slices/resource-slice";
import SectionHeadingStyle2 from "../SectionHeading/SectionHeadingStyle2";
import { IoClose } from "react-icons/io5";

export default function ResourcePage({ data }) {
  pageTitle("Resources");

  const { resources, status } = useSelector((state) => state.resource);
  const dispatch = useDispatch();
  const [expandedImage, setExpandedImage] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getResources({ page: 1, pageSize: 500 }));
  }, [dispatch]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setExpandedImage(null);
      }
    };

    // Attach event listener
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleImageClick = (imgUrl) => {
    setExpandedImage(imgUrl);
  };

  const handleClose = () => {
    setExpandedImage(null);
  };

  return (
    <>
      <Breadcrumb title="Latest Blog" bgUrl="/images/page_header_1.jpeg" />
      <SectionHeadingStyle2
        sectionTitleUp={"Our Resources"}
        sectionTitle={
          'Access valuable <span className="text-accent"> information </span> and <br /> tools for success'
        }
      />
      <section className="cs_pt_10 cs_pt_lg_20 cs_pb_100 cs_pb_lg_80 px-4">
        <div className="row">
          {resources &&
            status !== "loading" &&
            resources?.map((item, index) => (
              <div className="col-xl-3 col-md-6" key={index}>
                <div className="cs_service cs_style_1 cs_pt_25 cs_pl_25 cs_pr_25 cs_pb_15 bg-white cs_transition_4 shadow cs_mb_25">
                  <div className="cs_service_iconbox d-flex align-items-center cs_mb_20">
                    <h2 className="cs_lh_base cs_fs_20 cs_fs_lg_18 m-0">
                      <Link className="inline-block">{parse(item.title)}</Link>
                    </h2>
                  </div>
                  <p className="cs_mb_24">{parse(item.description)}</p>
                  {item.link && (
                    <div>
                      <span className="font-bold">Link: </span>
                      <Link className="inline-block">{parse(item.link)}</Link>
                    </div>
                  )}
                  <div className="cs_service_thumb position-relative cs_rounded_5">
                    <div
                      className="cs_service_thumb-in position-relative-in background-filled h-100 cursor-pointer" // Added cursor-pointer for better UX
                      style={{
                        backgroundImage: `url(${item.imgUrl})`,
                      }}
                      onClick={() => handleImageClick(item.imgUrl)}
                    />
                  </div>
                </div>
              </div>
            ))}
          {resources?.length === 0 && status !== "loading" && (
            <div className="flex items-center justify-center text-xl">
              No Resource found
            </div>
          )}

          {status === "loading" && (
            <div className="flex items-center justify-center text-xl">
              Loading...
            </div>
          )}

          {expandedImage && (
            <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
              <img
                src={expandedImage}
                alt="Expanded"
                className="max-w-full max-h-full object-contain"
              />
              <button
                onClick={handleClose}
                className="absolute top-4 right-4 text-white text-3xl"
              >
                <IoClose />
              </button>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
