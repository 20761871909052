import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";

import { ModalHeader } from "../modal/ModalHeader";
import { ModalBody } from "../modal/ModalBody";
import { ModalFooter } from "../modal/ModalFooter";
import {
  createTeamMember,
  selectTeamMemberById,
  updateTeamMember,
} from "../../../../store/slices/teamMember-slice";
import ImageUploader from "../blog/ImageUploader";

const defaultData = {
  name: "",
  role: "",
  image: "",
  facebook: "",
  linkedin: "",
  telegram: "",
  whatsapp: "",
};

const AddTeamMemberModal = ({ handleModalClose, id, isOpen }) => {
  const dispatch = useDispatch();

  const selectedTeamMember = useSelector((state) =>
    selectTeamMemberById(state, id)
  );
  
  const [formData, setFormData] = useState(defaultData);

  useEffect(() => {
    if (id && selectedTeamMember) {
      setFormData({
        name: selectedTeamMember.name,
        role: selectedTeamMember.role,
        image: selectedTeamMember.image,
        facebook: selectedTeamMember.facebook,
        linkedin: selectedTeamMember.linkedin,
        telegram: selectedTeamMember.telegram,
        whatsapp: selectedTeamMember.whatsapp,
      });
    }
  }, [id, selectedTeamMember]);

  const handleSave = async (event) => {
    event.preventDefault();

    if (id) {
      const updatedData = { ...formData };
      dispatch(updateTeamMember({ id, updatedData }));
    } else {
      dispatch(createTeamMember(formData));
    }
    handleModalClose();
    setFormData(defaultData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageUploadSuccess = (url) => {
    setFormData((prevState) => ({
      ...prevState,
      image: url,
    }));
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleModalClose}
      contentLabel={id ? "Edit Team Member" : "Add Team Member"}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
        },
        overlay: {
          background: "rgba(0,0,0,0.10)",
        },
      }}
    >
      <ModalHeader
        title={id ? "Edit Team Member" : "Add Team Member"}
        handleModalClose={handleModalClose}
        borderBottom={true}
      />
      <ModalBody>
        <form className="flex flex-col max-h-[400px]" onSubmit={handleSave}>
          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              Name
            </div>
            <div className="w-9/12">
              <input
                id="name"
                name="name"
                className="form-control cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full placeholder-gray-400"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter team member name"
                required
              />
            </div>
          </div>

          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              Role
            </div>
            <div className="w-9/12">
              <input
                id="role"
                name="role"
                className="form-control cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full placeholder-gray-400"
                value={formData.role}
                onChange={handleChange}
                placeholder="Enter team member role"
                required
              />
            </div>
          </div>

          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              Facebook
            </div>
            <div className="w-9/12">
              <input
                id="facebook"
                name="facebook"
                className="form-control cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full placeholder-gray-400"
                value={formData.facebook}
                onChange={handleChange}
                placeholder="Enter Facebook URL"
              />
            </div>
          </div>

          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              LinkedIn
            </div>
            <div className="w-9/12">
              <input
                id="linkedin"
                name="linkedin"
                className="form-control cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full placeholder-gray-400"
                value={formData.linkedin}
                onChange={handleChange}
                placeholder="Enter LinkedIn URL"
              />
            </div>
          </div>

          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              Telegram
            </div>
            <div className="w-9/12">
              <input
                id="telegram"
                name="telegram"
                className="form-control cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full placeholder-gray-400"
                value={formData.telegram}
                onChange={handleChange}
                placeholder="Enter Telegram URL"
              />
            </div>
          </div>

          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              WhatsApp
            </div>
            <div className="w-9/12">
              <input
                id="whatsapp"
                name="whatsapp"
                className="form-control cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full placeholder-gray-400"
                value={formData.whatsapp}
                onChange={handleChange}
                placeholder="Enter WhatsApp url"
              />
            </div>
          </div>

          <div className="flex w-full pb-4 gap-2 items-center">
            <div className="mr-16 h-9 flex items-center text-sm font-semibold">
              Image
            </div>
            <div>
              <ImageUploader onUploadSuccess={handleImageUploadSuccess} />
            </div>
          </div>

          <ModalFooter>
            <div className="flex justify-end gap-2">
              <button
                className="flex items-center shadow-md px-3 py-1 rounded-md border hover:text-accent hover:bg-gray-300 focus:outline-none"
                onClick={handleModalClose}
              >
                <span>Close</span>
              </button>

              <button
                type="submit"
                disabled={!id && !formData.image}
                className="flex items-center disabled:cursor-not-allowed shadow-md px-3 py-1 rounded-md border hover:bg-gray-300 hover:text-accent bg-accent text-white"
              >
                Save
              </button>
            </div>
          </ModalFooter>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default AddTeamMemberModal;
