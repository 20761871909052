import { useEffect } from "react";
import Gallery from "../Gallery";
import Section from "../Section";
import { pageTitle } from "../../helpers/PageTitle";
import Breadcrumb from "../Breadcrumb";
import SectionHeadingStyle2 from "../SectionHeading/SectionHeadingStyle2";

export default function PortfolioPage() {
  pageTitle("Portfolio");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Breadcrumb
        title="Team Member Details"
        bgUrl="/images/page_header_1.jpeg"
      />
      <Section pt="10" ptLg="15" pb="125" pbLg="55" className="mt-2">
      <SectionHeadingStyle2
        sectionTitleUp={'Our Portifolios'}
        sectionTitle={'Showcasing <span className="text-accent"> diverse projects </span>  <br />'}
      />
        <div className="container">
          <Gallery />
        </div>
      </Section>
    </>
  );
}
