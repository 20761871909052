import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";

import { ModalHeader } from "../modal/ModalHeader";
import { ModalBody } from "../modal/ModalBody";
import { ModalFooter } from "../modal/ModalFooter";
import {
  createEvent,
  selectEventById,
  updateEvent,
} from "../../../../store/slices/event-slice"; // Update import for event slice
import ImageUploader from "../blog/ImageUploader"; // Keep this if you want to use it for image uploads

const defaultData = {
  title: "",
  description: "",
  imgUrl: "",
  startDate: new Date().toISOString().slice(0, 16),
  endDate: new Date().toISOString().slice(0, 16),
  location: "",
};

const AddEventModal = ({ handleModalClose, id, isOpen }) => {
  const dispatch = useDispatch();

  const selectedEvent = useSelector((state) => selectEventById(state, id));

  const [formData, setFormData] = useState(defaultData);

  useEffect(() => {
    if (id && selectedEvent) {
      setFormData({
        title: selectedEvent.title,
        description: selectedEvent.description,
        imgUrl: selectedEvent.imgUrl,
        startDate: selectedEvent.startDate,
        endDate: selectedEvent.endDate,
        location: selectedEvent.location,
      });
    }
  }, [id, selectedEvent]);

  const handleSave = async (event) => {
    event.preventDefault();

    if (id) {
      const updatedData = { ...formData };
      dispatch(updateEvent({ id, updatedData }));
    } else {
      dispatch(createEvent(formData));
    }
    handleModalClose();
    setFormData(defaultData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageUploadSuccess = (url) => {
    setFormData((prevState) => ({
      ...prevState,
      imgUrl: url,
    }));
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleModalClose}
      contentLabel="Add/Edit Event"
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
        },
        overlay: {
          background: "rgba(0,0,0,0.10)",
        },
      }}
    >
      <ModalHeader
        title={id ? "Edit Event" : "Add Event"} // Update title
        handleModalClose={handleModalClose}
        borderBottom={true}
      />
      <ModalBody>
        <form className="flex flex-col max-h-[500px]" onSubmit={handleSave}>
          {/* Title Field */}
          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              Title
            </div>
            <div className="w-9/12">
              <input
                name="title"
                className="form-control py-2 border-0 bg-gray w-full placeholder-gray-400"
                value={formData.title}
                onChange={handleChange}
                placeholder="Enter event title"
                required
              />
            </div>
          </div>

          {/* Description Field */}
          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              Description
            </div>
            <div className="w-9/12">
              <textarea
                name="description"
                className="form-control py-2 border-0 bg-gray w-full placeholder-gray-400"
                value={formData.description}
                onChange={handleChange}
                placeholder="Enter event description"
                required
              />
            </div>
          </div>

          {/* Start Date Field */}
          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              Start Date
            </div>
            <div className="w-9/12">
              <input
                type="datetime-local"
                name="startDate"
                className="form-control py-2 border-0 bg-gray w-full placeholder-gray-400"
                value={
                  (formData?.startDate &&
                    new Date(formData?.startDate).toISOString().slice(0, 16)) ||
                  new Date().toISOString().slice(0, 16)
                }
                onChange={handleChange}
                required
              />
            </div>
          </div>

          {/* End Date Field */}
          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              End Date
            </div>
            <div className="w-9/12">
              <input
                type="datetime-local"
                name="endDate"
                className="form-control py-2 border-0 bg-gray w-full placeholder-gray-400"
                value={
                  (formData?.endDate &&
                    new Date(formData?.endDate).toISOString().slice(0, 16)) ||
                  new Date().toISOString().slice(0, 16)
                }
                onChange={handleChange}
                required
              />
            </div>
          </div>

          {/* Location Field */}
          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              Location
            </div>
            <div className="w-9/12">
              <input
                name="location"
                className="form-control py-2 border-0 bg-gray w-full placeholder-gray-400"
                value={formData.location}
                onChange={handleChange}
                placeholder="Enter event location"
                required
              />
            </div>
          </div>

          {/* Image Upload Field */}
          <div className="flex w-full pb-4 gap-2 items-center">
            <div className="mr-16 h-9 flex items-center text-sm font-semibold">
              Image
            </div>
            <div>
              <ImageUploader onUploadSuccess={handleImageUploadSuccess} />
            </div>
          </div>

          <ModalFooter>
            <div className="flex justify-end gap-2">
              <button
                className="flex items-center shadow-md px-3 py-1 rounded-md border hover:text-accent hover:bg-gray-300 focus:outline-none"
                onClick={handleModalClose}
              >
                <span>Close</span>
              </button>

              <button
                type="submit"
                className="flex items-center disabled:cursor-not-allowed shadow-md px-3 py-1 rounded-md border hover:bg-gray-300 hover:text-accent bg-accent text-white"
              >
                Save
              </button>
            </div>
          </ModalFooter>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default AddEventModal;
