import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../helpers/api";

export const userLogin = createAsyncThunk(
  "user/Login",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(`/auth/login`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
;

const initialState = {
  status: null,
  isAuthenticated: false,
  error: undefined,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      localStorage.removeItem("token");
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.error = undefined;
        state.status = "loading";
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.isAuthenticated = true;
        localStorage.setItem("token", action.payload.token);
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
