import axios from "axios";

const API = axios.create({ baseURL: `${process.env.REACT_APP_BASE_URL}/api` });
API.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error,'eee');
    
    if (error.response) {
      
      return Promise.reject({
        message: error.response.data.message || error.message,
        statusCode: error.status,
      });
    } else {
      return Promise.reject({
        message: error.message || "Network Error",
        statusCode:501
      });
    }
  }
);

export default API;
