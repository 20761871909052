import { toast } from "react-toastify";

import { isAnyOf } from "@reduxjs/toolkit";
import {
  createBlog,
  deleteBlog,
  getBlogs,
  updateBlog,
} from "../slices/blog-slice";
import {
  createPortifolio,
  deletePortifolio,
  updatePortifolio,
} from "../slices/portifolio-slice";
import { createJob, deleteJob, updateJob } from "../slices/job-slice";
import {
  createTestimony,
  deleteTestimony,
  updateTestimony,
} from "../slices/testimony-slice";
import { logout, userLogin } from "../slices/auth-slice";
import {
  createMagazine,
  deleteMagazine,
  updateMagazine,
} from "../slices/e-magazine-slice";
import {
  createPartner,
  deletePartner,
  updatePartner,
} from "../slices/partner-slice";
import {
  createResource,
  deleteResource,
  updateResource,
} from "../slices/resource-slice";
import { createEvent, deleteEvent, updateEvent } from "../slices/event-slice";
import {
  createOpportunity,
  deleteOpportunity,
  updateOpportunity,
} from "../slices/opportunity-slice";
import {
  createTeamMember,
  deleteTeamMember,
  updateTeamMember,
} from "../slices/teamMember-slice";

export const errorResponseNotificationListener = (startListening) => {
  startListening({
    matcher: isAnyOf(
      createBlog.rejected,
      updateBlog.rejected,
      deleteBlog.rejected,
      createPortifolio.rejected,
      updatePortifolio.rejected,
      deletePortifolio.rejected,
      createJob.rejected,
      updateJob.rejected,
      deleteJob.rejected,
      createTestimony.rejected,
      updateTestimony.rejected,
      deleteTestimony.rejected,
      getBlogs.rejected,
      userLogin.rejected,
      createMagazine.rejected,
      updateMagazine.rejected,
      deleteMagazine.rejected,
      createPartner.rejected,
      deletePartner.rejected,
      updatePartner.rejected,
      createResource.rejected,
      updateResource.rejected,
      deleteResource.rejected,
      createEvent.rejected,
      deleteEvent.rejected,
      updateEvent.rejected,
      createOpportunity.rejected,
      deleteOpportunity.rejected,
      updateOpportunity.rejected,
      createTeamMember.rejected,
      updateTeamMember.rejected,
      deleteTeamMember.rejected
    ),
    effect: (action, listenerApi) => {
      if (action.payload && ![401, 403].includes(action.payload?.statusCode)) {
        toast.error(action.payload.message);
      } else {
        setTimeout(() => {
          window.location.href = "/admin/login";
        }, 100);
        listenerApi.dispatch(logout());
        toast.error(action.payload.message);
      }
    },
  });
};
