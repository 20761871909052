import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../helpers/api";

// Fetch eMagazines
export const getMagazines = createAsyncThunk(
  "magazine/fetch",
  async ({ page, pageSize }, { rejectWithValue }) => {
    try {
      const response = await API.get(`/magazines`, {
        params: { page: page, pageSize: pageSize },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Create new eMagazine
export const createMagazine = createAsyncThunk(
  "magazine/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(`/magazines`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Update an existing eMagazine
export const updateMagazine = createAsyncThunk(
  "magazine/update",
  async ({ id, updatedData }, { rejectWithValue }) => {
    try {
      const response = await API.put(`/magazines/${id}`, updatedData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Delete an eMagazine
export const deleteMagazine = createAsyncThunk(
  "magazine/delete",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await API.delete(`/magazines/${id}`);
      return { ...response.data, id };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  magazines: [],
  status: null,
  error: undefined,
  total: 0,
  page: 1,
  pageSize: 5,
};

const magazineSlice = createSlice({
  name: "magazine",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      //  Fetch eMagazines
      .addCase(getMagazines.pending, (state) => {
        state.error = undefined;
        state.status = "loading";
        state.magazines = [];
      })
      .addCase(getMagazines.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.magazines = action.payload.magazines;
        state.total = action.payload.total;
      })
      .addCase(getMagazines.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      })

      // Create eMagazine
      .addCase(createMagazine.pending, (state) => {
        state.error = undefined;
        state.status = "loading";
      })
      .addCase(createMagazine.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.magazines.unshift(action.payload.magazine);
      })
      .addCase(createMagazine.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      })

      // Update eMagazine
      .addCase(updateMagazine.fulfilled, (state, action) => {
        const index = state.magazines.findIndex(
          (magazine) => magazine._id === action.payload.magazine._id
        );
        if (index !== -1) {
          state.magazines[index] = action.payload.magazine;
        }
        state.status = "succeeded";
      })
      .addCase(updateMagazine.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateMagazine.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Delete eMagazine
      .addCase(deleteMagazine.fulfilled, (state, action) => {
        state.magazines = state.magazines.filter(
          (magazine) => magazine._id !== action.payload.id
        );
        state.status = "succeeded";
      })
      .addCase(deleteMagazine.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteMagazine.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

// Selector to get a specific magazine by ID
export const selectMagazineById = (state, id) => {
  return state.magazine?.magazines?.find((magazine) => magazine._id === id);
};

export default magazineSlice.reducer;
