import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../helpers/api";

// Fetch testimonies with pagination
export const getTestimonies = createAsyncThunk(
  "testimony/fetch",
  async ({ page, pageSize }, { rejectWithValue }) => {
    try {
      const response = await API.get(`/testimonies`, {
        params: { page, pageSize },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Create a new testimony
export const createTestimony = createAsyncThunk(
  "testimony/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(`/testimonies`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Update an existing testimony
export const updateTestimony = createAsyncThunk(
  "testimony/update",
  async ({ id, updatedData }, { rejectWithValue }) => {
    try {
      const response = await API.put(`/testimonies/${id}`, updatedData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Delete a testimony
export const deleteTestimony = createAsyncThunk(
  "testimony/delete",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await API.delete(`/testimonies/${id}`);
      return { ...response.data, id };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  testimonies: [],
  status: null,
  error: undefined,
  total: 0,
  page: 1,
  pageSize: 5,
};

const testimonySlice = createSlice({
  name: "testimony",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      //  get Testimonies
      .addCase(getTestimonies.pending, (state) => {
        state.error = undefined;
        state.status = "loading";
        state.testimonies = [];
      })
      .addCase(getTestimonies.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.testimonies = action.payload.testimonies;
        state.total = action.payload.total;
      })
      .addCase(getTestimonies.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      })

      // create Testimony
      .addCase(createTestimony.pending, (state) => {
        state.error = undefined;
        state.status = "loading";
      })
      .addCase(createTestimony.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.testimonies.unshift(action.payload.testimony);
      })
      .addCase(createTestimony.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      })

      // Handle update Testimony
      .addCase(updateTestimony.fulfilled, (state, action) => {
        const index = state.testimonies.findIndex(
          (testimony) => testimony._id === action.payload.testimony._id
        );
        if (index !== -1) {
          state.testimonies[index] = action.payload.testimony;
        }
        state.status = "succeeded";
      })
      .addCase(updateTestimony.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateTestimony.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Handle delete Testimony
      .addCase(deleteTestimony.fulfilled, (state, action) => {
        state.testimonies = state.testimonies.filter(
          (testimony) => testimony._id !== action.payload.id
        );
        state.status = "succeeded";
      })
      .addCase(deleteTestimony.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteTestimony.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const selectTestimonyById = (state, id) => {
  return state.testimony?.testimonies?.find(
    (testimony) => testimony._id === id
  );
};

export default testimonySlice.reducer;
