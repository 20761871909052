import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";

import { ModalHeader } from "../modal/ModalHeader";
import { ModalBody } from "../modal/ModalBody";
import { ModalFooter } from "../modal/ModalFooter";
import {
  createJob,
  selectJobById,
  updateJob,
} from "../../../../store/slices/job-slice";

const defaultData = {
  title: "",
  location: "",
  type: "Full-time",
  salary: "",
  description: "",
  requirements: "",
  company: "",
  contactInfo: "",
  experienceLevel: "Entry-level",
  numberOfOpenings: 1,
  formLink:""
};

const AddJobModal = ({ handleModalClose, id, isOpen }) => {
  const dispatch = useDispatch();

  const selectedJob = useSelector((state) => selectJobById(state, id));
  const [formData, setFormData] = useState(defaultData);

  useEffect(() => {
    if (id && selectedJob) {
      setFormData({
        title: selectedJob.title,
        location: selectedJob.location,
        type: selectedJob.type,
        salary: selectedJob.salary,
        description: selectedJob.description,
        requirements: selectedJob.requirements,
        company: selectedJob.company,
        contactInfo: selectedJob.contactInfo,
        experienceLevel: selectedJob.experienceLevel,
        numberOfOpenings: selectedJob.numberOfOpenings,
        formLink : selectedJob.formLink
      });
    }
  }, [id, selectedJob]);

  const handleSave = async (event) => {
    event.preventDefault();

    if (id) {
      const updatedData = { ...formData };
      dispatch(updateJob({ id, updatedData }));
    } else {
      dispatch(createJob(formData));
    }
    handleModalClose();
    setFormData(defaultData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleModalClose}
      contentLabel="Add/Edit Job"
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "50vw",
        },
        overlay: {
          background: "rgba(0,0,0,0.10)",
        },
      }}
    >
      <ModalHeader
        title={id ? "Edit Job" : "Add Job"}
        handleModalClose={handleModalClose}
        borderBottom={true}
      />
      <ModalBody>
        <form className="flex flex-col max-h-[400px]" onSubmit={handleSave}>
          {/* Job Title */}
          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              Job Title
            </div>
            <div className="w-9/12">
              <input
                id="title"
                name="title"
                className="form-control cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full placeholder-gray-400"
                value={formData.title}
                onChange={handleChange}
                placeholder="Enter job title"
                required
              />
            </div>
          </div>

          {/* Location */}
          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              Location
            </div>
            <div className="w-9/12">
              <input
                id="location"
                name="location"
                className="form-control cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full"
                value={formData.location}
                onChange={handleChange}
                placeholder="Enter job location"
                required
              />
            </div>
          </div>

          {/* Job Type */}
          <div className="flex items-start w-full pb-4 font-semibold">
            <div className="w-3/12 h-9 flex items-center text-sm">Job Type</div>
            <div className="w-9/12">
              <select
                id="type"
                name="type"
                className="form-control placeholder-gray-400 cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full"
                value={formData.type}
                onChange={handleChange}
              >
                <option value="Full-time">Full-time</option>
                <option value="Part-time">Part-time</option>
                <option value="Contract">Contract</option>
                <option value="Internship">Internship</option>
              </select>
            </div>
          </div>

          {/* experiance level */}
          <div className="flex items-start w-full pb-4 font-semibold">
            <div className="w-3/12 h-9 flex items-center text-sm">
              Experience Level
            </div>
            <div className="w-9/12">
              <select
                id="experienceLevel"
                name="experienceLevel"
                className="form-control placeholder-gray-400 cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full"
                value={formData.experienceLevel}
                onChange={handleChange}
                required
              >
                <option value="">Select Experience Level</option>
                <option value="Entry-level">Entry-level</option>
                <option value="Mid-level">Mid-level</option>
                <option value="Senior-level">Senior-level</option>
              </select>
            </div>
          </div>

          {/* Salary */}
          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              Salary
            </div>
            <div className="w-9/12">
              <input
                id="salary"
                name="salary"
                className="form-control cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full"
                value={formData.salary}
                onChange={handleChange}
                placeholder="Enter salary (optional)"
              />
            </div>
          </div>

          {/* Salary */}
          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              Number Of Openings
            </div>
            <div className="w-9/12">
              <input
                id="numberOfOpenings"
                name="numberOfOpenings"
                className="form-control cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full"
                value={formData.numberOfOpenings}
                onChange={handleChange}
                placeholder="Enter number of openings"
              />
            </div>
          </div>

          {/* Description */}
          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              Description
            </div>
            <div className="w-9/12">
              <textarea
                id="description"
                name="description"
                className="form-control cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full"
                value={formData.description}
                onChange={handleChange}
                placeholder="Enter job description"
                required
              />
            </div>
          </div>

          {/* Requirements */}
          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              Requirements
            </div>
            <div className="w-9/12">
              <textarea
                id="requirements"
                name="requirements"
                className="form-control cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full"
                value={formData.requirements}
                onChange={handleChange}
                placeholder="Enter comma separated requirements"
              />
            </div>
          </div>

          {/* Company */}
          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              Company
            </div>
            <div className="w-9/12">
              <input
                id="company"
                name="company"
                className="form-control cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full"
                value={formData.company}
                onChange={handleChange}
                placeholder="Enter company name"
              />
            </div>
          </div>

          {/* Contact Info */}
          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              Contact Info
            </div>
            <div className="w-9/12">
              <input
                id="contactInfo"
                name="contactInfo"
                className="form-control cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full"
                value={formData.contactInfo}
                onChange={handleChange}
                placeholder="Enter contact info"
              />
            </div>
          </div>


          {/* Contact Info */}
          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              Job Form
            </div>
            <div className="w-9/12">
              <input
                id="formLink"
                name="formLink"
                className="form-control cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full"
                value={formData.formLink}
                onChange={handleChange}
                placeholder="Enter job form link"
              />
            </div>
          </div>



          <ModalFooter>
            <div className="flex justify-end gap-2">
              <button
                className="flex items-center shadow-md px-3 py-1 rounded-md border hover:text-accent hover:bg-gray-100"
                onClick={handleModalClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="shadow-md px-3 py-1 rounded-md text-white bg-blue-500 hover:bg-blue-600"
              >
                {id ? "Save" : "Add Job"}
              </button>
            </div>
          </ModalFooter>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default AddJobModal;
