import { combineReducers, configureStore } from "@reduxjs/toolkit";
import blogReducer from "./slices/blog-slice";
import portifolioReducer from "./slices/portifolio-slice";
import jobReducer from "./slices/job-slice";
import testimonyReducer from "./slices/testimony-slice"
import authReducer from "./slices/auth-slice"
import magazineReducer from "./slices/e-magazine-slice"
import parnerReducer from "./slices/partner-slice"
import resourceReducer from "./slices/resource-slice"
import opportunityReducer from "./slices/opportunity-slice"
import teamMemberReducer from "./slices/teamMember-slice"
import eventReducer from "./slices/event-slice"

import { listenerMiddleware } from "./listeners";

const store = configureStore({
  reducer: combineReducers({
    blog: blogReducer,
    portifolio: portifolioReducer,
    job: jobReducer,
    testimony:testimonyReducer,
    auth:authReducer,
    magazine:magazineReducer,
    partner:parnerReducer,
    resource:resourceReducer,
    event:eventReducer,
    opportunity:opportunityReducer,
    teamMember:teamMemberReducer,
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware),
});

export default store;
