import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { getPartners } from "../../../../store/slices/partner-slice";
import AddPartnerModal from "./AddPartnerModal";
import DeletePartnerModal from "./DeletePartnerModal";

const PartnerTable = ({ partners }) => {
  const { status, total, pageSize } = useSelector((state) => state.partner);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedPartnerId, setSelectedPartner] = useState();

  const handleEdit = (id) => {
    setSelectedPartner(id);
    setEditModalOpen(true);
  };

  const handleDelete = (id) => {
    setSelectedPartner(id);
    setDeleteModalOpen(true);
  };

  const dispatch = useDispatch();

  const handlePaginationModelChange = (pagination) => {
    dispatch(
      getPartners({
        page: pagination.page + 1,
        pageSize: pagination.pageSize,
      })
    );
  };

  const columns = [
    { field: "_id", headerName: "ID", flex: 0.5 },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "imageUrl", headerName: "Image URL", flex: 1.5 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <div className="flex flex-row gap-3 mt-2 items-center justify-center">
          <button
            className="text-blue-500 text-xl"
            onClick={() => handleEdit(params.row._id)}
          >
            <FaEdit />
          </button>
          <button
            className="text-red-500 text-xl"
            onClick={() => handleDelete(params.row._id)}
          >
            <FaTrashAlt />
          </button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <DataGrid
        sx={{ m: 2 , minHeight: '400px',}}
        style={{ padding: "12px" }}
        getRowId={(row) => row._id}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 5, page: 0 },
          },
        }}
        columns={columns}
        rows={partners}
        rowCount={total}
        loading={status === "loading"}
        pageSize={pageSize}
        pagination
        paginationMode="server"
        pageSizeOptions={[5, 10, 15]}
        onPaginationModelChange={handlePaginationModelChange}
      />

      <AddPartnerModal
        isOpen={isEditModalOpen}
        handleModalClose={() => setEditModalOpen(false)}
        id={selectedPartnerId}
      />

      <DeletePartnerModal
        handleModalClose={() => setDeleteModalOpen(false)}
        isOpen={deleteModalOpen}
        partnerId={selectedPartnerId}
      />
    </div>
  );
};

export default PartnerTable;
