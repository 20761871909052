import React from 'react'
import Iconbox from '../../Iconbox'
import SectionHeadingStyle3 from '../../SectionHeading/SectionHeadingStyle3'

export default function WhyChooseUsSection({sectionTitleUp, sectionTitle,sectionSubTitle, services, imgUrlLeft, imgUrlRight}) {
  return (
    <>
      <div className="container cs_copyright p-4 background-filled">
        <div className="row">
          <div className="col-xl-6">
            <div className="position-relative cs_zindex_3">
              <SectionHeadingStyle3 
                sectionTitleUp={sectionTitleUp}
                sectionTitle={sectionTitle}
                sectionSubTitle={sectionSubTitle}
                className={'cs_mb_60 cs_mb_lg_40 cs_column_gap_15 cs_row_gap_15'}
                textVarient={'text-white'}
              />
              <div className="row">
                {services.map((item, index) => (
                  <div className="col-sm-6" key={index}>
                    <Iconbox  icon={item.icon} title={item.title}/>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-xl-6" />
        </div>
      </div>
      <div className="cs_iconbox_left-img position-absolute bottom-0 start-0 h-100"
        style={{backgroundImage: `url('${imgUrlLeft}')`}}
      />
      <div className="cs_iconbox_right-img position-absolute cs_zindex_1 top-[134px] bottom-[100px] end-0  "
        style={{backgroundImage: `url('${imgUrlRight}')`}}
      />
    </>

  )
}
