import React, { useEffect, useState } from "react";
import { pageTitle } from "../../../helpers/PageTitle";
import {
  FaBriefcase,
  FaBlog,
  FaComment,
  FaImages,
  FaHandshake,
  FaNewspaper,
  FaPlus,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import API from "../../../helpers/api";

export default function AdminHome() {
  pageTitle("Admin Home");

  const [totals, setTotals] = useState({});
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const response = await API.get("/dashboard");
        if (response.data.success) {
          setTotals(response.data.data);
          setLoading(false)
        }
      } catch (error) {}
    };

    fetchData();
  }, []);

  const cardsData = [
    {
      title: "Jobs",
      total: totals.jobs,
      createLink: "/admin/job",
      icon: <FaBriefcase className="text-3xl text-blue-500" />,
    },
    {
      title: "Blogs",
      total: totals.blogs,
      createLink: "/admin/blog",
      icon: <FaBlog className="text-3xl text-green-500" />,
    },
    {
      title: "Testimonials",
      total: totals.testimonials,
      createLink: "/admin/testimony",
      icon: <FaComment className="text-3xl text-purple-500" />,
    },
    {
      title: "Portfolios",
      total: totals.portfolios,
      createLink: "/admin/portifolio",
      icon: <FaImages className="text-3xl text-yellow-500" />,
    },
    {
      title: "Opportunities",
      total: totals.trades,
      createLink: "/admin/opportunities",
      icon: <FaHandshake className="text-3xl text-red-500" />,
    },
    {
      title: "E-Magazines",
      total: totals.emagazines,
      createLink: "/admin/e-magazine",
      icon: <FaNewspaper className="text-3xl text-indigo-500" />,
    },
  ];
  return (
    <div className="p-4">
      <h3 className="text-2xl p-4 font-bold">Welcome to the Admin Dashboard</h3>
      <div className="p-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {
          loading && <div className="flex items-center justify-center mx-auto">
            <img src="/images/loading.gif" alt="" />
          </div>
        }
        {!loading && cardsData.map((card, index) => (
          <div
            key={index}
            className="bg-white shadow-lg rounded-lg p-6 flex flex-col justify-between hover:shadow-xl transform transition-all duration-300 hover:scale-105"
          >
            <div className="flex items-center space-x-4">
              <div className="icon">{card.icon}</div>
              <div>
                <h2 className="text-xl font-semibold ">{card.title}</h2>
                <p className="text-gray-600 ">Total: {card.total}</p>
              </div>
            </div>

            <Link
              to={card.createLink}
              state={{ modalOpen: true }}
              className="border flex items-center mt-3 justify-center gap-2 hover:text-accent  rounded-2xl px-4 py-2 shadow-md  transition duration-300"
            >
              <FaPlus />
              <span>Create {card.title}</span>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
