import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import API from "../../../helpers/api";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Extract reset token from the query params
  const queryParams = new URLSearchParams(location.search);
  const resetToken = queryParams.get("resetToken");

  useEffect(() => {
    if (!resetToken) {
      setError("Invalid or expired reset token.");
    }
  }, [resetToken]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setError("");

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      setLoading(true);
      const response = await API.post("/auth/reset-password", {
        resetToken,
        password,
      });
      console.log(response,'final response')
      if (response?.data?.success) {
        setLoading(false);
        setMessage("Password reset successful. You can now log in.");
        setTimeout(() => {
          navigate("/admin/login");
        }, 3000);
      }
    } catch (err) {        
      setError(err?.message || "Failed to reset password.");
      setLoading(false)
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="bg-white px-6 py-6 shadow sm:rounded-lg sm:px-12 items-center flex flex-col justify-center">
        <h2 className="text-2xl font-bold text-center mb-4">Reset Password</h2>

        <form className="flex flex-col gap-3 my-4" onSubmit={handleSubmit}>
          <div className="flex flex-row items-center justify-center gap-3">
            <label
              htmlFor="password"
              className="block text-sm font-medium mt-3 leading-6 text-gray-900 whitespace-nowrap"
            >
              New Password
            </label>
            <input
              className="form-control py-2 border-0 bg-gray"
              type="password"
              id="password"
              placeholder="Your new password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>

          <div className="flex flex-row items-center justify-center gap-3">
            <label
              htmlFor="confirmPassword"
              className="block text-sm font-medium mt-3 leading-6 text-gray-900 whitespace-nowrap"
            >
              Confirm Password
            </label>
            <input
              className="form-control py-2 border-0 bg-gray"
              type="password"
              id="confirmPassword"
              placeholder="Confirm your new password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>

          {message && <p className="text-green-600  mt-2 text-center">{message}</p>}
          {error && <p className="text-red-600  mt-2 text-center">{error}</p>}

          <div className="mt-10">
            <button
              type="submit"
              className="flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm bg-accent"
            >
              {loading && <img src="/images/loading.gif" alt="" />}
              {!loading && "Reset Password"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
