import React from "react";
import { FaBullseye, FaLightbulb, FaFlag } from "react-icons/fa";

export default function MissionVisionSection() {
  return (
    <div className="flex flex-col md:flex-row justify-between items-center px-4 py-16 mt-5 ">
      {/* Card 1 - Our Mission */}
      <div className="flex flex-col items-center shadow-xl  p-4 m-4 rounded-lg relative">
        <div className="absolute -top-10 flex justify-center items-center  border-2  rounded-full w-16 h-16">
          <FaBullseye className="text-accent hover:text-black text-3xl" />
        </div>
        <h3 className="text-xl font-semibold mt-10">Our Mission</h3>
        <p className="text-gray-600 mt-4 text-center">
          Facilitate transactions by connecting buyers and sellers by sharing
          information on the sale, purchase and rental of construction
          machinery, vehicles and various fixed assets.
        </p>
      </div>

      {/* Card 2 - Our Vision */}
      <div className="flex flex-col items-center shadow-xl  p-4 m-4 rounded-lg relative">
        <div className="absolute -top-10 flex border-2  justify-center items-center  rounded-full w-16 h-16">
          <FaLightbulb className="text-accent text-3xl" />
        </div>
        <h3 className="text-xl font-semibold mt-10">Our Vision</h3>
        <p className="text-gray-600 mt-4 text-center">
          Applying the online business plat form that the world has reached to
          our country, establishing an easy preferred marketing communication
          system for traders in various fields.
        </p>
      </div>

      {/* Card 3 - Our Goal */}
      <div className="flex flex-col items-center shadow-xl  p-4 m-4 rounded-lg relative">
        <div className="absolute -top-10 flex justify-center items-center border-2  rounded-full w-16 h-16">
          <FaFlag className="text-accent text-3xl" />
        </div>
        <h3 className="text-xl font-semibold mt-10">Our Goal</h3>
        <p className="text-gray-600 mt-4 text-center">
          Facilitate transactions by connecting buyers and sellers by sharing
          information on the sale, purchase and rental of construction
          machinery, vehicles and various fixed assets.
        </p>
      </div>
    </div>
  );
}
