import React, { useState } from "react";
import API from "../../helpers/api";
import { toast } from "react-toastify";

export default function NewsLetterWidget({ title, subTitle }) {
  const [email, setEmail] = useState("");
  // const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Loading state

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading when the form is submitted

    try {
      const response = await API.post("/newsletter/subscribe", { email });
      if (response.data.success) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
      setEmail("");
    } catch (error) {
      console.log(error);

      toast.error("Something went wrong, please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="cs_newsletter cs_style_1">
      <h2 className="cs_newsletter_title text-white cs_fs_22 cs_mb_9">
        {title}
      </h2>
      <p className="cs_newsletter_subtitle cs_mb_26">{subTitle}</p>
      <form
        className="cs_newsletter_form position-relative"
        onSubmit={handleSubmit}
      >
        <input
          type="email"
          className="cs_newsletter_input text-white cs_fs_14 cs_rounded_5 border-0 w-100 cs_pt_10"
          placeholder="Enter your mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          disabled={isLoading}
        />
        <button
          type="submit"
          className={`cs_newsletter_btn cs_fs_14 cs_rounded_5 cs_transition_4 bg-accent position-absolute text-uppercase px-6 items-center flex justify-center ${
            isLoading ? "disabled" : ""
          }`}
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : <span>Go</span>}
        </button>
      </form>
    </div>
  );
}
