import { useEffect } from "react";
import Post from "../Post";
import { pageTitle } from "../../helpers/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { getBlogs } from "../../store/slices/blog-slice";
import Breadcrumb from "../Breadcrumb";
import SectionHeadingStyle2 from "../SectionHeading/SectionHeadingStyle2";

export default function BlogGridPage() {
  pageTitle("Blog");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();

  const { blogs, status } = useSelector((state) => state.blog);

  useEffect(() => {
    dispatch(getBlogs({ page: 1, pageSize: 5 }));
  }, [dispatch]);

  return (
    <>
     <Breadcrumb
        title="Team Member Details"
        bgUrl="/images/page_header_1.jpeg"
      />
      <SectionHeadingStyle2
        sectionTitleUp={"Our Blogs"}
        sectionTitle={
          'Sharing <span className="text-accent"> valuable insights </span> <br />'
        }
      />

      <section className="cs_pt_25 cs_pt_lg_10 cs_pb_110 cs_pb_lg_50 mt-4">
        <div className="container">
          <div className="row">
            {blogs.map((item, index) => (
              <div className="col-lg-4" key={index}>
                <Post {...item} />
              </div>
            ))}
          </div>

          {!blogs && (
            <div className="font-bold h-screen flex items-center justify-center">
              No blogs found
            </div>
          )}

          {status === "loading" && (
            <div className="font-bold h-screen flex items-center justify-center">
              Loading....
            </div>
          )}
        </div>
      </section>
    </>
  );
}
