import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../helpers/api";

export const getBlogs = createAsyncThunk(
  "getBlogSlice/fetch",
  async ({ page, pageSize }, { rejectWithValue }) => {
    try {
      const response = await API.get(`/blogs`, {
        params: { page: page, pageSize: pageSize },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Create Blog
export const createBlog = createAsyncThunk(
  "createBlogSlice/create",
  async (blogData, { rejectWithValue }) => {
    try {
      const response = await API.post(`/blogs`, blogData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Update Blog
export const updateBlog = createAsyncThunk(
  "blog/update",
  async ({ id, updatedData }, { rejectWithValue }) => {
    try {
      const response = await API.put(`/blogs/${id}`, updatedData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Delete Blog
export const deleteBlog = createAsyncThunk(
  "blog/delete",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await API.delete(`/blogs/${id}`);
      return { ...response.data, id };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  blogs: [],
  status: null,
  error: undefined,
  total: 0,
  page: 1,
  pageSize: 5,
};

const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      //  get blogs
      .addCase(getBlogs.pending, (state) => {
        state.error = undefined;
        state.status = "loading";
        state.blogs = [];
      })
      .addCase(getBlogs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.blogs = action.payload.blogs;
        state.total = action.payload.total;
      })

      .addCase(getBlogs.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      })

      // create blog
      .addCase(createBlog.pending, (state) => {
        state.error = undefined;
        state.status = "loading";
      })
      .addCase(createBlog.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.blogs.unshift(action.payload.blog);
      })
      .addCase(createBlog.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      })

      // Handle updateBlog
      .addCase(updateBlog.fulfilled, (state, action) => {
        console.log(action.payload.blog, "blog in actions");

        const index = state.blogs.findIndex(
          (blog) => blog._id === action.payload.blog._id
        );
        if (index !== -1) {
          state.blogs[index] = action.payload.blog;
        }
        state.status = "succeeded";
      })
      .addCase(updateBlog.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateBlog.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Handle deleteBlog
      .addCase(deleteBlog.fulfilled, (state, action) => {
        state.blogs = state.blogs.filter(
          (blog) => blog._id !== action.payload.id
        );
        state.status = "succeeded";
      })
      .addCase(deleteBlog.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteBlog.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const selectBlogById = (state, id) => {
  return state.blog.blogs.find((blog) => blog._id === id);
};

export default blogSlice.reducer;
