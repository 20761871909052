const servicesData = [
  {
    id: 1,
    iconUrl: "/images/icons/service_icon_1.svg",
    title: "Rental & Sales Services",
    subTitle:
      "Comprehensive solutions for rentals and sales across various sectors.",
    subServices: [
      {
        id: 1.1,
        title: "Machinery",
        description:
          "We provide a diverse selection of machinery for both rental and purchase. Our inventory includes heavy-duty equipment such as excavators, loaders, bulldozers, and cranes, suitable for construction, mining, and large-scale industrial projects. Our machinery is well-maintained and available with flexible rental options.",
        imgUrl: "/images/services/machinary_4.png",
        question: "Interested in renting or buying machinery?",
        formUrl: "https://forms.gle/13obgNhdUxR5rTQQ8",
      },
      {
        id: 1.2,
        title: "Vehicle",
        description:
          "Our vehicle rental and sales services cater to a broad spectrum of needs, from commercial trucks and vans to personal cars and SUVs. We offer flexible rental terms and competitive pricing for short-term and long-term needs, as well as a selection of high-quality vehicles available for purchase.",
        imgUrl: "/images/services/vehicle_1.png",
        question: "Want to rent or purchase a vehicle?",
        formUrl: "https://forms.gle/ugTizs8gv3dtkrTb7",
      },
      {
        id: 1.3,
        title: "Property",
        description:
          "Explore our extensive portfolio of residential and commercial properties available for rent or sale. Whether you’re looking for a new home, an office space, or investment opportunities in land and buildings, we offer comprehensive services to help you find the perfect property to meet your needs.",
        imgUrl: "/images/services/sales_property.png",
        question: "Looking for a property to rent or buy?",
        formUrl: "https://forms.gle/Hb37B2HiBrNvptp46",
      },
      {
        id: 1.4,
        title: "Other",
        description:
          "If you have a specific rental or sales request that doesn’t fit into our listed services, we’re here to help! Whether it’s unique machinery, special vehicle requirements, or any other need, please reach out to us. We strive to accommodate your specific needs and provide tailored solutions.",
        imgUrl: "/images/services/rental_other_need.png",
        question: "Have other needs related to rentals or sales?",
        formUrl: "https://forms.gle/JJUS3pFwX9wkaMAg8",
      },
    ],

    faqData: [
      {
        title:
          "Can the equipment rental software help me view which equipment has a high demand and generates the most revenue?",
        desc: "Being in the equipment rental business, you must be familiar with the fact that not all equipment is in demand, and sometimes, even if there is immense demand, they may not generate high revenue.As a rental business, you must have data that shows which equipment is in high demand and the assets that fetch the highest fees. An equipment rental software solution can offer this data through analytics and business intelligence, equipping you to plan better.With KPI-based rental analytics, you can get insights into asset performance, utilization rates, booking trends, and more. It lets you view the demand trends, the repair and maintenance expenses, and the revenue generated.Based on this data, you can make calculated predictions about the equipment demand and plan your portfolio better.",
      },
      {
        title: "Is there a way I can manage package rental deals?",
        desc: "When companies conducting construction projects seek rental equipment, they often need more than one piece at a time. For instance, a rental project with cement mixers also requires tippers and trucks.Renting an equipment package with several pieces of equipment can be complex and requires multiple entries in the system. This can be quickly addressed with equipment rental software supporting rental kits. With rental kits, you can anticipate your customer's needs and offer them packages that will be valuable to them.By managing package rental deals, you can ease operations at the back end and ensure your customers have all the equipment required for a project.",
      },
      {
        title: "How many equipment suppliers are in your network?",
        desc: "Gadal is partnered with over 6,000 equipment suppliers",
      },
      {
        title: "How do your prices compare to other companies?",
        desc: "Gadal calculates prices 5% plus VAT rental commision fee from the advance payment and 2% commision fee plus VAT of Sales Prices from the total price. The price also reflect supplier rates, demand, and rental duration, ensuring competitive, tailored pricing for each job. Our all-in pricing, is usually 15-20% less expensive than traditional national providers.",
      },
    ],

    imgUrl: "/images/services/rental_lease.jpeg",
    href: "/service/rental-sales-details",
  },
  {
    id: 2,
    iconUrl: "/images/icons/service_icon_2.svg",
    title: "Real Estate Marketing",
    subTitle:
      "Marketing strategies tailored to promote and sell real estate properties effectively.",
    subServices: [
      {
        id: 2.1,
        title: "Real Estate Marketing",
        description:
          "Comprehensive marketing services to promote real estate properties across multiple channels. This includes online and offline strategies, professional photography, virtual tours, targeted digital ads, and SEO optimization to reach potential buyers or renters efficiently. Our approach is designed to maximize exposure, generate leads, and help sell or lease properties faster.",
        imgUrl: "/images/services/real_estate.png",
        question: "Interested in our real estate marketing services?",
        formUrl: "https://forms.gle/8pHCib5F5vtKfL1k9",
      },
    ],
    imgUrl: "/images/services/real_state_markating.jpeg",
    href: "/service/real-estate-marketing-details",
  },
  {
    id: 3,
    iconUrl: "/images/icons/service_icon_3.svg",
    title: "Asset Valuation",
    subTitle:
      "Expert valuation services for properties, machinery, and vehicles.",
    subServices: [
      {
        id: 3.1,
        title: "Property",
        description:
          "Our property valuation services provide accurate and reliable assessments of residential, commercial, and industrial properties. We use comprehensive market analysis and appraisal techniques to determine fair market value, supporting buying, selling, and investment decisions.",
        imgUrl: "/images/services/propery_valuation.png",
      },
      {
        id: 3.2,
        title: "Machinery",
        description:
          "We offer detailed valuation services for industrial and construction machinery. Our expertise includes evaluating equipment for sale, rental, or insurance purposes, ensuring that you receive an accurate valuation based on current market conditions and machinery specifics.",
        imgUrl: "/images/services/machinary_valuation.png",
      },
      {
        id: 3.3,
        title: "Vehicle",
        description:
          "Our vehicle valuation services provide precise appraisals for cars, trucks, and other vehicles. Whether for purchase, sale, or insurance, we deliver detailed valuations based on vehicle condition, market trends, and comparable sales data.",
        imgUrl: "/images/services/vehicle_valuation.png",
      },
    ],
    faqData: [
      {
        title: "Why do I need a valuation?",
        desc: "An individual or business requires valuations for the following purposes but not limited to lending, refinancing, lease & buyback, insolvency, tax, insurance, salvage estimates, auction values, superannuation, concessions. A valuation determines the market value of an asset. Contact Asset Valuations Group today for a valuation.",
      },
      {
        title: "Do Valuations Require a Site Inspection?",
        desc: "Most valuations require an inspection unless otherwise agreed upon by the client that a desktop valuation is required. When it comes to property valuations, a site inspection is mandatory.",
      },
      {
        title: "How is Goodwill Calculated?",
        desc: "The traditional way to calculate goodwill is Business Value less the value of the assets. For example, a business value came in at $1,000,000 and the total value of the business assets are $300,000. Therefore the value of Goodwill is $700,000.",
      },
    ],

    imgUrl: "/images/services/asset_valuation_main.jpeg",
    href: "/service/asset-valuation-details",
  },
  {
    id: 4,
    iconUrl: "/images/icons/service_icon_4.svg",
    title: "Asset Management",
    subTitle: "Comprehensive management services for properties and fleets.",
    subServices: [
      {
        id: 4.1,
        title: "Property Management",
        description:
          "Our property management services offer complete solutions for maintaining and managing residential and commercial properties. We handle everything from routine maintenance and repair to leasing, tenant relations, and financial management, ensuring your property is well cared for and profitable.",
        imgUrl: "/images/services/property_mgt.png",
      },
      {
        id: 4.2,
        title: "Fleet Management",
        description:
          "We provide expert fleet management services designed to optimize vehicle performance and cost-efficiency. Our services include fleet tracking, maintenance scheduling, fuel management, and driver safety programs, ensuring that your fleet operates smoothly and effectively.",
        imgUrl: "/images/services/fleet_mgt.png",
      },
    ],
    imgUrl: "/images/services/asset_mgt_main.jpeg",
    href: "/service/asset-management-details",
  },
  {
    id: 5,
    iconUrl: "/images/icons/service_icon_5.svg",
    title: "System Development",
    subTitle:
      "Customized digital solutions for domains, websites, applications, and ERP systems.",
    subServices: [
      {
        id: 5.1,
        title: "Domain and Hosting",
        description:
          "We offer comprehensive domain registration and reliable hosting services tailored to your business needs. Our solutions include domain name selection, secure hosting, and ongoing support to ensure your website remains accessible and performs optimally.",
        imgUrl: "/images/services/hosting.png",
      },
      {
        id: 5.2,
        title: "Website",
        description:
          "Our website development services focus on creating responsive, high-performance websites that meet your business objectives. We specialize in custom design, user experience optimization, and functionality to ensure your website stands out and engages your audience effectively.",
        imgUrl: "/images/services/website.png",
      },
      {
        id: 5.3,
        title: "Application",
        description:
          "We build custom applications tailored to your specific business requirements. Our development process includes user-centric design, robust functionality, and scalable architecture to deliver applications that enhance your business operations and user experience.",
        imgUrl: "/images/services/mobile_app.png",
      },
      {
        id: 5.4,
        title: "ERP",
        description:
          "Our ERP solutions streamline business operations and resource management. We provide customized ERP systems designed to integrate various business functions, including finance, inventory, and human resources, to improve efficiency and decision-making.",
        imgUrl: "/images/services/erp_tool.png",
      },
    ],
    faqData: [
      {
        title: "How long does software development take?",
        desc: "We cannot possibly answer this question precisely because there are projects finished in 2 to 3 weeks, but there are also tasks that take several years to complete.To provide you with a precise answer, we have to evaluate the project complexity and the work scope. Knowing this information, we can provide you with the estimated time frame.",
      },
      {
        title : "Do you provide product support services after the app development is complete?",
        desc : "Yes, we are always glad to provide technical support and service the apps we have developed. We can also further develop your software product if you need to implement new features or integrate third-party services."
      },
      {
        title : "Will you be able to increase the number of people in your team if necessary?",
        desc : "If you wish to partly reduce the project work intensity or, on the contrary, to increase it at times, we are always ready to adapt to your needs. When necessary, we will easily involve more team members in your project to meet your expectations."
      }, 

    ],
    imgUrl: "/images/services/system_dvt_main.jpeg",
    href: "/service/system-development-details",
  },
  {
    id: 6,
    iconUrl: "/images/icons/service_icon_6.svg",
    title: "Business & Investment Consultancy",
    subTitle:
      "Expert guidance in business development, investment strategies, and market research.",
    subServices: [
      {
        id: 6.1,
        title: "Research",
        description:
          "We provide in-depth research services to support business strategies and decision-making. Our research covers market trends, competitive analysis, and consumer behavior to offer valuable insights and inform your business planning.",
        imgUrl: "/images/services/research.png",
      },
      {
        id: 6.2,
        title: "Feasibility Study",
        description:
          "Our feasibility studies assess the viability of business ventures by evaluating market potential, financial projections, and operational requirements. We help you understand the risks and opportunities involved in your projects.",
        imgUrl: "/images/services/feasibilty.png",
      },
      {
        id: 6.3,
        title: "Business Plan",
        description:
          "We create detailed business plans that outline your company’s goals, strategies, and financial projections. Our plans provide a roadmap for growth and development, helping you secure funding and guide your business operations.",
        imgUrl: "/images/services/business_plan.png",
      },
      {
        id: 6.4,
        title: "Marketing Research",
        description:
          "Our marketing research services offer insights into market trends and customer behavior. We analyze data to help you refine your marketing strategies, enhance your brand positioning, and better target your audience.",
        imgUrl: "/images/services/market_research.png",
      },
      {
        id: 6.5,
        title: "Project Management",
        description:
          "We provide project management services to ensure successful execution of business initiatives. Our approach includes planning, execution, and monitoring to deliver projects on time and within budget while meeting your objectives.",
        imgUrl: "/images/services/project_mgt.png",
      },
    ],
    faqData : [
      {
        title :"What does Gadal Consulting do?",
        desc : "<ul><li>Business plan development</li><li>Financial forecast development</li><li>Pitch deck (investor presentation) preparation</li><li>Strategy development</li><li>Market research and analysis</li><li>Competitor research and analysis</li><li>Financial analysis</li><li>General coaching and advisory services</li><li>Capital formation strategy</li><li>Please note that we cannot help our clients raise capital.</li></ul>"
      },
      {
        title : "Can you help us raise money?",
        desc : "We can sometimes make informal introductions to potential investors or other intermediaries. In general, only brokers licensed with the Financial Industry Regulatory Authority (FINRA) can legally arrange financing (see Beware of Finders in our Blog). Please seek legal counsel and make sure that any “finder” you talk to is properly licensed with FINRA before you hire them. Failing to do so may expose you and your company to significant liability."
      },
      {
        title : "Do you ever invest in your clients?",
        desc : "No, we are management consultants, not investors.",
      },
      {
        title : "Can you work any faster?",
        desc : "Yes, but we may need to charge a “rush” fee. We typically work on multiple projects at any point in time, and rush projects can strain our schedules or inconvenience other clients."
      },
      {
        title : "Will you agree with everything I say?",
        desc : "Only if you’re always right. One of our jobs is to help you avoid mistakes. We’ll challenge your ideas and assumptions so that you’ll be ready for the tough questions when you meet with investors."
      }
    ],
    imgUrl: "/images/services/consultancy_main.jpeg",
    href: "/service/consultancy-details",
  },

  {
    id: 7,
    iconUrl: "/images/icons/export.svg",
    title: "Import Export Facilitation",
    subTitle: "Connecting and facilitating international and domestic trade.",
    subServices: [
      {
        id: 7.1,
        title: "International Trade Facilitation",
        description:
          "We connect businesses with reliable partners in international markets, streamlining cross-border transactions and ensuring compliance with trade regulations.",
        imgUrl: "/images/services/import_export_sub_2.jpg",
        question: "Interested in international trade facilitation?",
        formUrl: "https://forms.gle/dEnVs3NB9uTUvgpJA",
      },
      {
        id: 7.2,
        title: "Domestic Trade Facilitation",
        description:
          "We facilitate connections within the domestic market, helping businesses find local suppliers and customers to enhance their trading capabilities.",
        imgUrl: "/images/services/import_export_sub_1.jpg",
        question: "Looking for assistance in domestic trade?",
        formUrl: "https://forms.gle/FweMXPZk26aBiRaM8",
      },
    ],
    imgUrl: "/images/services/import_export_main.jpg",
    href: "/service/import-export-details",
  },
];

export default servicesData;
