import React, { useEffect, useState } from "react";
import Portfolio from "../Portfolio";
import { useDispatch, useSelector } from "react-redux";
import { getPortifolios } from "../../store/slices/portifolio-slice";

const categoryMenu = [
  { title: "Technology", category: "Technology" },
  // { title: "Business", category: "Business" },
  // { title: "Design", category: "Design" },
  // { title: "Marketing", category: "Marketing" },
  // { title: "Finance", category: "Finance" },
];

export default function Gallery() {
  const [activeCategory, setActiveCategory] = useState("all");
  // const [itemShow, setItemShow] = useState(6);

  const [pageSize, setPageSize] = useState(6);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { portifolios } = useSelector((state) => state.portifolio);

  useEffect(() => {
    setPageSize(5);
    setLoading(true);
    const category = activeCategory === "all" ? null : activeCategory;
    dispatch(getPortifolios({ page: 1, pageSize: 5, category })).then(() => {
      setLoading(false);
    });
  }, [activeCategory, dispatch]);

  const handleLoadMore = () => {
    const category = activeCategory === "all" ? null : activeCategory;
    setLoading(true);
    dispatch(getPortifolios({ page: 1, pageSize: pageSize, category })).then(
      () => {
        setPageSize(pageSize + 5);
        setLoading(false);
      }
    );
  };

  return (
    <div>
      <ul className="cs_isotop_filter cs_style_1 d-flex justify-content-center flex-wrap m-0 cs_pl_0 cs_pb_30">
        <li className={activeCategory === "all" ? "active" : ""}>
          <span onClick={() => setActiveCategory("all")}>All</span>
        </li>
        {categoryMenu.map((item, index) => (
          <li
            className={activeCategory === item.category ? "active" : ""}
            key={index}
          >
            <span
              className="text-capitalize"
              onClick={() => setActiveCategory(item.category)}
            >
              {item.title}
            </span>
          </li>
        ))}
      </ul>
      <div className="row">
        {!loading &&
          portifolios?.length > 0 &&
          portifolios.slice(0, 6).map((item, index) => (
            <div
              className={`col-lg-4 col-md-6 ${
                activeCategory === "all" || activeCategory === item.category
                  ? ""
                  : "d-none"
              }`}
              key={index}
            >
              <Portfolio {...item} />
              <div className="cs_mb_25"></div>
            </div>
          ))}
        {!loading && portifolios.length === 0 && (
          <p className="d-flex justify-content-center cs_mt_30">No items</p>
        )}
      </div>
      {loading ? (
        <div className="d-flex justify-content-center cs_mt_30">
          <span>Loading...</span>
        </div>
      ) : portifolios.length > 0 && portifolios.length % 5 === 0 ? (
        <div className="d-flex justify-content-center cs_mt_30">
          <span
            className="cs_btn cs_style_1 cs_fs_16 cs_rounded_5 cs_pl_30 cs_pr_30 cs_pt_10 cs_pb_10 overflow-hidden"
            onClick={handleLoadMore}
          >
            <span>Load More</span>
          </span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
