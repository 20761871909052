import React, { useEffect } from "react";
import Breadcrumb from "../Breadcrumb";
import SocialBtns from "../SocialBtns";
import { Icon } from "@iconify/react";
import { pageTitle } from "../../helpers/PageTitle";
import { selectPortifolioById } from "../../store/slices/portifolio-slice";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function PortfolioDetailsPage() {
  pageTitle("Portfolio Details");

  const { portfolioId } = useParams();
  const portifolio = useSelector((state) =>
    selectPortifolioById(state, portfolioId)
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Breadcrumb title="Project Details" bgUrl="/images/page_header_1.jpeg" />
      <section className="cs_pt_130 cs_pt_lg_80 cs_pb_93 cs_pb_lg_80">
        <div className="container">
          <div>
            <div className="cs_project_details-head d-flex align-items-center justify-content-between flex-wrap cs_mb_40 cs_row_gap_15 cs_column_gap_15">
              <h2 className="m-0 cs_fs_48 cs_fs_lg_36">{portifolio?.title} </h2>
              <div className="cs_published_date d-inline-flex cs_rounded_5 bg-accent text-white cs_pt_5 cs_pb_5 cs_pl_15 cs_pr_15 cs_pt_lg_3 cs_pb_lg_3">
                {portifolio?.createdAt &&
                  new Date(portifolio.createdAt).toLocaleDateString()}
              </div>
            </div>
            <img
              src={portifolio?.imgUrl}
              alt="Thumb"
              className="cs_mb_60 cs_mb_lg_40"
            />
            <div className="row">
              <div className="col-lg-8">
                <div className="cs_project_details cs_pb_40">
                  <p className="m-0">{portifolio?.description}</p> <br />
                  <br />
                  <h4 className="cs_fs_18 cs_mb_12">Project Challenges : </h4>
                  <ul className="cs_mb_30 cs_list cs_style_1">
                    {portifolio?.projectChallenges &&
                      portifolio?.projectChallenges
                        .split(",")
                        .map((challenge, index) => (
                          <li index={index}>
                            <Icon icon="fa-solid:check" />
                            {challenge}
                          </li>
                        ))}
                  </ul>
                </div>
              </div>
              <div className="col-lg-4">
                <ul className="cs_project_details-info bg-white cs_rounded_20 m-0 cs_pl_50 cs_pt_50 cs_pr_50 cs_pb_50 cs_pl_lg_40 cs_pt_lg_40 cs_pr_lg_40 cs_pb_lg_40">
                  <li>
                    <h3 className="cs_fs_18 cs_mb_10">
                      Client: {portifolio?.client}
                    </h3>
                  </li>
                  <li>
                    <h3 className="cs_fs_18 cs_mb_10">
                      Location : {portifolio.location}
                    </h3>
                  </li>
                  <li>
                    <SocialBtns variant="cs_social_btns d-flex flex-wrap cs_column_gap_15 cs_row_gap_15 cs_transition_5" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
