import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";

import { ModalHeader } from "../modal/ModalHeader";
import { ModalBody } from "../modal/ModalBody";
import { ModalFooter } from "../modal/ModalFooter";
import {
  createPartner,
  selectPartnerById,
  updatePartner,
} from "../../../../store/slices/partner-slice";
import ImageUploader from "../blog/ImageUploader";

const defaultData = {
  name: "",
  imageUrl: "",
};

const AddPartnerModal = ({ handleModalClose, id, isOpen }) => {
  const dispatch = useDispatch();

  const selectedPartner = useSelector((state) =>
    selectPartnerById(state, id)
  );

  const [formData, setFormData] = useState(defaultData);

  useEffect(() => {
    if (id && selectedPartner) {
      setFormData({
        name: selectedPartner.name,
        imageUrl: selectedPartner.imageUrl,
      });
    }
  }, [id, selectedPartner]);

  const handleSave = async (event) => {
    event.preventDefault();

    if (id) {
      const updatedData = { ...formData };
      dispatch(updatePartner({ id, updatedData }));
    } else {
      dispatch(createPartner(formData));
    }
    handleModalClose();
    setFormData(defaultData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageUploadSuccess = (url) => {
    setFormData((prevState) => ({
      ...prevState,
      imageUrl: url,
    }));
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleModalClose}
      contentLabel="Add/Edit Partner"
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
        },
        overlay: {
          background: "rgba(0,0,0,0.10)",
        },
      }}
    >
      <ModalHeader
        title={id ? "Edit Partner" : "Add Partner"}
        handleModalClose={handleModalClose}
        borderBottom={true}
      />
      <ModalBody>
        <form className="flex flex-col max-h-[400px]" onSubmit={handleSave}>
          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              Name
            </div>

            <div className="w-9/12">
              <input
                id="name"
                name="name"
                className="form-control cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full placeholder-gray-400"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter partner name"
              />
            </div>
          </div>

          <div className="flex  w-full pb-4 gap-2 items-center">
            <div className="mr-16 h-9 flex items-center text-sm font-semibold">
              Image
            </div>
            <div>
              <ImageUploader onUploadSuccess={handleImageUploadSuccess} />
            </div>
          </div>

          <ModalFooter>
            <div className="flex justify-end gap-2">
              <button
                className="flex items-center shadow-md px-3 py-1 rounded-md border hover:text-accent hover:bg-gray-300 focus:outline-none"
                onClick={handleModalClose}
              >
                <span>Close</span>
              </button>

              <button
                type="submit"
                disabled={!id && !formData.imageUrl}
                className="flex items-center disabled:cursor-not-allowed shadow-md px-3 py-1 rounded-md border hover:bg-gray-300 hover:text-accent  bg-accent text-white"
              >
                Save
              </button>
            </div>
          </ModalFooter>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default AddPartnerModal;
