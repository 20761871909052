import CategoryWidget from "../Widget/CategoryWidget";
import PostStyle5 from "../Post/PostStyle5";
import { useEffect } from "react";
import { pageTitle } from "../../helpers/PageTitle";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectBlogById } from "../../store/slices/blog-slice";
import Breadcrumb from "../Breadcrumb";
// import CommentList from "../Widget/CommentList";
// import FormStyle4 from "../Form/FormStyle4";
import SocialBtnsStyle2 from "../SocialBtns/SocialBtnsStyle2";

export default function BlogDetailsPage() {
  pageTitle("Blog Details");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { blogId } = useParams();
  const selectedBlog = useSelector((state) => selectBlogById(state, blogId));
  console.log(selectedBlog);
  
  return (
    <>
      <Breadcrumb />
      <section className="cs_pt_25 cs_pt_lg_10 cs_pb_110 cs_pb_lg_50 mt-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <PostStyle5
                imgUrl={selectedBlog?.imageUrl}
                title={selectedBlog?.title}
                subTitle={selectedBlog?.description}
              />

              <div className="d-flex justify-content-between align-items-center bg-gray cs_pt_25 cs_pb_25 cs_pl_40 cs_pl_lg_25 cs_pr_40 cs_pr_lg_25 flex-wrap cs_row_gap_15 cs_column_gap_15 cs_mb_40">
                <div className="cs_post_details-meta-tag">
                  <h4 className="cs_sidebar_widget_title">Tags</h4>
                  <div className="tagcloud">
                    {selectedBlog?.tags?.split(",")?.map((item, index) => (
                      <div  className="tag-cloud-link" key={index}>
                        {item}
                      </div>
                    ))}
                  </div>
                </div>
                <SocialBtnsStyle2 />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="cs_sidebar">
                <CategoryWidget
                  data={selectedBlog?.categories.split(",")}
                  title="Category"
                />
              </div>
            </div>

            {/* <div className="col-lg-8">
              <h2 className="cs_fs_26 cs_mb_40">2 Comments</h2>
              <CommentList />
              <h2 className="cs_fs_26 cs_mb_40">Leave a Comment</h2>
              <FormStyle4 />
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}
