import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../helpers/api";

// Fetch all resources with pagination
export const getResources = createAsyncThunk(
  "resource/fetch",
  async ({ page, pageSize }, { rejectWithValue }) => {
    try {
      const response = await API.get(`/resources`, {
        params: { page, pageSize },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Create a new resource
export const createResource = createAsyncThunk(
  "resource/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(`/resources`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Update a resource
export const updateResource = createAsyncThunk(
  "resource/update",
  async ({ id, updatedData }, { rejectWithValue }) => {
    try {
      const response = await API.put(`/resources/${id}`, updatedData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Delete a resource
export const deleteResource = createAsyncThunk(
  "resource/delete",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await API.delete(`/resources/${id}`);
      return { ...response.data, id };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


const initialState = {
  resources: [],
  status: null,
  error: undefined,
  total: 0,
  page: 1,
  pageSize: 5,
};

const resourceSlice = createSlice({
  name: "resource",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      // Get Resources
      .addCase(getResources.pending, (state) => {
        state.error = undefined;
        state.status = "loading";
        state.resources = [];
      })
      .addCase(getResources.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.resources = action.payload.resources;
        state.total = action.payload.total;
      })
      .addCase(getResources.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      })

      // Create Resource
      .addCase(createResource.pending, (state) => {
        state.error = undefined;
        state.status = "loading";
      })
      .addCase(createResource.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.resources.unshift(action.payload.resource);
      })
      .addCase(createResource.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      })

      // Handle Update Resource
      .addCase(updateResource.fulfilled, (state, action) => {
        const index = state.resources.findIndex(
          (resource) => resource._id === action.payload.resource._id
        );
        if (index !== -1) {
          state.resources[index] = action.payload.resource;
        }
        state.status = "succeeded";
      })
      .addCase(updateResource.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateResource.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Handle Delete Resource
      .addCase(deleteResource.fulfilled, (state, action) => {
        state.resources = state.resources.filter(
          (resource) => resource._id !== action.payload.id
        );
        state.status = "succeeded";
      })
      .addCase(deleteResource.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteResource.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

// Selector to get a resource by ID
export const selectResourceById = (state, id) => {
  return state.resource?.resources?.find(
    (resource) => resource._id === id
  );
};

export default resourceSlice.reducer;
