import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../helpers/api";

// Fetch portfolios with pagination and optional category filter
export const getPortifolios = createAsyncThunk(
  "portifolio/fetch",
  async ({ page, pageSize, category }, { rejectWithValue }) => {
    try {
      const response = await API.get(`/portifolios`, {
        params: { page, pageSize, ...(category && { category }) },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Create a new portfolio
export const createPortifolio = createAsyncThunk(
  "portifolio/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(`/portifolios`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Update an existing portfolio
export const updatePortifolio = createAsyncThunk(
  "portifolio/update",
  async ({ id, updatedData }, { rejectWithValue }) => {
    try {
      const response = await API.put(`/portifolios/${id}`, updatedData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Delete a portfolio
export const deletePortifolio = createAsyncThunk(
  "portifolio/delete",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await API.delete(`/portifolios/${id}`);
      return { ...response.data, id };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


const initialState = {
  portifolios: [],
  status: null,
  error: undefined,
  total: 0,
  page: 1,
  pageSize: 5,
};

const portifolioSlice = createSlice({
  name: "portifolio",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      //  get Portifolios
      .addCase(getPortifolios.pending, (state) => {
        state.error = undefined;
        state.status = "loading";
        state.portifolios = [];
      })
      .addCase(getPortifolios.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.portifolios = action.payload.portifolios;
        state.total = action.payload.total;
      })

      .addCase(getPortifolios.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      })

      // create portifolio
      .addCase(createPortifolio.pending, (state) => {
        state.error = undefined;
        state.status = "loading";
      })
      .addCase(createPortifolio.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.portifolios.unshift(action.payload.portifolio);
      })
      .addCase(createPortifolio.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      })

      // Handle update portifolio
      .addCase(updatePortifolio.fulfilled, (state, action) => {
        const index = state.portifolios.findIndex(
          (portifolio) => portifolio._id === action.payload.portifolio._id
        );
        if (index !== -1) {
          state.portifolios[index] = action.payload.portifolio;
        }
        state.status = "succeeded";
      })
      .addCase(updatePortifolio.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updatePortifolio.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Handle delete portifolio
      .addCase(deletePortifolio.fulfilled, (state, action) => {
        state.portifolios = state.portifolios.filter(
          (portifolio) => portifolio._id !== action.payload.id
        );
        state.status = "succeeded";
      })
      .addCase(deletePortifolio.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deletePortifolio.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const selectPortifolioById = (state, id) => {
  return state.portifolio?.portifolios?.find(
    (portifolio) => portifolio._id === id
  );
};

export default portifolioSlice.reducer;
