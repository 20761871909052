import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../helpers/api";

// Fetch partners with pagination
export const getPartners = createAsyncThunk(
  "partner/fetch",
  async ({ page, pageSize }, { rejectWithValue }) => {
    try {
      const response = await API.get(`/partners`, {
        params: { page, pageSize },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Create a new partner
export const createPartner = createAsyncThunk(
  "partner/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(`/partners`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Update an existing partner
export const updatePartner = createAsyncThunk(
  "partner/update",
  async ({ id, updatedData }, { rejectWithValue }) => {
    try {
      const response = await API.put(`/partners/${id}`, updatedData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Delete a partner
export const deletePartner = createAsyncThunk(
  "partner/delete",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await API.delete(`/partners/${id}`);
      return { ...response.data, id };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  partners: [],
  status: null,
  error: undefined,
  total: 0,
  page: 1,
  pageSize: 5,
};

const partnerSlice = createSlice({
  name: "partner",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      // Get Partners
      .addCase(getPartners.pending, (state) => {
        state.error = undefined;
        state.status = "loading";
        state.partners = [];
      })
      .addCase(getPartners.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.partners = action.payload.partners;
        state.total = action.payload.total;
      })
      .addCase(getPartners.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      })

      // Create Partner
      .addCase(createPartner.pending, (state) => {
        state.error = undefined;
        state.status = "loading";
      })
      .addCase(createPartner.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.partners.unshift(action.payload.partner);
      })
      .addCase(createPartner.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      })

      // Update Partner
      .addCase(updatePartner.fulfilled, (state, action) => {
        const index = state.partners.findIndex(
          (partner) => partner._id === action.payload.partner._id
        );
        if (index !== -1) {
          state.partners[index] = action.payload.partner;
        }
        state.status = "succeeded";
      })
      .addCase(updatePartner.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updatePartner.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Delete Partner
      .addCase(deletePartner.fulfilled, (state, action) => {
        state.partners = state.partners.filter(
          (partner) => partner._id !== action.payload.id
        );
        state.status = "succeeded";
      })
      .addCase(deletePartner.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deletePartner.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const selectPartnerById = (state, id) => {
  return state.partner?.partners?.find((partner) => partner._id === id);
};

export default partnerSlice.reducer;
