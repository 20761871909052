import { useEffect } from 'react';
import Breadcrumb from '../Breadcrumb';
import Section from '../Section';
import ServiceSectionStyle4 from '../Section/ServiceSection/ServiceSectionStyle4';
import { pageTitle } from '../../helpers/PageTitle';
import servicesData from '../../data/services';



export default function ServicesPage() {
  pageTitle('Services');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Breadcrumb title="Our Services" bgUrl="/images/page_header_1.jpeg" />

      <Section pt="40"  ptLg="40" pb="115" pbLg="55">
        <ServiceSectionStyle4
          sectionTitleUp="Our Service List"
          sectionTitle="Gadal Technologies offers <br> the following services"
          data={servicesData}
        />
      </Section>

    </>
  );
}
