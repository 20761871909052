import React, { useEffect } from 'react';
import Team from '../../Team';
import SectionHeadingStyle2 from '../../SectionHeading/SectionHeadingStyle2';
import { useDispatch, useSelector } from 'react-redux';
import { getTeamMembers } from '../../../store/slices/teamMember-slice';

export default function TeamSection({ sectionTitle, sectionTitleUp }) {
  const { teamMembers } = useSelector((state) => state.teamMember);
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(getTeamMembers({ page: 1, pageSize: 500 }));
  }, [dispatch]);
  
  
  return (
    <div className="container">
      {sectionTitle && (
        <SectionHeadingStyle2
          sectionTitle={sectionTitle}
          sectionTitleUp={sectionTitleUp}
        />
      )}

      <div className="row">
        {teamMembers?.map((item, index) => (
          <div className="col-lg-3" key={index}>
            <Team
              key={index}
              item = {item}
            
            />
          </div>
        ))}
      </div>
    </div>
  );
}
