import React from "react";
import { Navigate } from "react-router-dom";

// Function to check if token exists and is not expired
const isAuthenticated = () => {
  const token = localStorage.getItem("token");

  if (!token) {
    return false; 
  }
  try {
    const payload = JSON.parse(atob(token.split(".")[1])); 
    const currentTime = Math.floor(Date.now() / 1000);     
    if (payload.exp && payload.exp < currentTime) {
      return false; 
    }

    return true; 
  } catch (error) {
    return false; 
  }
};

const ProtectedRoute = ({ children }) => {
  return isAuthenticated() ? children : <Navigate to="/admin/login" />;
};

export default ProtectedRoute;
