import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPartners } from "../../../store/slices/partner-slice";

export default function BrandSection() {
  const { partners } = useSelector((state) => state.partner);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPartners({ page: 1, pageSize: 500 }));
  }, [dispatch]);


  return (
    <div className="flex items-center justify-center flex-row">
      <div className="cs_moving_text my-6 cs_brands flex flex-row justify-content-xl-between align-items-center cs_column_gap_25 cs_row_gap_25  justify-content-center">
        {partners &&
          partners.map((item, index) => (
            <div
              className="cs_brand partners_card flex flex-row items-center justify-center gap-1"
              key={index}
            >
              <img
                src={item.imageUrl}
                alt="Brand"
                className="important-image-two"
              />
              <p className="font-bold whitespace-nowrap">{item.name}</p>
            </div>
          ))}
      </div>

      <div className="cs_moving_text my-6 cs_brands flex flex-row justify-content-xl-between align-items-center cs_column_gap_25 cs_row_gap_25  justify-content-center">
        {partners &&
          partners.map((item, index) => (
            <div
              className="cs_brand partners_card flex flex-row items-center justify-center gap-1 "
              key={index}
            >
              <img
                src={item.imageUrl}
                alt="Brand"
                className="important-image-two"
              />
              <p className="font-bold whitespace-nowrap">{item.name}</p>
            </div>
          ))}
      </div>
      <div className="cs_moving_text my-6 cs_brands flex flex-row justify-content-xl-between align-items-center cs_column_gap_25 cs_row_gap_25  justify-content-center">
        {partners &&
          partners.map((item, index) => (
            <div
              className="cs_brand partners_card flex flex-row items-center justify-center gap-1 "
              key={index}
            >
              <img
                src={item.imageUrl}
                alt="Brand"
                className="important-image-two"
              />
              <p className="font-bold whitespace-nowrap">{item.name}</p>
            </div>
          ))}
      </div>
    </div>
  );
}
