import { createListenerMiddleware } from "@reduxjs/toolkit";
import { errorResponseNotificationListener } from "./errorListener";
import { successResponseNotificationListener } from "./successListener";

export const listenerMiddleware = createListenerMiddleware();

export const startAppListening = listenerMiddleware.startListening;

errorResponseNotificationListener(startAppListening);
successResponseNotificationListener(startAppListening);
