import React, { useState } from "react";
import axios from "axios";

export default function FormStyle5() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    phoneNumber: "",
  });

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSuccess(false);

    try {
      console.log(process.env.REACT_APP_BASE_URL);

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/contact`,
        formData
      );

      if (response.status === 200) {
        setSuccess(true);
        setFormData({
          name: "",
          email: "",
          message: "",
          phoneNumber: "",
        });
      } else {
        setError("Failed to send message. Please try again.");
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        className="form-control cs_fs_14 cs_rounded_5 border-0 cs_mb_10 bg-white"
        type="text"
        placeholder="Your Name"
        name="name"
        value={formData.name}
        onChange={handleInputChange}
        required
      />

      <input
        className="form-control cs_fs_14 cs_rounded_5 border-0 cs_mb_10 bg-white"
        type="email"
        placeholder="Email Address"
        name="email"
        value={formData.email}
        onChange={handleInputChange}
        required
      />

      <input
        className="form-control cs_fs_14 cs_rounded_5 border-0 cs_mb_10 bg-white"
        type="text"
        placeholder="Phone Number"
        name="phoneNumber"
        value={formData.phoneNumber}
        onChange={handleInputChange}
        required
      />
      <textarea
        className="form-control cs_fs_14 cs_rounded_5 border-0 cs_mb_10 bg-white"
        placeholder="Message here ..."
        name="message"
        value={formData.message}
        onChange={handleInputChange}
        cols={5}
        rows={2}
        required
      />
      <button
        disabled={loading}
        type="submit"
        className="cs_btn cs_style_1 cs_fs_16 cs_rounded_5 cs_pl_30 cs_pr_30 cs_pt_10 cs_pb_10 overflow-hidden w-100"
      >
        {loading ? <span>Sending...</span> : <span>Send A Message</span>}
      </button>

      {error && (
        <div className="col-lg-12">
          <p className="text-danger">{error}</p>
        </div>
      )}

      {success && (
        <div className="col-lg-12">
          <p className="text-success">Message sent successfully!</p>
        </div>
      )}
    </form>
  );
}
