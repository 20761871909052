import SectionHeadingStyle3 from "../../SectionHeading/SectionHeadingStyle3";
import VideoBlock from "../../VideoBlock";
import Button from "../../Button";

export default function AboutUsSection({
  thumbUrl,
  experienceBgUrl,
  experienceIconUrl,
  experienceNumber,
  experienceTitle,
  sectionTitleUp,
  sectionTitle,
  sectionSubTitle,
  grothTitle,
  grothPercentage,
  featureList,
  btnText,
  btnUrl,
  videoBtnText,
  videoBtnUrl,
  brandText,
  ceoText
}) {
  return (
    <>
      <div className="container ">
        <div className="row">
          <div className="col-lg-6 cs_mb_lg_55">
            <div className="cs_experience cs_style_1 position-relative">
              <div className="cs_experience_thumb">
                <img
                  src={thumbUrl}
                  alt="Thumb"
                  className="position-relative cs_zindex_3 cs_rounded_15"
                />
                <div className="cs_experience_shape">
                  <img
                    src="/images/experience_shape_1.png"
                    alt="Shape"
                    className="moving_x"
                  />
                </div>
              </div>
              <div
                className="cs_experience_box background-filled text-center bg-white cs_rounded_10 position-absolute bottom-0 end-0 cs_zindex_3 d-flex flex-column justify-content-center align-items-center"
                style={{
                  backgroundImage: `url(${experienceBgUrl})`,
                }}
              >
                <img src={experienceIconUrl} alt="Icon" className="cs_mb_5" />
                <h3 className="text-white cs_fs_60 cs_fs_lg_46 fw-bold lh_1 mb-0 d-flex justify-content-between">
                  <span className="odometer">{experienceNumber}</span>
                  <span className="fw-light">+</span>
                </h3>
                <h2 className="cs_fs_18 fw-normal text-white m-0">
                  {experienceTitle}
                </h2>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="cs_about cs_style_1">
              <SectionHeadingStyle3
                sectionTitleUp={sectionTitleUp}
                sectionTitle={sectionTitle}
                sectionSubTitle={sectionSubTitle}
                className={"cs_mb_15"}
              />
              <div className="cs_progressbar cs_style_1 cs_mb_20">
                <div>
                  <div className="cs_progressbar_heading d-flex justify-content-between align-items-center cs_mb_8">
                    <h3 className="fw-medium m-0 cs_fs_18">
                      About the CEO and Founder
                    </h3>
                  </div>
                  <p className="text-justify px-2">
                    {ceoText}
                  </p>
                </div>

                <div>
                  <div className="cs_progressbar_heading d-flex justify-content-between align-items-center cs_mb_8">
                    <h3 className="fw-medium m-0 cs_fs_18 mt-2">The Brand</h3>
                  </div>
                  <p className="text-justify px-2">
                    {brandText}
                  </p>
                </div>
              </div>

              <div className="d-flex align-items-center cs_row_gap_20 cs_column_gap_30 cs_column_gap_lg_20 flex-wrap">
                <Button btnText={btnText} btnUrl={btnUrl} />
                <VideoBlock
                  videoSrc={videoBtnUrl}
                  videoBtnText={videoBtnText}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
