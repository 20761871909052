import React, { useEffect } from "react";
import Breadcrumb from "../Breadcrumb";
import Section from "../Section";
import AboutUsSection from "../Section/AboutSection";
import TeamSection from "../Section/TeamSection";
import TestimonialSection from "../Section/TestimonialSection";
import { pageTitle } from "../../helpers/PageTitle";
import MissionVisionSection from "../Section/AboutSection/MissionVisionSection";


export default function AboutPage() {
  pageTitle("About Us");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Breadcrumb title="About Us" bgUrl="/images/page_header_1.jpeg" />

      <Section
        pt="40"
        ptLg="75"
        pb="80"
        pbLg="20"
        className="position-relative "
      >
        <AboutUsSection
          thumbUrl="/images/work_experiance_2.jpg"
          experienceBgUrl="/images/work_experiance_4.jpeg"
          experienceIconUrl="/images/experience_icon.svg"
          experienceNumber="4"
          experienceTitle="Work Experience"
          sectionTitleUp="About Us"
          sectionTitle='Get Ready for Success with <span className="text-accent">Gadal</span>'
          sectionSubTitle="Gadal Technology PLC is a privately-owned company focused on simplifying daily activities through online marketing. We are legally engaged in e-commerce, construction material supply, and the sale and rental of heavy machinery and vehicles. Additionally, we offer buildings, warehouses, villas, hotel rooms, and light vehicles for sale or rent nationwide. For over eight years, we've supported the construction sector, enhancing the capital and technical capacities of the private sector. To expand our services, we've developed gadalmarket.com, a trading platform that complements our machinery rental services and facilitates vehicle and fixed asset sales and rentals.<br> "
          grothTitle="E-commerce and Construction Services"
          grothPercentage="90"
          ceoText={'Samson Belda CEO and founder of GADAL TECHNOLOGIES has achieved his Bachelor degree from Jimma University by Marketing and sales management on 2009. After a while on 2012 he becomes self employed and started his own business serving people as a commision agent that leads to establishing Gadal Technologies working on system development and tech based business on 2020 as a legal private limited company in Addis Ababa, Ethiopia.'}
          brandText={"The name 'Gadal' is derived from Hebrew, meaning 'to grow, promote, and do great things.' Gadal Technologies has become a leading tech company in Ethiopia, leveraging Samson Belda's experience and goodwill to build long-term partnerships with clients."}
          btnText="Get A Quote"
          btnUrl="/contact"
          videoBtnText="Watch the video"
          videoBtnUrl="https://www.youtube.com/embed/NfyDbDz95s4"
        />
        <MissionVisionSection/>
      </Section>

      

      <Section >
        <TeamSection
          sectionTitle="Meet the professional team <br/>behind the success"
          sectionTitleUp="Meet Our Team Member"
        />
      </Section>

      <Section
        pt="110"
        ptLg="55"
        pb="140"
        pbLg="80"
        style={{ backgroundImage: `url(${"/images/testimonial_bg.jpeg"})` }}
      >
        <TestimonialSection
          sectionTitleUp="Testimonial"
          sectionTitle="What They’re Saying?"
          sectionSubTitle="Discover how our comprehensive services in e-commerce, construction materials supply, vehicle sales and rentals, heavy machinery, and real estate have made a difference for our clients."
        />
      </Section>
    </>
  );
}
