import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import PortifolioTable from "../components/portifolio/PortifolioTable";
import AddPortifolioModal from "../components/portifolio/AddPortifolioModal";
import { getPortifolios } from "../../../store/slices/portifolio-slice";
import { pageTitle } from "../../../helpers/PageTitle";

export default function AdminPortifolioPage() {
  pageTitle("Admin Portifolio");
  const location = useLocation();
  const modalOpen = location?.state?.modalOpen;
  const [isModalOpen, setIsModalOpen] = useState(modalOpen ? modalOpen : false);
  const dispatch = useDispatch();

  const { portifolios } = useSelector((state) => state.portifolio);

  useEffect(() => {
    dispatch(getPortifolios({ page: 1, pageSize: 5 }));
  }, [dispatch]);

  return (
    <div className="p-4">
      <div className="flex flex-row justify-between items-center ">
        <h2 className="te text-lg font-semibold">Portifolios</h2>
        <Link
          onClick={() => setIsModalOpen(true)}
          className="border  flex items-center justify-center gap-2 hover:text-accent  rounded-2xl px-4 py-2 shadow-md  transition duration-300"
        >
          <FaPlus />
          <span className="">Create portifolio</span>
        </Link>
      </div>

      <PortifolioTable portifolios={portifolios} />

      <AddPortifolioModal
        isOpen={isModalOpen}
        handleModalClose={() => setIsModalOpen(false)}
      />
    </div>
  );
}
