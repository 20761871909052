import React, { useState } from "react";
import axios from "axios";

const ImageUploader = ({ onUploadSuccess }) => {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const handleImageUpload = async (event) => {
    setError("");
    const file = event.target.files[0];
    if (!file) return;

    setUploading(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append(
      "upload_preset",
      process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESENT
    );

    try {
      console.log(process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESENT, "temp");

      const response = await axios.post(
        `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload`,
        formData
      );
      const imageUrl = response.data.secure_url;

      console.log(process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESENT, "temp");

      setImageUrl(imageUrl);
      onUploadSuccess(imageUrl);
    } catch (error) {
      setError("Error uploading image");
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="image-uploader">
      <input type="file" accept="image/*" onChange={handleImageUpload} />
      {uploading && <p>Uploading...</p>}
      {imageUrl && <p className=" text-green-500">Upload sucess</p>}
      {error && <p className="text-red-500">{error}</p>}
    </div>
  );
};

export default ImageUploader;
