import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { getEvents } from "../../../../store/slices/event-slice";
import AddEventModal from "./AddEventModal";
import DeleteEventModal from "./DeleteEventModal";
import { formatDate } from "../../../../helpers/dateUtil";

const EventTable = ({ events }) => {
  const { status, total, pageSize } = useSelector((state) => state.event); // Update state slice
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedEventId, setSelectedEvent] = useState();

  const handleEdit = (id) => {
    setSelectedEvent(id);
    setEditModalOpen(true);
  };

  const handleDelete = (id) => {
    setSelectedEvent(id);
    setDeleteModalOpen(true);
  };

  const dispatch = useDispatch();

  const handlePaginationModelChange = (pagination) => {
    dispatch(
      getEvents({
        page: pagination.page + 1,
        pageSize: pagination.pageSize,
      })
    );
  };

  const columns = [
    { field: "_id", headerName: "ID", flex: 0.5 },
    {
      field: "startDate",
      headerName: "Start Date",
      flex: 1.5,
      renderCell: (params) => {
        const formattedDate = formatDate(params.value);
        return <div>{formattedDate}</div>;
      },
    },
    {
      field: "endDate",
      headerName: "End Date",
      flex: 1.5,
      renderCell: (params) => {
        const formattedDate = formatDate(params.value);
        return <div>{formattedDate}</div>;
      },
    },
    { field: "title", headerName: "Title", flex: 1 },
    { field: "description", headerName: "Description", flex: 1 },
    { field: "imgUrl", headerName: "Image Url ", flex: 0.6 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <div className="flex flex-row gap-3 mt-2 items-center justify-center">
          <button
            className="text-blue-500 text-xl"
            onClick={() => handleEdit(params.row._id)}
          >
            <FaEdit />
          </button>
          <button
            className="text-red-500 text-xl"
            onClick={() => handleDelete(params.row._id)}
          >
            <FaTrashAlt />
          </button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <DataGrid
        sx={{ m: 2, minHeight: '400px', }}
        style={{ padding: "12px" }}
        getRowId={(row) => row._id}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 5, page: 0 },
          },
        }}
        columns={columns}
        rows={events} // Update to use events
        rowCount={total}
        loading={status === "loading"}
        pageSize={pageSize}
        pagination
        paginationMode="server"
        pageSizeOptions={[5, 10, 15]}
        onPaginationModelChange={handlePaginationModelChange}
      />

      <AddEventModal
        isOpen={isEditModalOpen}
        handleModalClose={() => setEditModalOpen(false)}
        id={selectedEventId}
      />

      <DeleteEventModal
        handleModalClose={() => setDeleteModalOpen(false)}
        isOpen={deleteModalOpen}
        eventId={selectedEventId} // Update to eventId
      />
    </div>
  );
};

export default EventTable;
