import React, { useState } from "react";
import axios from "axios";

const MultipleImageUploader = ({ onUploadSuccess }) => {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState("");
  const [uploadedImages, setUploadedImages] = useState([]);
  const [uploadProgress, setUploadProgress] = useState([]);

  const handleImageUpload = async (event) => {
    setError("");
    const files = event.target.files;
    if (!files.length) return;

    setUploading(true);
    let uploadedUrls = [];
    let progress = Array.from({ length: files.length }, () => 0);
    setUploadProgress(progress);

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      const formData = new FormData();
      formData.append("file", file);
      formData.append(
        "upload_preset",
        process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESENT
      );

      try {
        const response = await axios.post(
          `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload`,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadProgress((prev) =>
                prev.map((p, index) => (index === i ? percentCompleted : p))
              );
            },
          }
        );
        const imageUrl = response.data.secure_url;
        uploadedUrls.push(imageUrl);
        setUploadedImages((prev) => [...prev, imageUrl]);

      } catch (error) {
        setError("Error uploading image");
      }
    }

    onUploadSuccess(uploadedUrls);
    setUploading(false);
  };

  return (
    <div className="multiple-image-uploader">
      <input
        type="file"
        accept="image/*"
        multiple
        onChange={handleImageUpload}
      />
      {uploading && (
        <div>
          <p>Uploading...</p>
          {uploadProgress.map((progress, index) => (
            <p key={index}>Image {index + 1}: {progress}%</p>
          ))}
        </div>
      )}
      {uploadedImages.length > 0 && (
        <div>
          <p className="text-green-500">Upload Success!</p>
          <ul>
            {uploadedImages.map((url, index) => (
              <li key={index}>
                <img src={url} alt={`Uploaded ${index}`} width="100" />
              </li>
            ))}
          </ul>
        </div>
      )}
      {error && <p className="text-red-500">{error}</p>}
    </div>
  );
};

export default MultipleImageUploader;
