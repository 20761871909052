import React from "react";
import DropDown from "./DropDown";
import { Link } from "react-router-dom";

export default function Nav({ setMobileToggle }) {
  return (
    <ul className="cs_nav_list fw-medium text-uppercase">
      <li className="asd">
        <Link to="/">Home</Link>
      </li>
      <li>
        <Link to="/about" onClick={() => setMobileToggle(false)}>
          About
        </Link>
      </li>

      <li>
        <Link to="/service" onClick={() => setMobileToggle(false)}>
          Services
        </Link>
      </li>

      <li className="menu-item-has-children">
        <Link onClick={() => setMobileToggle(false)}>Profile</Link>
        <DropDown>
          <ul>
            <li>
              <Link to="/portfolio" onClick={() => setMobileToggle(false)}>
                Portfolio
              </Link>
            </li>
            <li>
              <Link to={"/resource"} onClick={() => setMobileToggle(false)}>
                Resources
              </Link>
            </li>
          </ul>
        </DropDown>
      </li>

      <li className="menu-item-has-children">
        <Link onClick={() => setMobileToggle(false)}>Announcements</Link>
        <DropDown>
          <ul>
            <li>
              <Link to="/blog" onClick={() => setMobileToggle(false)}>
                Our Blog
              </Link>
            </li>
            <li>
              <Link to={"/job"} onClick={() => setMobileToggle(false)}>
                Job vacancy
              </Link>
            </li>
            <li>
              <Link to={'/opportunities'} onClick={() => setMobileToggle(false)}>
                Investment opportunities
              </Link>
            </li>
            <li>
              <Link to={'/e-magazine'} onClick={() => setMobileToggle(false)}>
                E-Magazine
              </Link>
            </li>
            <li>
              <Link to={"/event"} onClick={() => setMobileToggle(false)}>
                Events
              </Link>
            </li>
          </ul>
        </DropDown>
      </li>
      <li>
        <Link to="/contact" onClick={() => setMobileToggle(false)}>
          Contact
        </Link>
      </li>
    </ul>
  );
}
