import { Icon } from '@iconify/react'
import React from 'react'
import { Link } from 'react-router-dom'

export default function SocialBtnsStyle2() {
  return (
    <div className="cs_social_btns cs_color_2 d-flex flex-wrap cs_column_gap_15 cs_row_gap_15 cs_transition_5">
     <Link
        to="https://www.facebook.com/gadalmarket?mibextid=ZbWKwL"
        className="d-flex align-items-center justify-content-center cs_height_35 cs_width_35 rounded-circle"
      >
        <Icon icon="fa6-brands:facebook-f" />
      </Link>
      <Link
        to="https://x.com/Samson_Belda?t=aMx0NIngzWYY_41aHTb_3Q&s=09"
        className="d-flex align-items-center justify-content-center cs_height_35 cs_width_35  rounded-circle"
      >
        <Icon icon="fa6-brands:twitter" />
      </Link>
      <Link
        to="https://www.linkedin.com/company/gadal-market/"
        className="d-flex align-items-center justify-content-center cs_height_35 cs_width_35  rounded-circle"
      >
        <Icon icon="fa6-brands:linkedin-in" />
      </Link>
     
      <Link
        to="https://www.instagram.com/gadal_market?igsh=MWdyNzU5aGFibmhvYw=="
        className="d-flex align-items-center justify-content-center cs_height_35 cs_width_35  rounded-circle"
      >
        <Icon icon="fa6-brands:instagram" />
      </Link>
      <Link
        to="https://t.me/gadalmarket"
        className="d-flex align-items-center justify-content-center cs_height_35 cs_width_35  rounded-circle"
      >
        <Icon icon="fa6-brands:telegram" />
      </Link>
      <Link
        to="https://youtube.com/@gadaltech?si"
        className="d-flex align-items-center justify-content-center cs_height_35 cs_width_35 rounded-circle"
      >
        <Icon icon="fa6-brands:youtube" />
      </Link>
    </div>
  )
}
