import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getMagazines } from "../../../store/slices/e-magazine-slice";
import { pageTitle } from "../../../helpers/PageTitle";
import AddEMagazineModalContent from "../components/eMagazine/AddEmagazineModal";
import MagazineTable from "../components/eMagazine/EmagazineTable";

export default function AdminMagazinePage() {
  pageTitle("Admin eMagazine");
  const location = useLocation();
  const modalOpen = location?.state?.modalOpen;
  const [isModalOpen, setIsModalOpen] = useState(modalOpen ? modalOpen : false);
  const dispatch = useDispatch();

  const { magazines } = useSelector((state) => state.magazine);

  useEffect(() => {
    dispatch(getMagazines({ page: 1, pageSize: 5 }));
  }, [dispatch]);

  return (
    <div className="p-4">
      <div className="flex flex-row justify-between items-center">
        <h2 className="text-lg font-semibold">eMagazines</h2>
        <Link
          onClick={() => setIsModalOpen(true)}
          className="border flex items-center justify-center gap-2 hover:text-accent rounded-2xl px-4 py-2 shadow-md transition duration-300"
        >
          <FaPlus />
          <span>Create eMagazine</span>
        </Link>
      </div>

      <MagazineTable magazines={magazines} />

      <AddEMagazineModalContent
        isOpen={isModalOpen}
        handleModalClose={() => setIsModalOpen(false)}
      />
    </div> 
  );
}
