import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../helpers/api";

// Fetch all events
export const getEvents = createAsyncThunk(
  "event/fetch",
  async ({ page, pageSize }, { rejectWithValue }) => {
    try {
      const response = await API.get(`/events`, {
        params: { page, pageSize },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Create a new event
export const createEvent = createAsyncThunk(
  "event/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(`/events`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Update an event
export const updateEvent = createAsyncThunk(
  "event/update",
  async ({ id, updatedData }, { rejectWithValue }) => {
    try {
      const response = await API.put(`/events/${id}`, updatedData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Delete an event
export const deleteEvent = createAsyncThunk(
  "event/delete",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await API.delete(`/events/${id}`);
      return { ...response.data, id };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  events: [],
  status: null,
  error: undefined,
  total: 0,
  page: 1,
  pageSize: 5,
};

const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      // Get Events
      .addCase(getEvents.pending, (state) => {
        state.error = undefined;
        state.status = "loading";
        state.events = [];
      })
      .addCase(getEvents.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.events = action.payload.events;
        state.total = action.payload.total;
      })
      .addCase(getEvents.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      })

      // Create Event
      .addCase(createEvent.pending, (state) => {
        state.error = undefined;
        state.status = "loading";
      })
      .addCase(createEvent.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.events.unshift(action.payload.event);
      })
      .addCase(createEvent.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      })

      // Handle Update Event
      .addCase(updateEvent.fulfilled, (state, action) => {
        const index = state.events.findIndex(
          (event) => event._id === action.payload.event._id
        );
        if (index !== -1) {
          state.events[index] = action.payload.event;
        }
        state.status = "succeeded";
      })
      .addCase(updateEvent.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateEvent.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Handle Delete Event
      .addCase(deleteEvent.fulfilled, (state, action) => {
        state.events = state.events.filter(
          (event) => event._id !== action.payload.id
        );
        state.status = "succeeded";
      })
      .addCase(deleteEvent.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteEvent.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

// Selector to get event by ID
export const selectEventById = (state, id) => {
  return state.event?.events?.find((event) => event._id === id);
};

export default eventSlice.reducer;
