import { toast } from "react-toastify";

import { isAnyOf } from "@reduxjs/toolkit";
import { createBlog, deleteBlog, updateBlog } from "../slices/blog-slice";
import {
  createPortifolio,
  deletePortifolio,
  updatePortifolio,
} from "../slices/portifolio-slice";
import { createJob, deleteJob, updateJob } from "../slices/job-slice";
import {
  createTestimony,
  deleteTestimony,
  updateTestimony,
} from "../slices/testimony-slice";
import {
  createMagazine,
  deleteMagazine,
  updateMagazine,
} from "../slices/e-magazine-slice";
import {
  createPartner,
  deletePartner,
  updatePartner,
} from "../slices/partner-slice";
import {
  createResource,
  deleteResource,
  updateResource,
} from "../slices/resource-slice";
import { createEvent, deleteEvent, updateEvent } from "../slices/event-slice";
import {
  createOpportunity,
  deleteOpportunity,
  updateOpportunity,
} from "../slices/opportunity-slice";
import {
  createTeamMember,
  deleteTeamMember,
  updateTeamMember,
} from "../slices/teamMember-slice";

export const successResponseNotificationListener = (startListening) => {
  startListening({
    matcher: isAnyOf(
      createBlog.fulfilled,
      updateBlog.fulfilled,
      deleteBlog.fulfilled,
      createPortifolio.fulfilled,
      updatePortifolio.fulfilled,
      deletePortifolio.fulfilled,
      createJob.fulfilled,
      updateJob.fulfilled,
      deleteJob.fulfilled,
      createTestimony.fulfilled,
      updateTestimony.fulfilled,
      deleteTestimony.fulfilled,
      createMagazine.fulfilled,
      updateMagazine.fulfilled,
      deleteMagazine.fulfilled,
      createPartner.fulfilled,
      updatePartner.fulfilled,
      deletePartner.fulfilled,
      createResource.fulfilled,
      deleteResource.fulfilled,
      updateResource.fulfilled,
      createEvent.fulfilled,
      updateEvent.fulfilled,
      deleteEvent.fulfilled,
      deleteOpportunity.fulfilled,
      updateOpportunity.fulfilled,
      createOpportunity.fulfilled,
      createTeamMember.fulfilled,
      updateTeamMember.fulfilled,
      deleteTeamMember.fulfilled
    ),
    effect: (action) => {
      if (action.payload.success) {
        toast.success(action.payload.message);
      } else toast.error(action.payload.message);
    },
  });
};
