import { useEffect } from "react";
import { pageTitle } from "../../helpers/PageTitle";
import Section from "../Section";
import AboutUsSection from "../Section/AboutSection";
import BrandSection from "../Section/BrandSection";
import ContactSection from "../Section/ContactSection";
import HeroSection from "../Section/HeroSection";
import MovingTextSection from "../Section/MovingTextSection";

import TestimonialSection from "../Section/TestimonialSection";
import WhyChooseUsSection from "../Section/WhyChooseUsSection";
import { heroData } from "../../data/hero.data";

import ServiceSectionStyle4 from "../Section/ServiceSection/ServiceSectionStyle4";
import servicesData from "../../data/services";
import DownloadAppSection from "./MobileAppsPage";

const movingTextData = [
  "* Rental & Sales Services",
  "* Real Estate Marketing",
  "* Asset Valuation",
  "* Asset Management",
  "* System Development",
  "* Business & Investment Consultancy",
];

export default function Home() {
  pageTitle("Home");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     
      <HeroSection data={heroData} />

      <Section>
        <MovingTextSection data={movingTextData} />
      </Section>

      <Section pt="100" ptLg="30" pb="5" pbLg="5">
        <ServiceSectionStyle4
          sectionTitleUp="Our Service List"
          sectionTitle="Gadal Technologies offer <br> the following services"
          data={servicesData}
        />
      </Section>

      <Section pt="100" ptLg="30" pb="5" pbLg="5" className="position-relative mb-28">
        <AboutUsSection
          thumbUrl="/images/exp-gadal.jpg"
          experienceBgUrl="/images/work_experiance_4.jpeg"
          experienceIconUrl="/images/experience_icon.svg"
          experienceNumber="4"
          experienceTitle="Work Experience"
          sectionTitleUp="About Us"
          sectionTitle="Excel with <span className='text-accent'>Gadal</span>"
          sectionSubTitle="Gadal Technology PLC is a private company focused on simplifying daily activities through online marketing. We operate in e-commerce, construction material supply, and the sale and rental of machinery, vehicles, and properties nationwide.Our platform, gadalmarket.com, enhances our services by offering machinery rentals and facilitating vehicle and property transactions."
          grothTitle="E-commerce and Construction Services"
          brandText = "The name Gadal is derived from Hebrew, meaning: to grow, to cause to grow, become great or important, promote, make powerful, praise, do great things, to be magnified, and to be brought up."
          ceoText = "Samson Belda, CEO and founder of Gadal Technologies, earned his Bachelor's degree in Marketing and Sales Management from Jimma University in 2009. He became self-employed in 2012, starting as a commission agent. In 2020, he established Gadal Technologies as a private limited company in Addis Ababa, Ethiopia, focusing on system development and tech-based business."
          grothPercentage="90"
          featureList={[
            {
              title: "Vehicle Sales and Rentals",
              subTitle:
                "Facilitating the sale and rental of light vehicles and fixed assets.",
            },
            {
              title: "Construction Materials Supply",
              subTitle:
                "Supplying materials for construction at competitive market rates.",
            },
            {
              title: "Heavy Machinery Rental and Sales",
              subTitle: "Offering machinery and vehicles for rent or sale.",
            },
            {
              title: "Real Estate and Property Rental",
              subTitle:
                "Providing properties including buildings, villas, and warehouses for sale or rent.",
            },
          ]}
          btnText="Get A Quote"
          btnUrl="/contact"
          videoBtnText="Watch the video"
          videoBtnUrl="https://www.youtube.com/embed/NfyDbDz95s4"
        />
      </Section>

      <DownloadAppSection />

      <Section
        pt="135"
        ptLg="75"
        pb="100"
        pbLg="40"
        className="position-relative cs_iconbox_2_wrap overflow-hidden"
      >
        <WhyChooseUsSection
          imgUrlLeft="/images/moving_text_shape.png"
          imgUrlRight="/images/why_choose_us.png"
          sectionTitleUp="Why Choose Us"
          sectionTitle=' Providing <span className="text-accent"> reliable information </span> <br />  from the source to the clients.'
          sectionSubTitle="Offering a wide range of service with thousands of clients and partners on IT System development, rental and sales of Properties, machineries, vehicles, asset valuation, investment opportunities, business consulting, Realestate marketing, property management"
          services={[
            {
              icon: "mdi:shield-check", 
              title: "Integrity",
            },
            {
              icon: "mdi:lightbulb-on", 
              title: "Innovation",
            },
            {
              icon: "mdi:checkbox-marked-circle-outline", 
              title: "Accountability",
            },
            {
              icon: "mdi:hand-heart", 
              title: "Honesty",
            },
            {
              icon: "mdi:handshake", 
              title: "Respect",
            },
            {
              icon: "mdi:fire", 
              title: "Passion",
            },
            {
              icon: "mdi:account-group", 
              title: "Teamwork",
            },
            {
              icon: "mdi:star-circle", 
              title: "Excellence",
            },
          ]}
          
        />
      </Section>

      <Section
        pt="140"
        ptLg="75"
        pb="135"
        pbLg="75"
        style={{ backgroundImage: `url(${"/images/testimonial_bg.jpeg"})` }}
      >
        <TestimonialSection
          sectionTitleUp="Testimonial"
          sectionTitle="What They’re Saying?"
          sectionSubTitle="Discover the testimonials how our comprehensive service explained by our existing clients"
        />
      </Section>

      <Section
        pt="133"
        ptLg="75"
        pb="140"
        pbLg="80"
        className="cs_copyright object-cover text-white overflow-hidden bg-black opacity-95"
        style={{ backgroundImage: 'url("/images/moving_text_shape.png")' }}
      >
        <ContactSection
          sectionTitleUp="Contact With Us"
          sectionTitle="Let’s Work Together?"
          sectionSubTitle="Ready to bring your ideas to life? Partner with Gadal Technologies to leverage innovative solutions and achieve your goals."
          textVarient="text-white"
          className="cs_mb_40"
          sectionImgUrl="/images/contact_img.png"
          contactInfo={[
            {
              imgUrl: "/images/icons/contact_icon_1.svg",
              titleUp: "Have Any Question?",
              title: "+2519 11 22 93 61",
              textColor: "text-white",
            },
            {
              imgUrl: "/images/icons/contact_icon_2.svg",
               titleUp: "Send Email",
              title: "support@gadaltech.com",
              textColor: "text-white",
            },
            {
              imgUrl: "/images/icons/contact_icon_3.svg",
              titleUp: "Address",
              title:
                "Addis Abab ,Bole Sub-city Wereda 04, Amen Building House number 401",
              textColor: "text-white",
            },
          ]}
        />
      </Section>

      <div className="mb-20 mt-20">
        <BrandSection />
      </div>
    </>
  );
}
