import React, { useEffect, useState } from "react";
import Breadcrumb from "../Breadcrumb";
import Section from "../Section";
import Accordion from "../Accordion";
import FormStyle5 from "../Form/FormStyle5";
import { Icon } from "@iconify/react";
import { pageTitle } from "../../helpers/PageTitle";
import { Link, useLocation } from "react-router-dom";
import servicesData from "../../data/services";
import VideoBlock from "../VideoBlock";
import Button from "../Button";

export default function ServiceDetailsPage() {
  pageTitle("Service Details");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();
  const id = location?.state?.id;

  const service = servicesData.find((service) => service.id === id);

  const [selectedService, setSelectedService] = useState(
    service?.subServices[0]
  );

  const handleServiceClick = (event, service) => {
    event.preventDefault();
    setSelectedService(service);
  };

  return (
    <>
      <Breadcrumb title="Our Services" bgUrl="/images/page_header_1.jpeg" />

      <Section pt="20" className="mt-5" ptLg="80" pb="115" pbLg="55">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-5 cs_mb_lg_60">
              <div className="cs_service_list cs_mb_40">
                <h2 className="cs_fs_20 text-white text-uppercase bg-accent cs_pl_30 cs_pr_30 cs_pt_23 cs_pb_23 m-0">
                  Service Categories
                </h2>
                <ul className="m-0 cs_pl_30 cs_pr_30 cs_pt_30 cs_pb_30 ">
                  {service?.subServices &&
                    service?.subServices?.map((item, index) => (
                      <Link
                        key={index}
                        onClick={(event) => handleServiceClick(event, item)}
                        className=" my-2"
                      >
                        <p>
                          <div className="">{item.title}</div>
                          <Icon
                            icon="fa6-solid:arrow-right-long"
                            className="w-4 h-4"
                          />
                        </p>
                      </Link>
                    ))}
                </ul>
              </div>

              <div
                className="cs_quick_contact_card background-filled cs_mb_40 text-center cs_copyright  text-white background-filled "
                style={{
                  backgroundImage: 'url("/images/moving_text_shape.png")',
                }}
              >
                <div className="cs_quick_contact_card_in">
                  <h6 className="cs_fs_30 cs_fs_lg_36 text-white cs_mb_40">
                    {selectedService?.question}
                  </h6>

                  <Link
                    to={selectedService?.formUrl}
                    target="_blank"
                    className="cs_btn cs_style_1 cs_fs_16 cs_rounded_5 cs_pl_30 cs_pr_30 cs_pt_10 cs_pb_10 overflow-hidden"
                  >
                    <span>Fill this Form</span>
                  </Link>
                </div>
              </div>

              <div className="bg-gray cs_pl_30 cs_pr_30 cs_pt_55 cs_pb_60">
                <h2 className="cs_fs_26 cs_mb_25">Quick Contact</h2>
                <FormStyle5 />
              </div>
            </div>

            <div className="col-xl-8 col-lg-7">
              <h2 className="cs_fs_26 cs_mb_20">{selectedService.title}</h2>
              <p className="cs_mb_10">{selectedService.description}</p>

              <img
                src={selectedService.imgUrl}
                alt="Thumb"
                className="cs_mb_70 rounded  max-h-[600px] w-[800px]"
              />

              <div className="d-flex align-items-center mb-16 mt-5 items-center justify-center cs_row_gap_20 cs_column_gap_30 cs_column_gap_lg_20 flex-wrap">
                <Button btnText={"Get A Quote"} btnUrl={"/contact"} />
                <VideoBlock
                  videoSrc={"https://www.youtube.com/embed/NfyDbDz95s4"}
                  videoBtnText={"Watch the video"}
                />
              </div>

              <div className="cs_section_heading cs_style_1 cs_mb_30">
                <div className="cs_section_heading_in">
                  <h3 className="cs_fs_20 text-accent fw-normal cs_lh_base cs_mb_15">
                    frequently asked questions
                  </h3>
                  <h2 className="cs_fs_36 cs_mb_0">Questions & Answers</h2>
                </div>
              </div>

              <Accordion data={service?.faqData} />
            </div>
          </div>
        </div>
      </Section>
    </>
  );
}
