import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getJobs } from "../../store/slices/job-slice";
import { FaBuilding, FaMapMarkerAlt } from "react-icons/fa"; // Import icons
import { Link } from "react-router-dom";
import SectionHeadingStyle2 from "../SectionHeading/SectionHeadingStyle2";
import Breadcrumb from "../Breadcrumb";

const JobsPage = () => {
  const { jobs, status } = useSelector((state) => state.job);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getJobs({ page: 1, pageSize: 5 }));
  }, [dispatch]);

  return (
    <div className="">
      <Breadcrumb
        title="Team Member Details"
        bgUrl="/images/page_header_1.jpeg"
      />
      <SectionHeadingStyle2
        sectionTitleUp={"Our Jobs"}
        sectionTitle={
          'Offering <span className="text-accent"> exciting opportunities </span> <br />'
        }
      />
      <div className="grid grid-cols-2 gap-4">
        {jobs &&
          jobs.map((job) => (
            <div
              key={job.id}
              className="border border-gray-200 rounded-lg shadow-sm p-4 mb-6"
            >
              {/* Job Title - First Line */}
              <h2 className="text-2xl font-bold mb-2">{job.title}</h2>

              {/* Company, Location, Experience Level - Second Line */}
              <div className="flex flex-col sm:flex-row justify-between mb-4">
                <div className="flex items-center space-x-2 text-lg font-semibold">
                  {/* Company Icon and Name */}
                  <span className="flex items-center text-gray-700">
                    <FaBuilding className="mr-2 text-accent" /> {job.company}
                  </span>

                  {/* Location Icon and Name */}
                  <span className="flex items-center text-gray-700">
                    <FaMapMarkerAlt className="mr-2 text-accent" />{" "}
                    {job.location}
                  </span>
                </div>
                {/* Experience Level */}
                <div className="bg-blue-100 text-blue-600 px-4 py-2 rounded-lg">
                  Experience Level: {job.experienceLevel}
                </div>
              </div>

              {/* Requirements - Third Section */}
              <div className="mb-4">
                <h3 className="font-semibold text-xl mb-2">Requirements:</h3>
                <ul className="list-disc pl-5 space-y-2 text-gray-700">
                  {job.requirements[0].split(",").map((requirement, index) => (
                    <li key={index}>{requirement.trim()}</li>
                  ))}
                </ul>
              </div>

              {/* View More - Fourth Section */}
              <div className="flex justify-end ">
                <Link
                  to={`/job/${job._id}`}
                  className="cs_post_btn d-flex justify-content-between align-items-center  gap-3"
                >
                  <span className="cs_post_btn-text">Read More</span>
                  <div className="cs_post_btn-icon d-flex cs_transition_4">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.9999 0.224976C6.52011 0.224976 1.63131 4.16368 0.485006 9.52707C-0.0876941 12.207 0.298106 15.0567 1.57581 17.4816C2.80551 19.8153 4.82151 21.7014 7.23351 22.7703C9.74241 23.8824 12.6227 24.0762 15.2597 23.3178C17.8037 22.5864 20.0594 20.9811 21.5951 18.8262C24.806 14.3211 24.3767 7.99288 20.5991 3.95608C18.3851 1.59028 15.2405 0.224976 11.9999 0.224976ZM17.6486 12.6291L14.4386 15.9165C13.6259 16.749 12.3413 15.4878 13.1507 14.6592L14.7704 13.0005H7.09461C6.54951 13.0005 6.09471 12.5454 6.09471 12.0006C6.09471 11.4558 6.54981 11.0007 7.09461 11.0007H14.732L13.0802 9.34918C12.2594 8.52838 13.532 7.25548 14.3528 8.07628L17.6411 11.3643C17.9897 11.7126 17.993 12.2766 17.6486 12.6291Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                </Link>
              </div>
            </div>
          ))}
      </div>

      {!jobs && (
        <div className="h-screen font-bold flex items-center justify-center">
          No Job found
        </div>
      )}

      {status === "loading" && (
        <div className="font-bold h-screen flex items-center justify-center">
          Loading....
        </div>
      )}
    </div>
  );
};

export default JobsPage;
