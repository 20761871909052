import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../helpers/api";

// Fetch opportunities with pagination
export const getOpportunities = createAsyncThunk(
  "opportunity/fetch",
  async ({ page, pageSize }, { rejectWithValue }) => {
    try {
      const response = await API.get(`/opportunities`, {
        params: { page, pageSize },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Create a new opportunity
export const createOpportunity = createAsyncThunk(
  "opportunity/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(`/opportunities`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Update an existing opportunity
export const updateOpportunity = createAsyncThunk(
  "opportunity/update",
  async ({ id, updatedData }, { rejectWithValue }) => {
    try {
      const response = await API.put(`/opportunities/${id}`, updatedData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Delete an opportunity
export const deleteOpportunity = createAsyncThunk(
  "opportunity/delete",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await API.delete(`/opportunities/${id}`);
      return { ...response.data, id };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  opportunities: [],
  status: null,
  error: undefined,
  total: 0,
  page: 1,
  pageSize: 5,
};

const opportunitySlice = createSlice({
  name: "opportunity",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      // Get Opportunities
      .addCase(getOpportunities.pending, (state) => {
        state.error = undefined;
        state.status = "loading";
        state.opportunities = [];
      })
      .addCase(getOpportunities.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.opportunities = action.payload.opportunities;
        state.total = action.payload.total;
      })
      .addCase(getOpportunities.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      })

      // Create Opportunity
      .addCase(createOpportunity.pending, (state) => {
        state.error = undefined;
        state.status = "loading";
      })
      .addCase(createOpportunity.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.opportunities.unshift(action.payload.opportunity);
      })
      .addCase(createOpportunity.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      })

      // Update Opportunity
      .addCase(updateOpportunity.fulfilled, (state, action) => {
        const index = state.opportunities.findIndex(
          (opportunity) => opportunity._id === action.payload.opportunity._id
        );
        if (index !== -1) {
          state.opportunities[index] = action.payload.opportunity;
        }
        state.status = "succeeded";
      })
      .addCase(updateOpportunity.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateOpportunity.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Delete Opportunity
      .addCase(deleteOpportunity.fulfilled, (state, action) => {
        state.opportunities = state.opportunities.filter(
          (opportunity) => opportunity._id !== action.payload.id
        );
        state.status = "succeeded";
      })
      .addCase(deleteOpportunity.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteOpportunity.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

// Selector to get an opportunity by ID
export const selectOpportunityById = (state, id) => {
  return state.opportunity?.opportunities?.find(
    (opportunity) => opportunity._id === id
  );
};

export default opportunitySlice.reducer;
