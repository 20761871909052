import { Outlet } from "react-router-dom";
import Footer from "../Footer";
import Header from "../Header";
import { AiOutlineWhatsApp } from "react-icons/ai";

export default function Layout() {
  return (
    <>
      <Header />
      <a
        href="https://wa.me/911229361"
        target="_blank"
        rel="noopener noreferrer"
        title="Chat with us on WhatsApp"
        style={{
          margin: "0 0 20px 0",
          height: "52px",
          minWidth: "52px",
          padding: "5px",
          position: "fixed",
          bottom: "20px",
          right: "20px",
          zIndex: "999999999",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "transform 0.3s",
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.transform = "scale(1.1)";
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.transform = "scale(1)";
        }}
      >
        <AiOutlineWhatsApp
          size={60}
          style={{ backgroundColor: "#25D366", borderRadius: "50%" }}
          className="p-[8px]"
          color="#fff"
        />
      </a>
      <Outlet />
      <Footer />
    </>
  );
}
