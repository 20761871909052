import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../helpers/api";

// Fetch team members with pagination
export const getTeamMembers = createAsyncThunk(
  "team/fetch",
  async ({ page, pageSize }, { rejectWithValue }) => {
    try {
      const response = await API.get(`/team-members`, {
        params: { page, pageSize },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Create a new team member
export const createTeamMember = createAsyncThunk(
  "team/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(`/team-members`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Update an existing team member
export const updateTeamMember = createAsyncThunk(
  "team/update",
  async ({ id, updatedData }, { rejectWithValue }) => {
    try {
      const response = await API.put(`/team-members/${id}`, updatedData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Delete a team member
export const deleteTeamMember = createAsyncThunk(
  "team/delete",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await API.delete(`/team-members/${id}`);
      return { ...response.data, id };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


const initialState = {
  teamMembers: [],
  status: null,
  error: undefined,
  total: 0,
  page: 1,
  pageSize: 5,
};

const teamSlice = createSlice({
  name: "teamMember",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getTeamMembers.pending, (state) => {
        state.error = undefined;
        state.status = "loading";
        state.teamMembers = [];
      })
      .addCase(getTeamMembers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.teamMembers = action.payload.teamMembers;
        state.total = action.payload.total;
      })
      .addCase(getTeamMembers.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      })

      .addCase(createTeamMember.pending, (state) => {
        state.error = undefined;
        state.status = "loading";
      })
      .addCase(createTeamMember.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.teamMembers.unshift(action.payload.teamMember);
      })
      .addCase(createTeamMember.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      })

      .addCase(updateTeamMember.fulfilled, (state, action) => {
        const index = state.teamMembers.findIndex(
          (member) => member._id === action.payload.teamMember._id
        );
        if (index !== -1) {
          state.teamMembers[index] = action.payload.teamMember;
        }
        state.status = "succeeded";
      })
      .addCase(updateTeamMember.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateTeamMember.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      .addCase(deleteTeamMember.fulfilled, (state, action) => {
        state.teamMembers = state.teamMembers.filter(
          (member) => member._id !== action.payload.id
        );
        state.status = "succeeded";
      })
      .addCase(deleteTeamMember.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteTeamMember.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const selectTeamMemberById = (state, id) => {
  return state.teamMember?.teamMembers?.find((member) => member._id === id);
};

export default teamSlice.reducer;
