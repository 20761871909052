import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import DeleteMagazineModal from "./DeleteEMagazineModal";
import AddEMagazineModalContent from "./AddEmagazineModal";
import { getMagazines } from "../../../../store/slices/e-magazine-slice";

const MagazineTable = ({ magazines }) => {
  const { status, total, pageSize } = useSelector((state) => state.magazine);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedMagazineId, setSelectedMagazineId] = useState();

  const handleEdit = (id) => {
    setSelectedMagazineId(id);
    setEditModalOpen(true);
  };

  const handleDelete = (id) => {
    setSelectedMagazineId(id);
    setDeleteModalOpen(true);
  };

  const dispatch = useDispatch();

  const handlePaginationModelChange = (pagination) => {
    dispatch(
      getMagazines({
        page: pagination.page + 1,
        pageSize: pagination.pageSize,
      })
    );
  };

  const columns = [
    { field: "_id", headerName: "ID", flex: 0.5 },
    { field: "year", headerName: "Year", flex: 1 },
    { field: "month", headerName: "Month", flex: 1 },
    { field: "coverImage", headerName: "Cover Image URL", flex: 1 },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 0.7,
      renderCell: (params) => {
        if (params.value) {
          const date = new Date(params.value);
          return date.toLocaleDateString();
        }
        return "";
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <div className="flex flex-row gap-3 mt-2 items-center justify-center">
          <button
            className="text-blue-500 text-xl"
            onClick={() => handleEdit(params.row._id)}
          >
            <FaEdit />
          </button>
          <button
            className="text-red-500 text-xl"
            onClick={() => handleDelete(params.row._id)}
          >
            <FaTrashAlt />
          </button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <DataGrid
        sx={{ m: 2 , minHeight: '400px',}}
        style={{ padding: "12px" }}
        getRowId={(row) => row._id}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 5, page: 0 },
          },
        }}
        columns={columns}
        rows={magazines}
        rowCount={total}
        loading={status === "loading"}
        pageSize={pageSize}
        pagination
        paginationMode="server"
        pageSizeOptions={[5, 10, 15]}
        onPaginationModelChange={handlePaginationModelChange}
      />

      <AddEMagazineModalContent
        isOpen={isEditModalOpen}
        handleModalClose={() => setEditModalOpen(false)}
        id={selectedMagazineId}
      />

      <DeleteMagazineModal
        handleModalClose={() => setDeleteModalOpen(false)}
        isOpen={deleteModalOpen}
        magazineId={selectedMagazineId}
      />
    </div>
  );
};

export default MagazineTable;
