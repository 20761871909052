import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";

import { ModalHeader } from "../modal/ModalHeader";
import { ModalBody } from "../modal/ModalBody";
import { ModalFooter } from "../modal/ModalFooter";
import {
  createPortifolio,
  selectPortifolioById,
  updatePortifolio,
} from "../../../../store/slices/portifolio-slice";
import ImageUploader from "../blog/ImageUploader";

const defaultData = {
  title: "",
  description: "",
  category: "Technology",
  client: "",
  projectChallenges: "",
  location: "",
  imgUrl: "",
};

const AddPortifolioModal = ({ handleModalClose, id, isOpen }) => {
  const dispatch = useDispatch();

  const selectedPortifolio = useSelector((state) =>
    selectPortifolioById(state, id)
  );

  const [formData, setFormData] = useState(defaultData);

  useEffect(() => {
    if (id && selectedPortifolio) {
      setFormData({
        title: selectedPortifolio.title,
        category: selectedPortifolio.categories,
        imageUrl: selectedPortifolio.imageUrl,
        description: selectedPortifolio.description,
        client: selectedPortifolio.client,
        projectChallenges: selectedPortifolio.projectChallenges,
        location: selectedPortifolio.location,
      });
    }
  }, [id, selectedPortifolio]);

  const handleSave = async (event) => {
    event.preventDefault();

    if (id) {
      const updatedData = { ...formData };
      dispatch(updatePortifolio({ id, updatedData }));
    } else {
      dispatch(createPortifolio(formData));
    }
    handleModalClose();
    setFormData(defaultData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageUploadSuccess = (url) => {
    setFormData((prevState) => ({
      ...prevState,
      imgUrl: url,
    }));
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleModalClose}
      contentLabel="Add/Edit Portifolio"
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
        },
        overlay: {
          background: "rgba(0,0,0,0.10)",
        },
      }}
    >
      <ModalHeader
        title={id ? "Edit Portifolio" : "Add Portifolio"}
        handleModalClose={handleModalClose}
        borderBottom={true}
      />
      <ModalBody>
        <form className="flex flex-col max-h-[400px]" onSubmit={handleSave}>
          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              Title
            </div>

            <div className="w-9/12">
              <input
                id="title"
                name="title"
                className="form-control cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full placeholder-gray-400"
                value={formData.title}
                onChange={handleChange}
                placeholder="Enter portifolio title"
              />
            </div>
          </div>

          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              Description
            </div>
            <div className="w-9/12">
              <textarea
                id="description"
                name="description"
                className="form-control cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full placeholder-gray-400"
                value={formData.description}
                onChange={handleChange}
                placeholder="Enter resource description"
              />
            </div>
          </div>

          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              Project Challenges
            </div>
            <div className="w-9/12">
              <textarea
                id="projectChallenges"
                name="projectChallenges"
                className="form-control cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full placeholder-gray-400"
                value={formData.projectChallenges}
                onChange={handleChange}
                placeholder="Enter comma separated challenges.."
              />
            </div>
          </div>

          <div className="flex items-start w-full pb-4 font-semibold">
            <div className="w-3/12 h-9 flex items-center text-sm">
              Categories
            </div>
            <div className="w-9/12">
              <select
                id="category"
                name="category"
                className="form-control placeholder-gray-400 cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full"
                value={formData.category}
                defaultValue={"Technology"}
                onChange={handleChange}
                size="small"
                required
              >
                <option value="Technology">Technology</option>
                <option value="Business">Business</option>
                <option value="Design">Design</option>
                <option value="Marketing">Marketing</option>
                <option value="Finance">Finance</option>
              </select>
            </div>
          </div>

          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              Client
            </div>

            <div className="w-9/12">
              <input
                id="client"
                name="client"
                className="form-control cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full placeholder-gray-400"
                value={formData.client}
                onChange={handleChange}
                placeholder="Enter client name"
              />
            </div>
          </div>

          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              Location
            </div>

            <div className="w-9/12">
              <input
                id="location"
                name="location"
                className="form-control cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full placeholder-gray-400"
                value={formData.location}
                onChange={handleChange}
                placeholder="Enter project location"
              />
            </div>
          </div>

          <div className="flex  w-full pb-4 gap-2 items-center">
            <div className="mr-16 h-9 flex items-center text-sm font-semibold">
              Image
            </div>
            <div>
              <ImageUploader onUploadSuccess={handleImageUploadSuccess} />
            </div>
          </div>

          <ModalFooter>
            <div className="flex justify-end gap-2">
              <button
                className="flex items-center shadow-md px-3 py-1 rounded-md border hover:text-accent hover:bg-gray-300 focus:outline-none"
                onClick={handleModalClose}
              >
                <span>Close</span>
              </button>

              <button
                type="submit"
                disabled={!id && !formData.imgUrl}
                className="flex items-center disabled:cursor-not-allowed shadow-md px-3 py-1 rounded-md border hover:bg-gray-300 hover:text-accent  bg-accent text-white"
              >
                Save
              </button>
            </div>
          </ModalFooter>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default AddPortifolioModal;
