import React, { } from "react";

import { Link } from "react-router-dom";

const SideBarItem = ({ title, icon: Icon, onClick, active, path }) => {
  return (
    <Link to={path}>
      <div
        className="flex h-fit cursor-pointer border"
        onClick={() => onClick(title)}
      >
        <div
          className={`w-2 rounded ${active ? "bg-light-primary" : ""}`}
        ></div>
        <div
          className={` w-full md:w-[185px] flex items-center  px-5 py-2 mx-5 ${
            active ? "bg-opacity-10 dark:bg-opacity-20" : ""
          } rounded-lg  `}
        >
          <div className="pr-4 flex flex-row items-center justify-center gap-4">
            <Icon className={`${active ? "text-accent" : ""}`} />
            <p className={`${active ? "text-accent" : ""} whitespace-nowrap`}>{title}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default SideBarItem;
