import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaBuilding, FaMapMarkerAlt } from "react-icons/fa";
import { selectJobById } from "../../store/slices/job-slice";
import SectionHeadingStyle2 from "../SectionHeading/SectionHeadingStyle2";
import Breadcrumb from "../Breadcrumb";

const JobDetailsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  const job = useSelector((state) => selectJobById(state, id));

  if (!job) {
    return <p>Job not found.</p>;
  }

  // Date formatting
  const formattedPostedDate = job.postedDate
    ? new Date(job.postedDate).toLocaleDateString()
    : "N/A";
  const formattedDeadline = job.deadline
    ? new Date(job.deadline).toLocaleDateString()
    : "N/A";

  return (
    <>
      <Breadcrumb
        title="Team Member Details"
        bgUrl="/images/page_header_1.jpeg"
      />
      <SectionHeadingStyle2
        sectionTitleUp={"Our Jobs"}
        sectionTitle={
          'Offering <span className="text-accent"> exciting opportunities </span> <br />'
        }
      />
      <div className="p-8 max-w-4xl mx-auto bg-white shadow-lg rounded-lg mt-5">
        {/* Job Title */}
        <h3 className="text-4xl font-extrabold text-gray-900 mb-6">
          {job.title}
        </h3>

        {/* Company, Location, Experience Level */}
        <div className="flex flex-col sm:flex-row justify-between items-center mb-6 space-y-4 sm:space-y-0">
          {/* Company and Location */}
          <div className="flex items-center space-x-6 text-lg font-medium text-gray-700">
            {/* Company */}
            <span className="flex items-center">
              <FaBuilding className="mr-2 text-accent" />
              <span className="text-gray-800">{job.company}</span>
            </span>

            {/* Location */}
            <span className="flex items-center">
              <FaMapMarkerAlt className="mr-2 text-accent" />
              <span className="text-gray-800">{job.location}</span>
            </span>
          </div>

          {/* Experience Level */}
          <div className="bg-blue-100 text-blue-600 px-4 py-2 rounded-lg">
            Experience Level: {job.experienceLevel}
          </div>
        </div>

        {/* Job Type, Salary, Openings */}
        <div className="bg-gray-100 p-4 rounded-lg mb-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <p>
              <strong className="font-semibold">Job Type:</strong> {job.type}
            </p>
            {job.salary && (
              <p>
                <strong className="font-semibold">Salary:</strong> {job.salary}
              </p>
            )}
            <p>
              <strong className="font-semibold">Number of Openings:</strong>{" "}
              {job.numberOfOpenings}
            </p>
          </div>
        </div>

        {/* Job Description */}
        <div className="mb-6">
          <h3 className="text-2xl font-semibold text-gray-900 mb-2">
            Job Description
          </h3>
          <p className="text-gray-700 leading-relaxed">{job.description}</p>
        </div>

        {/* Requirements */}
        <div className="mb-6">
          <h3 className="text-2xl font-semibold text-gray-900 mb-2">
            Requirements
          </h3>
          <ul className="list-disc pl-5 space-y-2 text-gray-700 leading-relaxed">
            {job.requirements.map((requirement, index) => (
              <li key={index}>{requirement.trim()}</li>
            ))}
          </ul>
        </div>

        {/* Dates and Status */}
        <div className="mb-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <p>
              <strong className="font-semibold">Posted Date:</strong>{" "}
              {formattedPostedDate}
            </p>
            {job.deadline && (
              <p>
                <strong className="font-semibold">Application Deadline:</strong>{" "}
                {formattedDeadline}
              </p>
            )}
            <p>
              <strong className="font-semibold">Status:</strong> {job.status}
            </p>
          </div>
        </div>

        {/* Contact Information */}
        <div className="mb-6">
          <p>
            <strong className="font-semibold">Contact Info:</strong>{" "}
            {job.contactInfo}
          </p>
        </div>

        {/* Apply Button */}
        <div className="text-center">
          <Link
            to={job.formLink && job.formLink}
            target={job.formLink != null ?  "_blanck" : "_self"}
            className="bg-accent text-white px-8 py-3 rounded-full shadow-md hover:bg-blue-600 transition ease-in-out duration-300"
          >
            Apply Now
          </Link>
        </div>
      </div>
    </>
  );
};

export default JobDetailsPage;
