import React, { useState } from "react";
import axios from "axios";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    phoneNumber: "",
    company : ""
  });

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSuccess(false);

    try {
      console.log(process.env.REACT_APP_BASE_URL);

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/contact`,
        formData
      );

      if (response.status === 200) {
        setSuccess(true);
        setFormData({
          name: "",
          email: "",
          message: "",
          phoneNumber: "",
          company : ""
        });
      } else {
        setError("Failed to send message. Please try again.");
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="row" onSubmit={handleSubmit}>
      <div className="col-lg-6">
        <input
          className="form-control cs_fs_14 cs_rounded_5 border-0 cs_mb_35 bg-gray"
          type="text"
          placeholder="Your Name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          required
        />
      </div>
      <div className="col-lg-6">
        <input
          className="form-control cs_fs_14 cs_rounded_5 border-0 cs_mb_35 bg-gray"
          type="email"
          placeholder="Email Address"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="col-lg-6">
        <input
          className="form-control cs_fs_14 cs_rounded_5 border-0 cs_mb_35 bg-gray"
          type="text"
          placeholder="Phone Number"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="col-lg-6">
        <input
          className="form-control cs_fs_14 cs_rounded_5 border-0 cs_mb_35 bg-gray"
          type="text"
          placeholder="Your company (optional)"
          name="company"
          value={formData.company}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="col-lg-12">
        <textarea
          className="form-control cs_fs_14 cs_rounded_5 border-0 cs_mb_40 bg-gray"
          placeholder="Message here ..."
          name="message"
          value={formData.message}
          onChange={handleInputChange}
          cols={35}
          rows={10}
          required
        />
      </div>
      <div className="col-lg-12">
        <button
          type="submit"
          className="cs_btn cs_style_1 cs_fs_16 cs_rounded_5 cs_pl_30 cs_pr_30 cs_pt_10 cs_pb_10 overflow-hidden"
          disabled={loading}
        >
          {loading ? <span>Sending...</span> : <span>Send A Message</span>}
        </button>
      </div>

      {error && (
        <div className="col-lg-12">
          <p className="text-danger">{error}</p>
        </div>
      )}

      {success && (
        <div className="col-lg-12">
          <p className="text-success">Message sent successfully!</p>
        </div>
      )}
    </form>
  );
};

export default ContactForm;
