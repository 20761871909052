import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";

import { ModalHeader } from "../modal/ModalHeader";
import { ModalBody } from "../modal/ModalBody";
import { ModalFooter } from "../modal/ModalFooter";
import {
  createTestimony,
  selectTestimonyById,
  updateTestimony,
} from "../../../../store/slices/testimony-slice";
import ImageUploader from "../blog/ImageUploader";

const defaultData = {
  name: "",
  designation: "",
  rating: 5,
  description: "",
  imgUrl: "",
};

const AddTestimonyModal = ({ handleModalClose, id, isOpen }) => {
  const dispatch = useDispatch();

  const selectedTestimony = useSelector((state) =>
    selectTestimonyById(state, id)
  );

  const [formData, setFormData] = useState(defaultData);

  useEffect(() => {
    if (id && selectedTestimony) {
      setFormData({
        name: selectedTestimony.name,
        designation: selectedTestimony.designation,
        rating: selectedTestimony.rating,
        description: selectedTestimony.description,
        imgUrl: selectedTestimony.imgUrl,
      });
    }
  }, [id, selectedTestimony]);

  const handleSave = async (event) => {
    event.preventDefault();

    if (id) {
      const updatedData = { ...formData };
      dispatch(updateTestimony({ id, updatedData }));
    } else {
      dispatch(createTestimony(formData));
    }
    handleModalClose();
    setFormData(defaultData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageUploadSuccess = (url) => {
    setFormData((prevState) => ({
      ...prevState,
      imgUrl: url,
    }));
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleModalClose}
      contentLabel="Add/Edit Testimony"
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
        },
        overlay: {
          background: "rgba(0,0,0,0.10)",
        },
      }}
    >
      <ModalHeader
        title={id ? "Edit Testimony" : "Add Testimony"}
        handleModalClose={handleModalClose}
        borderBottom={true}
      />
      <ModalBody>
        <form className="flex flex-col max-h-[400px]" onSubmit={handleSave}>
          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              Name
            </div>
            <div className="w-9/12">
              <input
                id="name"
                name="name"
                className="form-control cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full placeholder-gray-400"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter person's name"
                required
              />
            </div>
          </div>

          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              Designation
            </div>
            <div className="w-9/12">
              <input
                id="designation"
                name="designation"
                className="form-control cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full placeholder-gray-400"
                value={formData.designation}
                onChange={handleChange}
                placeholder="Enter person's designation"
                required
              />
            </div>
          </div>

          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              Rating
            </div>
            <div className="w-9/12">
              <select
                id="rating"
                name="rating"
                className="form-control placeholder-gray-400 cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full"
                value={formData.rating}
                onChange={handleChange}
                required
              >
                <option value={5}>5</option>
                <option value={4}>4</option>
                <option value={3}>3</option>
                <option value={2}>2</option>
                <option value={1}>1</option>
              </select>
            </div>
          </div>

          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              Description
            </div>
            <div className="w-9/12">
              <textarea
                id="description"
                name="description"
                className="form-control cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full placeholder-gray-400"
                value={formData.description}
                onChange={handleChange}
                placeholder="Enter testimony description"
                required
              />
            </div>
          </div>

          <div className="flex  w-full pb-4 gap-2 items-center">
            <div className="mr-16 h-9 flex items-center text-sm font-semibold">
              Image
            </div>
            <div>
              <ImageUploader onUploadSuccess={handleImageUploadSuccess} />
            </div>
          </div>

          <ModalFooter>
            <div className="flex justify-end gap-2">
              <button
                className="flex items-center shadow-md px-3 py-1 rounded-md border hover:text-accent hover:bg-gray-300 focus:outline-none"
                onClick={handleModalClose}
              >
                <span>Close</span>
              </button>

              <button
                type="submit"
                disabled={!id && !formData.imgUrl}
                className="flex items-center disabled:cursor-not-allowed shadow-md px-3 py-1 rounded-md border hover:bg-gray-300 hover:text-accent  bg-accent text-white"
              >
                Save
              </button>
            </div>
          </ModalFooter>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default AddTestimonyModal;
