import React from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { deleteOpportunity } from "../../../../store/slices/opportunity-slice"; // Change action to deleteOpportunity

const DeleteOpportunityModal = ({ handleModalClose, isOpen, opportunityId }) => {
  const dispatch = useDispatch();

  const handleDelete = async () => {
    dispatch(deleteOpportunity({ id: opportunityId })); // Updated to delete an opportunity
    handleModalClose();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => handleModalClose()}
        contentLabel="Confirm Delete"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
          overlay: {
            background: "rgba(0,0,0,0.10)",
          },
        }}
      >
        <div className="p-4">
          <h2 className="text-xl mb-4">
            Are you sure you want to delete this opportunity?
          </h2>
          <div className="flex justify-end space-x-4">
            <button
              className="bg-red-500 text-white px-4 py-2 rounded"
              onClick={handleDelete}
            >
              Yes
            </button>
            <button
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded"
              onClick={() => handleModalClose()}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteOpportunityModal;
