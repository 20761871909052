import React from "react";
import { Link } from "react-router-dom";
import SocialBtns from "../../components/SocialBtns";
import MenuWidget from "../Widget/MenuWidget";
import NewsLetterWidget from "../Widget/NewsLetterWidget";

const menuDataOne = [
  { title: "About", href: "/about" },
  { title: "Portfolio", href: "/portfolio" },
  { title: "Services", href: "/service" },
  { title: "Support", href: "/contact" },
  { title: "Blog", href: "/blog" },
  { title: "Contact", href: "/contact" },
];

export default function Footer() {
  return (
    <footer
      className="relative cs_copyright p-4 text-white"
      style={{ backgroundImage: 'url("/images/Footer_back.jpg")', backgroundSize: "cover", backgroundPosition: "center" }}
    >
      {/* Overlay with transparency */}
      <div className="absolute inset-0 bg-black opacity-60"></div>

      <div className="container relative z-10">
        <div className="cs_footer_top d-flex justify-content-between align-items-start align-items-md-center cs_pt_46 cs_pb_46 cs_column_gap_15 cs_row_gap_15 flex-md-row  flex-column">
          <div className="cs_footer_contact_info">
            <h3 className="text-accent fw-normal cs_mb_4 cs_fs_16 cs_lh_lg">
              Have Any Question?
            </h3>
            <h2 className="text-white fw-medium m-0 cs_fs_22">
              +2519 11 22 93 61
            </h2>
          </div>
          <div className="cs_footer_logo">
            <img
              className="important-logo"
              src="/images/logo_2.png"
              alt="Logo"
            />
          </div>
          <div className="cs_footer_contact_info">
            <h3 className="text-accent fw-normal cs_mb_4 cs_fs_16 cs_lh_lg">
              Send Email
            </h3>
            <h2 className="text-white fw-medium m-0 cs_fs_22">
              <a href="mailto:info@gadaltech.com" className="text-white">
                info@gadaltech.com
              </a>
            </h2>
          </div>
        </div>
        <div className="cs_footer_main cs_pt_20 cs_pb_30">
          <div className="row">
            <div className="col-lg-4">
              <div className="cs_footer_item cs_pt_20 cs_pb_20">
                <div className="cs_text_widget">
                  <p>
                    Gadal offers consulting, facilitating goods and product
                    rentals and sales partnering with businesses, operating an
                    e-commerce platform developing numerous systems for the tech
                    market.
                  </p>
                  <p className="text-accent font-bold text-lg mt-2">Connecting Minds!</p>
                </div>
                <SocialBtns variant="cs_social_btns d-flex flex-wrap cs_column_gap_15 cs_row_gap_15 cs_transition_5" />
              </div>
            </div>
            <div className="col-lg-3 offset-lg-1">
              <MenuWidget data={menuDataOne} title="Explore" />
            </div>
            <div className="col-lg-4">
              <div className="cs_footer_item cs_pb_20">
                <NewsLetterWidget
                  title="Newsletter"
                  subTitle="Stay updated with the latest news from Gadal Technologies."
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="cs_copyright text-center cs_fs_124 cs_lh_lg cs_pt_20 cs_pb_36 relative z-10">
        <div className="container">
          <p className="m-0">
            Copyright © {new Date().getFullYear()} <Link to="/">Gadal</Link>.
            All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
