import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";

import { ModalHeader } from "../modal/ModalHeader";
import { ModalBody } from "../modal/ModalBody";
import { ModalFooter } from "../modal/ModalFooter";

import MultipleImageUploader from "./MultipleImageUploader";
import {
  createMagazine,
  selectMagazineById,
  updateMagazine,
} from "../../../../store/slices/e-magazine-slice";
import ImageUploader from "../blog/ImageUploader";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const AddEMagazineModalContent = ({ handleModalClose, id, isOpen }) => {
  const dispatch = useDispatch();

  const selectedEMagazine = useSelector((state) =>
    selectMagazineById(state, id)
  );

  const [formData, setFormData] = useState({
    year: "2024",
    month: "January",
    coverImageUrl: "",
    pageImageUrls: [],
  });

  useEffect(() => {
    if (id && selectedEMagazine) {
      setFormData({
        year: selectedEMagazine.year,
        month: selectedEMagazine.month,
        coverImageUrl: selectedEMagazine.coverImageUrl,
        pageImageUrls: selectedEMagazine.pages || [],
      });
    }
  }, [id, selectedEMagazine]);

  const handleSave = async (event) => {
    event.preventDefault();

    if (id) {
      const updatedData = { ...formData };
      dispatch(updateMagazine({ id, updatedData }));
    } else {
      dispatch(createMagazine(formData));
    }
    handleModalClose();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle cover image upload
  const handleCoverUploadSuccess = (url) => {
    setFormData((prevState) => ({
      ...prevState,
      coverImageUrl: url,
    }));
  };

  // Handle page image upload
  const handlePageUploadSuccess = (urls) => {
    setFormData((prevState) => ({
      ...prevState,
      pageImageUrls: urls,
    }));
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleModalClose}
      contentLabel="Add/Edit eMagazine"
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
        },
        overlay: {
          background: "rgba(0,0,0,0.10)",
        },
      }}
    >
      <ModalHeader
        title={id ? "Edit eMagazine" : "Add eMagazine"}
        handleModalClose={handleModalClose}
        borderBottom={true}
      />
      <ModalBody>
        <form className="flex flex-col max-h-[400px]" onSubmit={handleSave}>
          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              Year
            </div>

            <div className="w-9/12">
              <input
                id="year"
                name="year"
                className="form-control cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full placeholder-gray-400"
                value={formData.year}
                onChange={handleChange}
                placeholder="Enter Year"
                required
              />
            </div>
          </div>

          <div className="flex items-start w-full pb-4">
            <div className="w-3/12 h-9 flex items-center text-sm font-semibold">
              Month
            </div>
            <div className="w-9/12">
              <select
                id="month"
                name="month"
                className="form-control placeholder-gray-400 cs_fs_14 py-2 cs_rounded_5 border-0 bg-gray w-full"
                value={formData.month}
                onChange={handleChange}
                required
              >
                {months.map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Cover Image Uploader */}
          <div className="flex w-full pb-4 gap-2 items-center">
            <div className="mr-5 h-9 flex items-center text-sm font-semibold">
              Cover Image
            </div>
            <div>
              <ImageUploader onUploadSuccess={handleCoverUploadSuccess} />
            </div>
          </div>

          {/* Page Images Uploader */}
          <div className="flex w-full pb-4 gap-2 items-center">
            <div className="mr-16 h-9 flex items-center text-sm font-semibold">
              Pages
            </div>
            <div>
              <MultipleImageUploader
                onUploadSuccess={handlePageUploadSuccess}
              />
            </div>
          </div>

          <ModalFooter>
            <div className="flex justify-end gap-2">
              <button
                className="flex items-center shadow-md px-3 py-1 rounded-md border hover:text-accent hover:bg-gray-300 focus:outline-none"
                onClick={handleModalClose}
              >
                <span>Close</span>
              </button>

              <button
                type="submit"
                className="flex items-center shadow-md px-3 py-1 rounded-md border hover:bg-gray-300 hover:text-accent"
              >
                Save
              </button>
            </div>
          </ModalFooter>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default AddEMagazineModalContent;
