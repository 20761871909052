export const formatDate = (isoString) => {
    const date = new Date(isoString);
    
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
  
    return date.toLocaleString("en-US", options);
  };