export const heroData = [
  {
    bgUrl: "/images/hero/hero_1.jpg",
    titleUp: "Your reliable Business partner",
    title:
      "One stop solution on multiple business and <br>  investment opportunities",
    subTitle: `
        Gadal Technologies is highly passionated and committed to provide excellent service on machinery, <br> 
        property, vehicle, import and export facilities, IT solutions, asset valuation,<br> 
        property management, Business consulting and Realestate marketing.
        
      `,
    btnText: "Explore Our Services",
    btnUrl: "/service",
    isUrlExternal: false,
  },
  {
    bgUrl: "/images/hero/hero_2.jpg",
    titleUp: "Investment opportunities",
    title:
      "You can't afford not to work with Gadal <br> with multiple  Investment opportunities",
    subTitle: `
        Gadal brings a new way of serving people with big different types of investments
      `,
    btnText: "Learn More",
    btnUrl: "/opportunities",
    isUrlExternal: false,
  },
  {
    bgUrl: "/images/hero/hero_3.jpg",
    titleUp: "Gadal Investment hub",
    title:
      "We are preparing Business and Investment <br> E-Megazine being published monthly",
    subTitle: `
        On this Magazine we will publish different Investment opportunities, promote new and existing business, <br> announce government policies on business issues
      `,
    btnText: "Browse E-magazine",
    btnUrl: "/e-magazine",
    isUrlExternal: false,
  },
  {
    bgUrl: "/images/hero/hero_5.jpg",
    titleUp: "Marketing and consulting",
    title: "Connecting you to potential clients",
    subTitle: `
        Through our website Gadal Market, we offer a platform to rent and sell vehicles,  fixed assets, <br>
        and various items. We also design problem-solving projects to help businesses thrive in a 
        tech-driven world.
      `,
    btnText: "Visit Gadal Market",
    btnUrl: "https://gadalmarket.com",
    isUrlExternal: true,
  },
];
