import React from "react";
import Sidebar from "../Sidebar/Sidebar";

import { Outlet } from "react-router-dom";
import AdminHeader from "../Header/AdminHeader";

const AdminLayout = () => {
  return (
    <div className="flex flex-row max-h-[100vh] overflow-y-auto">
      <div className="hidden md:block">
        <Sidebar />
      </div>

      <div className="flex flex-col h-[100vh] w-full bg-[#F3F2F7] dark:bg-black">
        <AdminHeader/>
        <div className="overflow-y-auto max-h-[100vh]">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
