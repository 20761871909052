import React, { useEffect } from "react";
import { FaGooglePlay, FaApple } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function DownloadAppSection({ data }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="flex flex-col md:flex-row items-center justify-between p-8 bg-black opacity-80 mt-2 mb-10">
        {/* Left Section */}
        <div className="flex-1 mb-6 md:mb-0">
          <h2 className="text-2xl font-bold text-white">
            Download <span className="text-accent">Gadal</span> Mobile
            Applications
          </h2>
          <div className="flex mt-4 space-x-4">
            {/* Play Store Button */}
            <Link
              to={
                "https://play.google.com/store/apps/details?id=com.gadalmarket.gadalmarket"
              }
              className="flex items-center px-4 py-2 rounded-full bg-accent"
            >
              <FaGooglePlay className="mr-2 text-white" />
              <span className="text-white">Play Store</span>
            </Link>

            {/* App Store Button */}
            <Link className="flex items-center px-4 py-2 rounded-full bg-accent">
              <FaApple className="mr-2 text-white" />
              <span className="text-white">App Store</span>
            </Link>
          </div>
        </div>

        {/* Right Section - Image */}
        <div className="flex-1 py-10">
          <div className="w-full h-60 flex items-center justify-center">
            <img src="/images/samusng_gadal.svg" alt="" />
          </div>
        </div>
      </div>
    </>
  );
}
