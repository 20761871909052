
import React from "react";

import { GrHomeRounded } from "react-icons/gr";
import {
  FaBriefcase,
  FaRegNewspaper,
  FaBlogger,
  FaHandshake,
  FaBook,
  FaCalendarAlt,
  FaUsers,
} from "react-icons/fa";
import { GiMoneyStack } from "react-icons/gi";
import { MdLibraryBooks } from "react-icons/md";
import { IoMdChatbubbles } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/slices/auth-slice";
// import { FaPlus } from "react-icons/fa";

const AdminHeader = () => {
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();

  const routes = [
    {
      path: "/admin",
      title: "Dashboard",
      icon: GrHomeRounded,
      active: pathname === "/admin",
    },

    {
      path: "/admin/job",
      title: "Jobs",
      icon: FaBriefcase,
      active: pathname.startsWith("/admin/job"),
    },

    {
      path: "/admin/blog",
      title: "Blog",
      icon: FaBlogger,
      active: pathname.startsWith("/admin/blog"),
    },
    {
      path: "/admin/portifolio",
      title: "Portifolios",
      icon: MdLibraryBooks,
      active: pathname.startsWith("/admin/portifolio"),
    },
    {
      path: "/admin/testimony",
      title: "Testimonies",
      icon: IoMdChatbubbles,
      active: pathname.startsWith("/admin/testimony"),
    },
    {
      path: "/admin/opportunities",
      title: "Opportunities",
      icon: GiMoneyStack,
      active: pathname.startsWith("/admin/opportunities"),
    },
    {
      path: "/admin/e-magazine",
      title: "E-Magazine",
      icon: FaRegNewspaper,
      active: pathname.startsWith("/admin/e-magazine"),
    },
    {
      path: "/admin/partner",
      title: "Partners",
      icon: FaHandshake,
      active: pathname.startsWith("/admin/partner"),
    },

    {
      path: "/admin/team-members",
      title: "Team Members",
      icon: FaUsers,
      active: pathname.startsWith("/admin/team-members"),
    },

    {
      path: "/admin/resource",
      title: "Resources",
      icon: FaBook,
      active: pathname.startsWith("/admin/resource"),
    },

    {
      path: "/admin/event",
      title: "Events",
      icon: FaCalendarAlt,
      active: pathname.startsWith("/admin/event"),
    },
  ];

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div className="flex sticky top-0 flex-row items-center justify-between px-5 py-2 shadow-md">
      <div className="flex h-full gap-6 items-center w-full">
        <div className="flex items-center justify-end w-full py-2">
          <div className="hidden md:flex flex-row gap-2   ">
            <Link
              onClick={handleLogout}
              to={"/admin/login"}
              className="border flex items-center justify-center gap-2 hover:text-accent  rounded-2xl px-4 py-2 shadow-md  transition duration-300"
            >
              <span>Log out</span>
            </Link>
          </div>

          <div className="flex md:hidden flex-row items-center justify-start overflow-x-auto  max-w-full">
            {routes.map((route) => (
              <Link to={route.path} key={route.path} className="shrink-0">
                <div className="flex flex-row items-center justify-center gap-1 px-2 py-1 rounded">
                  <route.icon
                    className={`${route.active ? "text-accent" : ""}`}
                  />
                  <p
                    className={`${
                      route.active ? "text-accent" : ""
                    } whitespace-nowrap`}
                  >
                    {route.title}
                  </p>
                </div>
              </Link>
            ))}
          </div>

          {/* User avatar */}
          <div className="w-9 h-9 rounded-full overflow-hidden ml-4">
            <img
              src="/images/user_avatar.jpeg"
              width={40}
              height={40}
              alt="profile"
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminHeader;
