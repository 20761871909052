import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getMagazines } from "../../store/slices/e-magazine-slice";
import Breadcrumb from "../Breadcrumb";
import { pageTitle } from "../../helpers/PageTitle";

// Selector to group magazines by year and month
export const selectMagazinesByYear = (magazines, selectedYear) => {
  // const magazines = state.magazine?.magazines || [];

  // Grouping magazines by month for the selected year
  return magazines.reduce((acc, magazine) => {
    const year = magazine.year;
    const month = magazine.month;

    if (year === selectedYear) {
      if (!acc[month]) {
        acc[month] = [];
      }
      acc[month].push(magazine);
    }

    return acc;
  }, {});
};

const MagazineCard = ({ magazine }) => {
  return (
    <div className="cs_portfolio border rounded-lg shadow-md cs_style_1 position-relative overflow-hidden">
      <Link
        to={`/e-magazine/${magazine?._id}`}
        className="cs_portfolio_overlay cs_transition_4 position-absolute h-100 w-100 start-0 top-0 cs_zindex_1 d-block"
      ></Link>
      <div
        className="cs_portfolio_thumb d-block h-100 w-100 background-filled cs_transition_6"
        style={{ backgroundImage: `url('${magazine.coverImage}')` }}
      ></div>
    </div>
  );
};

export default function EMagazinePage() {
  pageTitle("E-Magazine");
  const [selectedYear, setSelectedYear] = useState(2024);
  const [magazines, setMagazines] = useState({});
  const { magazines: stateMagazines, status } = useSelector(
    (state) => state.magazine
  );

  const years = Array.from({ length: 15 }, (_, i) => 2024 + i);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getMagazines({ page: 1, pageSize: 500 }));
  }, [dispatch]);

  useEffect(() => {
    if (selectedYear) {
      const getMagazines = async () => {
        const magazinesByYear = selectMagazinesByYear(
          stateMagazines,
          selectedYear
        );
        setMagazines(magazinesByYear);
      };
      getMagazines();
    }
  }, [selectedYear, stateMagazines]);

  return (
    <>
      <Breadcrumb title="Our Services" bgUrl="/images/page_header_1.jpeg" />

      <div className="p-4 my-6 h-screen">
        {/* Year Selector */}
        <div className="overflow-x-auto whitespace-nowrap py-2 w-[60%] mx-auto">
          {years.map((year) => (
            <button
              key={year}
              onClick={() => setSelectedYear(year)}
              className={`px-4 py-1 mx-2 rounded-lg border ${
                selectedYear === year ? "bg-accent text-white" : "bg-gray-200"
              }`}
            >
              {year}
            </button>
          ))}
        </div>

        {/* Magazine List */}
        {status === "loading" && (
          <div className="flex items-center justify-center">Loading... </div>
        )}
        {selectedYear &&
          Object.keys(magazines).length === 0 &&
          status !== "loading" && (
            <div className="flex items-center justify-center">
              No magazines available for {selectedYear}
            </div>
          )}

        {selectedYear &&
          Object.keys(magazines).length !== 0 &&
          status !== "loading" && (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-4">
              {Object.keys(magazines).map((month) => (
                <div className="" key={month}>
                  <h3 className="text-lg font-medium mb-3">
                    {month} {selectedYear}
                  </h3>
                  <div className="">
                    {magazines[month].map((magazine) => (
                      <MagazineCard key={magazine.id} magazine={magazine} />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
      </div>
    </>
  );
}
