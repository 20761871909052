import React, { useEffect, useRef, useState } from "react";
import HTMLFlipBook from "react-pageflip";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Section from "../Section";
import { pageTitle } from "../../helpers/PageTitle";
import { useSelector } from "react-redux";
import { selectMagazineById } from "../../store/slices/e-magazine-slice";
import { useParams } from "react-router-dom";
import Breadcrumb from "../Breadcrumb";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { FaExpand, FaCompress } from "react-icons/fa";

const Page = React.forwardRef((props, ref) => {
  return (
    <div className="page" ref={ref}>
      {props.children}
    </div>
  );
});

const MyBook = React.forwardRef((props, ref) => {
  const magazine = { ...props.props };

  return (
    <HTMLFlipBook width={400} height={500} ref={ref}>
      <Page number={1}>
        <div className="flex flex-col items-center justify-center h-full p-4">
          <h1 className="text-3xl font-bold">
            Welcome to {magazine.month} {magazine.year} Edition
          </h1>
          <p className="mt-4 text-lg text-center">
            We're thrilled to have you here! Dive in and explore the latest
            articles and stories in this edition.
          </p>
        </div>
      </Page>
      {magazine?.pages.map((page, index) => (
        <Page key={index} number={index + 1}>
          <img src={page} alt={`Page ${index + 1}`} />
        </Page>
      ))}

      {/* Second to Last Page */}
      <Page number={magazine.pages.length + 1}>
        <div className="flex items-center justify-center h-full">
          <h2 className="text-xl">The End!!</h2>
        </div>
      </Page>

      {/* Last Page with Thank You */}
      <Page number={magazine.pages.length + 2}>
        <div className="flex flex-col items-center justify-center h-full">
          <h2 className="text-2xl font-bold">Thank You for Reading!</h2>
          <p className="mt-4 text-center">
            We hope you enjoyed this edition. Stay tuned for more!
          </p>
        </div>
      </Page>
    </HTMLFlipBook>
  );
});

export default function EMagazineDetailsPage() {
  pageTitle("E-Magazine Details");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { id } = useParams();
  const selectedEMagazine = useSelector((state) =>
    selectMagazineById(state, id)
  );

  const flipBookRef = useRef(null);

  const goToPreviousPage = () => {
    if (flipBookRef.current) {
      flipBookRef.current.pageFlip().flipPrev();
    }
  };

  const goToNextPage = () => {
    if (flipBookRef.current) {
      flipBookRef.current.pageFlip().flipNext();
    }
  };

  const handle = useFullScreenHandle();
  const [isFullScreen, setIsFullScreen] = useState(false);

  return (
    <>
      <Breadcrumb title="Contact" bgUrl="/contact_1.png" />
      <FullScreen handle={handle}>
        <Section pb="133" pbLg="75" className={"mt-10"}>
          <div className="App flex flex-col items-center justify-center my-auto h-screen">
            <MyBook ref={flipBookRef} props={selectedEMagazine} />
            <div className="navigation-buttons flex mt-4">
              <button
                onClick={goToPreviousPage}
                className="navigation-button"
                aria-label="Previous Page"
              >
                <FaArrowLeft size={24} />
              </button>
              <button
                onClick={goToNextPage}
                className="navigation-button"
                aria-label="Next Page"
              >
                <FaArrowRight size={24} />
              </button>

              {!isFullScreen && (
                <button
                  onClick={() => {
                    handle.enter();
                    setIsFullScreen(true);
                  }}
                  className="navigation-button"
                  aria-label="Zoom Out"
                >
                  <FaExpand size={24} />
                </button>
              )}

              {isFullScreen && (
                <button
                  onClick={() => {
                    handle.exit();
                    setIsFullScreen(false);
                  }}
                  className="navigation-button"
                  aria-label="Zoom In"
                >
                  <FaCompress size={24} />
                </button>
              )}
            </div>
          </div>
        </Section>
      </FullScreen>
    </>
  );
}
