import PostStyle5 from "../Post/PostStyle5";
import { useEffect } from "react";
import { pageTitle } from "../../helpers/PageTitle";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectOpportunityById } from "../../store/slices/opportunity-slice";
import Breadcrumb from "../Breadcrumb";
import { FaPhoneAlt, FaEnvelope, FaWhatsapp } from "react-icons/fa";

export default function OpportunityDetailsPage() {
  pageTitle("Opportunity Details");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  const opprtunity = useSelector((state) => selectOpportunityById(state, id));
  console.log(opprtunity,'dd');
  
  return (
    <>
      <Breadcrumb
        title="Available Opportunities"
        bgUrl="/images/page_header_1.jpeg"
      />
      <section className="cs_pt_40 cs_pt_lg_60 cs_pb_140 cs_pb_lg_80 mt-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <PostStyle5
                imgUrl={opprtunity?.imageUrl}
                title={opprtunity?.title}
                subTitle={opprtunity?.description}
              />
            </div>
            <div className="col-lg-6">
              <div className="cs_quick_contact_card background-filled cs_mb_40 text-center cs_copyright   bg-gray-100 ">
                <div className="cs_quick_contact_card_in">
                  <h6 className="cs_fs_30 cs_fs_lg_36  cs_mb_40">
                    Want to access this opportunity?
                  </h6>

                  <Link
                    to={opprtunity?.link}
                    target={opprtunity?.link && "_blank"}
                    className="cs_btn cs_style_1 cs_fs_16 cs_rounded_5 cs_pl_30 cs_pr_30 cs_pt_10 cs_pb_10 overflow-hidden"
                  >
                    <span>Fill this Form</span>
                  </Link>
                </div>
              </div>

              <section className="py-10 bg-gray-100 ">
                <div className="container flex items-start mx-40 justify-center flex-col ">
                  <h6 className=" cs_fs_30 cs_fs_lg_36  text-start  mb-10 flex">
                    Quick Contact
                  </h6>
                  <div className="flex flex-col items-start  space-y-4 text-center">
                    <div className="flex justify-center items-center gap-4">
                      <FaPhoneAlt className="text-4xl text-accent " />
                      <h4 className="text-lg font-semibold ">9830</h4>
                    </div>
                    <div className="flex justify-center items-center gap-4">
                      <FaWhatsapp className="text-4xl text-accent " />
                      <h4 className="text-lg font-semibold">+251911229361</h4>
                    </div>
                    <div className="flex justify-center items-center gap-4">
                      <FaEnvelope className="text-4xl text-accent" />
                      <h4 className="text-lg font-semibold ">
                        support@gadaltech.com
                      </h4>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
