import React, { useEffect } from "react";
import LocationInMap from "../LocationInMap";
import Section from "../Section";
import FormStyle2 from "../Form/FormStyle2";
import IconboxStyle2 from "../Iconbox/IconboxStyle2";
import SectionHeadingStyle3 from "../SectionHeading/SectionHeadingStyle3";
import { pageTitle } from "../../helpers/PageTitle";
import Breadcrumb from "../Breadcrumb";

const locationData =
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4020.330634041895!2d38.77462730233124!3d9.006355790937516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b85eb1678b3c1%3A0x30ccfb7b5544ac1a!2sGadal%20Technologies!5e0!3m2!1sam!2set!4v1725647991190!5m2!1sam!2set";

export default function ContactPage() {
  pageTitle("Contact");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Breadcrumb title="Contact Us" bgUrl="/images/contact_us_header.jpeg" />

      <Section pt="60" ptLg="50" pb="133" pbLg="75" >
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-6">
              <SectionHeadingStyle3
                sectionTitleUp="Have any question?"
                sectionTitle="Write a Message"
              />
              <FormStyle2 />
            </div>
            <div className="col-xl-5 col-lg-6">
              <div className="cs_pl_60 cs_pl_lg_0 cs_pt_lg_60">
                <SectionHeadingStyle3
                  sectionTitleUp="Contact With Us"
                  sectionTitle="Let’s work together"
                  sectionSubTitle="Ready to bring your ideas to life? Partner with Gadal Technology PLC to leverage innovative solutions and achieve your goals. Let’s collaborate to create something exceptional."
                  className="cs_mb_40"
                />
                <div className="cs_iconbox_wrapper cs_style1">
                  <IconboxStyle2
                    imgUrl="/images/icons/contact_icon_1.svg"
                    title="9830"
                    titleUp="Have Any Question?"
                  />
                  <IconboxStyle2
                    imgUrl="/images/icons/contact_icon_2.svg"
                    title="support@gadaltech.com"
                    titleUp="Send Email"
                  />
                  <IconboxStyle2
                    imgUrl="/images/icons/contact_icon_3.svg"
                    title="Addis Abab ,Bole Sub-city Wereda 04, Amen Building House number 401."
                    titleUp="Address"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <LocationInMap data={locationData} />
    </>
  );
}
